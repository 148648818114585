import React from 'react'
import islands from '@constants/islands'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelFilm extends React.Component {
  resource = '/clement_bethel_films'
  form_key = 'clement_bethel_film'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'email',
        type: 'email',
    },
    {
        name:'name_of_film',
    },
    {
        name: 'name_of_organization',
    },
    {
        name: 'category_of_film',
    },
    {
        name: 'duration_of_film',
    },
    {
        name: 'island',
        options: islands,
    },
    {
        name: 'phone_number',
        type: 'phone',
    },
    {
        name: 'level_first',
        label: 'Level',
        options: [
            'Primary PR ($5 Single $10 Group/Ensemble)',
            'Jr. High JH ($5 Single $10 Group/Ensemble)',
            'Sr. High SH ($5 Single $10 Group/Ensemble)',
            'Community COM ($10 Single $20 Group/Ensemble)',
            'Pre-School PS ($5 Single $10 Group/Ensemble)',
            'Other'
        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.level_first !='Other',
    },
    {
        name: 'level_second',
        label: 'Lavel',
        options: [
            'Primary PR ($5 Single)',
            'Primary PR ($10 Groupe)',
            'Jr. High JH ($5 Single)',
            'Jr. High JH ($10 Group/Ensemble)',
            'Sr. High SH ($5 Single)',
            'Sr. High SH ($10 Group/Ensemble)',
            'Community COM ($10 Single)',
            'Community COM ($20 Group/Ensemble)',
            'Pre-School PS ($5 Single $10 Group/Ensemble)',
            'Other'
        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.level_second !='Other',
    },
    {
        name: 'recording_and_editing',
        label: 'Recording and editing software',
    },
    {
        name: 'brief_synopsis',
        label: 'Provide a brief synopsis of the Film.', 
        hint: 'Include credits',
        type: 'textarea',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
