import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class CinemaTheatreLicense extends React.Component {
  resource = '/cinema-theatre-licenses'
  form_key = 'cinema-theatre-license'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),
    "::Onwner's information",
    {
        name: 'first_name',
    },
    {
        name: 'last_name',
    },
    "::Additional Infromation",
    {
        name: 'premises_location',
        type: 'textarea',
        hint: 'Be as descriptive as possible',
    },
    {
        name: 'seating_capacity',
    },
    {
        name: 'date',
        type: 'date',
    },
    "::Uploads",
    {
        name:'site_plans',
        type: 'file:all',
        key: 'image.0'
    },
    {
        name:'building_plans',
        type: 'file:all',
        key: 'image.1'
    },

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
