import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import daysOfWeek from '@constants/weekdys'

export default class __SERVICE_NAME__ extends React.Component {
  resource = '/youth_organizations'
  form_key = 'youth_organization'
  defaults = {}

  // Delete lines as needed
  free          = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => {
    const list = [
      {
        name: 'organization_name',
        label: 'name of Organization',
      },
      {
        name: 'organization_type',
        label: 'Type of Organization',
      },
      {
        name: 'organization_start_date',
        type: 'date',
        label: 'Start Date',
        showYearDropdown: true,
      },
      {
        name: 'island',
        options: islands,
      },
      {
        name: 'po_box',
        label: 'P. O. Box',
        required: false
      },
      '::Contact Details',
      {
        name: 'i_am_contact',
        type: 'checkbox',
        required: false,
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      },
    ]

    if (!form.i_am_contact) {
      list.push(
        '::Contact Details',
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
        {
          name: 'contact_phone_numb',
          label: 'Primary Phone #',
          type: 'phone'
        },
      )
    }

    list.push(
      {
        name: 'meeting_frequency',
        label: 'Meeting Days',
        multi: true,
        options: daysOfWeek,
        format: val => val.join(', ')
      },
      {
        name: 'meetings_located',
        label: 'Meeting Location',
      },
      {
        name: 'shareholders',
        label: 'Officers',
        columns: [
          'first_name',
          'last_name',
          'position'
        ]
      },
      {
        name: 'number_of_members',
        is: 'integer'
      },
      {
        name: 'member_age_range',
        label: 'Age Range of Members',
        options: [
          { value: 'under 12', label: 'Under 12' },
          '13 - 19',
          '20 - 25',
          { value: 'over 25', label: 'Over 25' },
        ],
      },
      {
        name: 'members_sex',
        label: 'Gender Ratio',
        options: [
          { value: 'male', label: 'All Male' },
          { value: 'female', label: 'All Female' },
          { value: 'mixed', label: 'Mixed' },
        ]
      },
      {
        name: 'group_activies',
        columns: ['activity'],
        format: v => v.activity,
      },

      '::Uploads',
      {
        name: 'organization_profile_upload',
        type: 'file:pdf',
        label: `Organiztion's Aims & Objectives`,
      },
      {
        name: 'proposal_upload',
        type: 'file:pdf',
        label: 'Business Proposal',
      },
    )

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
