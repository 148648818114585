import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelArt extends React.Component {
  resource = '/clementbethelarts'
  form_key = 'clementbethelart'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ":: Entrant Info",
    {
        name: 'gender',
        options:['Male','Female','Other'],
    },
    {
        name: 'first_name',
    },
    {
        name: 'last_name',
    },
    {
        name: 'email',
        type: 'email',
    },
    "::Additional Info",
    {
        name: 'name_of_work',
    },
    {
        name: 'island_deadline',
        label: 'Island deadline date',
        options: [
            'Abaco February 15th',
            'Acklins February 15th',
            'Andros April 1st',
            'Bimini May 1st',
            'Berry Islands May 1st',
            'Cat Island May 1st',
            'Crooked Island May 1st',
            'Eleuthera May 1st',
            'Exuma Feb 15th',
            'Grand Bahama April 1st',
            'Inagua Feb 15th',
            'New Providence March 1st',
            'Mayaguana Feb 15th',
            'San Salvador Feb 15th',
            'Rum Cay Feb 15th',
            'Ragged Island Feb 15th',
            'Long Island May 1st',
            'Other',

    ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.island_deadline !='Other',
    },
    {
        name: 'level',
        options:[
            'Primary PR ($5 Single)',
            'Primary PR ($10 Group)',
            'Jr High JH ($ Single)',
            'Jr High JH ($10 Group/Ensemble)',
            'Sr. High SH ($5 Single)',
            'Sr. High SH ($10 Group/Ensemble)',
            'Community COM ($10 Single)',
            'Community COM ($20 Group/Ensemble)',
            'Pre-School PS ($5 Singles $10 Group/Ensemble)',
            'Other',
        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.level !='Other',
    },
    {
        name: 'work_medium',
        label: 'Medium of Work',
        type: 'textarea',
        hint: 'Please explain in detail',
    },
    {
        name: 'school_or_ensemble',
        label:'Name of school or Ensemble',
    },
    {
        name: 'name_of_guardian',
        label: 'Name of Director/Teacher/Parent', 
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
