import React from 'react'
import { Route } from 'react-router-dom'
import YAML from 'yaml'
import SERVICES_YAML from '@/services.yml'

const generateServiceRoutes = () => {
  if (!$app.__data) return null

  const manifest = YAML.parse(SERVICES_YAML)
  const context = require.context('@agencies', true, /\.js$/)

  const render = (Component, service) => router =>
    <Component {...router} service={service} />

  return $app.getServices('*').map(service => {
    const { code } = service.agency

    const agency = manifest.find(agency => agency.code == code)
    const match = (
      agency.departments?.reduce((arr, dept) => [...arr, ...dept.services], [])
      ?? agency?.services
    ).find(s => s._sid == service.name)

    if (!match
      || (match.allow && !$app.hasRole(match.allow))
      || (match.hide && $app.hasRole(match.hide))
    ) return null

    const filename = `./${code}/${match.file}.js`
    const Component = context(filename).default

    return (
      <Route
        key={service.path}
        path={service.path}
        render={render(Component, service)}
      />
    )
  })
}

export default generateServiceRoutes
