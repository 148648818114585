import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

const today = new Date()

export default class CulturalRegistry extends React.Component {
  resource = '/cultural_registries'
  form_key = 'cultural_registry'

  defaults = {
    development_assistance: [
      { item: '' },
      { item: '' },
      { item: '' },
    ]
  }

  free          = true
  skip_location = true
  hide_buttons  = false

  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),

    {
      name: 'number_of_members',
      label: 'Number of Members in Group/Organizations',
      is: 'integer',
    },

    {
      name: 'artist_name',
      // label: ''
    },
    {
      name: 'website'
    },
    {
      name: 'social_media',
      columns: [
        {
          name: 'platform',
          datalist: [
            'Facebook',
            'Twitter',
            'Instagram',
            'TikTok',
            'YouTube',
            'LinkedIn',
            'Pinterest'
          ]
        },
        'tag'
      ],
      // format: list => {
      //   // console.log(list)
      //   return JSON.stringify({
      //     [list.site.toLowerCase()]: true,
      //     tag: list.tag
      //   })
      // },

      // view: {
      //   element: value => {
      //     // const links = value.map()

      //     return (
      //       <Fragment>
      //         <h5 className='sub'>Social Media Links</h5>
      //       </Fragment>
      //     )
      //   }
      // }
    },

    '::Work History',
    {
      name: 'years_of_engagement',
      options: [
        { value: '0 - 5 Years', label: '0 - 5 Years' },
        { value: '6 - 10 Years', label: '6 - 10 Years' },
        { value: '11 - 15 Years', label: '11 - 15 Years' },
        { value: '16 - 20 Years', label: '16 - 20 Years' },
        { value: '21+ Years', label: '21+ Years' }
      ]
    },
    {
      name: 'affiliates',
      label: 'Local & International Affiliates',
      columns: ['affiliate'],
      format: v => v.affiliate
    },
    {
      name: 'work_level',
      options: [
        'Amateur',
        'Semi Professional',
        'Professional'
      ]
    },
    {
      name: 'areas_of_focus',
      type: 'select',
      multi: true,
      options: [
        'art',
        'craft',
        'jewelry',
        'music',
        'drama',
        {label: 'Film/TV', value: 'film'},
        'dance',
        'culinary',
        'fashion',
        'literature',
        'junkanoo',
        {label: 'Festival/Pagentry', value: 'festival'},
        {label: 'Museum/Attraction', value: 'museum'},
        // 'Other'
      ]
    },
    // {
    //   name: 'area_of_focus_other',
    //   hide: !form.areas_of_focus?.includes?.('Other'),
    //   label: 'Please Specify',
    // },
    {
      name: 'target_demographic',
      type: 'textarea'
    },

    '::Education / Training',
    {
      name: 'education_history',
      columns: [
        'school_name',
        {
          name: 'school_type',
        },
        'area_of_focus',
        {
          name: 'level_of_study',
          options: [
            "Primary",
            'Secondary',
            "Undergraduate",
            "Post-Graduate",
          ],
        },
        {
          name: 'start_date',
          type: 'date',
          max: today,
        },
        {
          name: 'completion_date',
          type: 'date',
          required: false,
        }
      ]
    },

    '::Skills and Qualifications',
    {
      name: 'special_skills',
      label: 'Special Skills, Abilities, & Honours',
      columns: ['item'],
      format: v => v.item,
    },

    '::Highlights',
    {
      name: 'highlights',
      label: 'Main Activities over the last 5 years',
      columns: ['activity'],
      format: v => v.activity,
    },
    {
      name: 'development_assistance',
      label: 'Three (3) things that can assist your development',
      columns: ['item'],
      format: v => v.item,
    },

    '::Financial Indicators',
    {
      name: 'high_season_dates',
      columns: [{
        name: 'date',
        type: 'date',
        minDate: today
      }],
      format: v => v.date,
    },
    {
      name: 'low_season_dates',
      columns: [{
        name: 'date',
        type: 'date',
        minDate: today
      }],
      format: v => v.date,
    },
    {
      name: 'annual_income_range',
      is: 'currency'
    },
    {
      name: 'support_funding',
      type: 'textarea',
      label: 'Additional Support Funding',
      required: false,
    },

    '::Uploads',
    {
      name: 'resume_upload',
      key: 'image.0',
      type: 'file:pdf',
      label: 'Resume',
    },
    {
      name: 'photo_upload',
      key: 'image.1',
      type: 'file:image',
      label: 'Other Photo',
    },
    {
      name: 'photo_2_upload',
      key: 'image.2',
      type: 'file:image',
      label: 'Other Photo',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
