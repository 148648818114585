import React from 'react'

import { getProperty } from '@helpers/objects'
import ENV from '@constants/env'

export const isDeliverable = (record, updating = false) =>
  (updating || /approve/i.test(record.application_decision)) &&
  /Collections Cent(er|re)/.test(record.pickup_sub_location)

export const officerName = () => {
  const u = $app.current_user
  return `${u.first_name.charAt(0)}. ${u.last_name}`.capitalize()
}

export const fullName = (person = {}, middleName = true) => {
  if (!person || typeof person != 'object') return null

  const list = [person.first_name]

  if (middleName == 'initial' && person.middle_name) {
    list.push(person.middle_name.charAt(0) + '.')
  } else if (middleName) {
    list.push(person.middle_name)
  }

  list.push(person.last_name)

  return list.join(' ').capitalize()
}

export const getUploadPath = (path = '', raw = false) => {
  if (!path) return 'N/A'

  const link = path.startsWith('http') ? path : `${ENV.WEB_SERVICE_URL}${path}`

  return raw ? link : 'href::' + link
}

export const getFieldLink = (form = {}, { name, key }) => {
  let path

  if (key) {
    path = typeof key == 'function' ? key(form) : getProperty(form, key)
  } else {
    path = form[name] || form[name + '_file_name']
  }

  return getUploadPath(path)
}

export const failsRoleRequirements = (roles = []) => {
  if (!roles?.length) return false

  for (let role of roles) {
    if (Array.isArray(role)) {
      if (!$app.hasAnyRole(...role)) return true
    } else if (!$app.hasRole(role)) return true
  }

  return false
}

export const getMatchedLocations = (agencies, agencyId) => {
  const isCCC = l => /collections cen/i.test(l.street_address)

  const list = []

  let matchedAgency = false
  let matchedCCC = false
  let cccIndex

  for (let { id, locations } of agencies) {
    if (id == agencyId) {
      // agency match
      matchedAgency = true
      list.push(...locations)

      // already got CCC earlier; return
      if (matchedCCC) break

      // Check if CCC is in curr.agency
      cccIndex = locations.findIndex(isCCC)
      if (cccIndex != -1) {
        // Push CCC to top of list then return
        list.unshift(...list.splice(cccIndex, 1))
        break
      }
    }

    // Check for CCC; push to front if found
    cccIndex = locations.findIndex(isCCC)
    if (cccIndex != -1) {
      matchedCCC = true
      list.unshift(locations[cccIndex])
      // return if we also already got agency's own locations
      if (matchedAgency) break
    }
  }

  return list
}

export const getSearchableFieldNames = (arr, prop) =>
  arr?.reduce((l, f) => (f.searchable ? [...l, f[prop]] : l), []) ?? []

// Common fields

export const BUSINESS_FIELDS = form => [
  {
    heading: 'Company Details',
    hide: form.client_type == 'individual',
    view: false,
  },
  {
    name: 'client_type',
    label: 'Application Type',
    save: true,
    hide: true,
  },
  {
    name: 'company_name',
    hide: form.client_type == 'individual',
    disabled: true,
    view: false,
  },
  {
    name: 'company_type',
    hide: form.client_type == 'individual',
    disabled: true,
    view: false,
  },
  {
    name: 'company_email',
    hide: form.client_type == 'individual',
    disabled: true,
    view: false,
  },
  {
    name: 'trading_name',
    hide: form.client_type == 'individual',
    disabled: true,
    view: false,
  },
  {
    name: 'bl_number',
    label: 'Business Licence #',
    hide: form.client_type == 'individual',
    required: false,
    disabled: true,
    view: false,
  },
  {
    name: 'tin_number',
    label: 'TIN #',
    hide: form.client_type == 'individual',
    required: false,
    disabled: true,
    view: false,
  },
  {
    name: 'business_nib',
    label: 'Business NIB',
    hide: form.client_type == 'individual',
    disabled: true,
    view: false,
  },
  {
    heading: '<span>&nbsp;</span>',
    hide: form.client_type == 'individual',
  }
]

export const PO_BOX = {
  name: 'po_box',
  label: 'P. O. Box',
  required: false,
}

export const PHONE_NUMBER = {
   name: 'phone_number',
   label: 'Phone Number',
   use_profile: 'additional_phone_number'
}

export const MOBILE_NUMBER = {
   name: 'phone_number',
   label: 'Mobile Number',
   use_profile: 'additional_phone_number'
}

export const FIELD_WITH_OTHER = (form = {}, parent = {}) => [
  parent,
  {
    name: parent.name + '_other',
    label: 'Please Specify',
    hide: parent.multi
      ? !form[parent.name]?.has?.('Other')
      : form[parent.name] != 'Other'
  }
]

// Common option types
export const MALE_OR_FEMALE = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
]

export const Spinner = () => (
  <div className='flex flex-col items-center justify-center w-full p-4'>
    <div className='animate-spin text-5xl mb-4'>
      <i className='fas fa-circle-notch' />
    </div>
    <span className='text-2xl'>Loading...</span>
  </div>
)
