import React from 'react'

import Wizard from '@modules/form-wizard'
import islands from '@constants/islands'
import { common } from './duty-free'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class DutyFreeFishFarming extends React.Component {
  resource = '/duty_free_fish_farmings'
  form_key = 'duty_free_fish_farming'
  free = true

  defaults = {}
  business_only = true
  skip_location = true

  metadata = {
    denied: { license_numb: '####' }
  }

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'phone',
      is: 'phone',
      use_profile: 'additional_phone_number',
    },
    {
      name: 'po_box',
      label: 'P. O. Box',
      required: false,
    },
    {
      name: 'island',
      options: islands,
    },
    {
      name: 'company_name',
      label: 'Company Name',
    },
    {
      name: 'venture_type',
      label: 'Type of Venture',
    },
    '::Uploads',
    {
      name: 'permit_upload',
      key: 'image.2',
      type: 'file:all',
      label: 'Aquaculture/Fish Farming Permit'
    }
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Certificate of Registration',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...common(this)} />
}
