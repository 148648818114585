import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

// Section (5) subsection

export default class DocumentRequest extends React.Component {
  resource = '/'
  form_key = ''
  fee = 99
  free = false
  postpaid = false
  defaults = {}

  skip_location = false
  hide_buttons = false

  custom_actions = []

  validate = null

  foremost = null

  fields = form => []
  //tets

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
