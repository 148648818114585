/* These are import statements in JavaScript that import various modules and components needed for the
TradeUnionRegistration class to function properly. */
import React from 'react'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import occupations from '@constants/occupations'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

/* This code exports a React component called `TradeUnionRegistration` as the default export. The
component extends the `React.Component` class and defines various properties and methods that are
used to render a form wizard for registering a trade union. */
export default class TradeUnionRegistration extends React.Component {
  resource = '/trade_unions'
  form_key = 'trade_union'

  skip_location = true

  hide_buttons = {
    status: true,
    deny: true,
  }

  custom_actions = [
    {
      text: 'Approve For Payment',
      icon: 'check',
      roles: ['director'],
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Apprve For Payment',
          text: `
            Are you sure you want to approve this application and allow
            the applicant to pay & receive their certificate? 
          `,
        })

        if (isDismissed) return
        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_payment_amount', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'approved'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          toastr.success('Success', 'Application fee successfully set')
        } catch (err) {
          console.error(err)
          Swal.fire(
            'Oops...',
            'An error occurred while approving the application',
            'error'
          )
        }
      }
    },
  ]

 /* `defaults` is an object that contains default values for the fields in the form. These values will
 be used to pre-populate the form when it is first loaded. The default values include the type of
 union, whether a new name will be used, the name of the employer, the name of the union, the street
 address of the union, and information about the union officers, including their titles, names,
 email addresses, NIB numbers, addresses, and occupations. */
  defaults = {
    "union_type": "Union of Employees",
    "new_name": true,
    "employer_name": "Woodworking",
    "union_name": "Totally New Union #242",
    "union_street_address": "TEST STREET",
    "union_officers": [
      {
          "title": "President",
          "email": "test@email.com",
          "first_name": "John",
          "middle_name": "J",
          "last_name": "Doe",
          "nib": "12345678",
          "address": "TEST ADDRESS",
          "occupation": "Business Management & Administration"
      },
      {
          "title": "Treasurer",
          "email": "test@email.com",
          "first_name": "Jane",
          "middle_name": "",
          "last_name": "Doe",
          "nib": "754732624",
          "address": "Test Address",
          "occupation": "Arts, Audio/Video Technology & Communications"
      },
      {
          "title": "General Secretariat",
          "email": "test@email.com",
          "first_name": "Jack",
          "middle_name": "T",
          "last_name": "Doe",
          "nib": "9080083204",
          "address": "Test Stree",
          "occupation": "Agriculture, Food & Natural Resources"
      },
      {
          "title": "Trustee",
          "email": "test@email.com",
          "first_name": "Johnathon",
          "middle_name": "D",
          "last_name": "Munnings",
          "nib": "9080083204",
          "address": "Test Stree",
          "occupation": "Agriculture, Food & Natural Resources"
      }
    ],
  }

  validate = form => {

  }

  canned = {
    'TEST CANNED': `
      This is a test canne message; it can be whatever you like.
    `
  }

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      // TODO: Change to Sweetalert popup on start of application
      name: 'union_type',
      options: [
        'Union of Employees',
        'Union of Employers',
        // `Employer's Union`,
        // `Association of Employers`,
        // `Employee's Union`,
        // `Association of Employees`
      ]
    },
    {
      name: 'new_name',
      type: 'select:bool',
      label: 'Will this union be using a new name?',
    },
    {
      name: 'employer_name',
      label: `Craft/Industry`,
    },
    {
      name: 'union_name',
    },
    {
      name: 'union_street_address',
      label: 'Street Address',
    },
    // {
    //   heading: '<span></span>',
    //   // Pres, Treaurer, At least 2 Trustees, Secretary
    // },
    {
      name: 'union_officers',
      rowLabel: 'Officer',
      addLabel: 'Add Officer',
      min: form.client_type == 'business' ? 3 : 15,
      fields: form.union_type == 'Union of Employees'
      ? [
        {
          name: 'title',
          datalist: [
            'President',
            'Vice President',
            'Treasurer',
            'General Secretariat',
            'Trustee',
          ]
        },
        {
          name: 'email',

        },
        'first_name',
        {
          name: 'middle_name',
          required: false,
        },
        'last_name',
        {
          name: 'nib',
          label: 'NIB',
        },
        'address',
        {
          name: 'occupation',
          options: occupations,
        },
      ] : [
        {
          name: 'title',
          datalist: [
            'President',
            'Vice President',
            'Treasurer',
            'General Secretariat',
            'Trustee',
          ]
        },
        {
          name: 'email',
        },
        'business_name',
        {
          name: 'buusiness_nib',
          label: 'Business NIB',
        },
        'address',
      ],
    },
    {
      heading: '<span>&nbsp;</span>',
      view: false,
    },
    {
      name: 'constitution_upload',
      type: 'file:pdf',
      label: 'Copy of Constitution',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
