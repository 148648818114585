import React from 'react'
import moment from 'moment'
import { ValidatorForm } from 'react-form-validator-core'

import Fields from '@shared/form-fields'
import { extractKeys } from '@helpers/objects'

export default class AppointmentForm extends React.Component {
  state = {
    data: null,
    loading: true,
    location: '',
    date: null,
    timesExcluded: [],
    appointments: null,
    publicEvents: null,
    finishedDay: null,
  }

  async componentDidMount() {
    try {
      const { data: limits } = await $app.axios('/schedules/schedule_limits', {
        params: { 'scope[form_type]': this.props.service_type },
      })
      const { data: appts } = await $app.axios.get('/schedules')

      const { data: events } = await $app.axios.get('/calendar_events')

      const locations = limits.reduce((obj, loc) => {
        const [start, end] = loc.time_period[0].time.split('-')

        const [min, max] = [start, end].map(
          h => moment().hour(h).minute(0).second(0)._d
        )

        if (
          loc.form_type === 'IndividualLicense' &&
          loc.location !== 'sports stadium'
        ) {
          return obj
        }

        obj[loc.location] = {
          min,
          max,
          interval: loc.slot_interval,
          start,
          end,
          ...loc,
        }

        return obj
      }, {})

      this.setState({
        data: { locations },
        appointments: appts?.schedules,
        publicEvents: events?.event,
      })
    } catch (error) {
      console.error(error)
    }
  }

  on = {
    location: val => {
      this.getOccupiedSlots(val?.[0]?.value, new Date())
      this.setState({
        location: val?.[0]?.value,
      })
    },
    date: val => {
      this.getOccupiedSlots(this.state.location, val, true)
    },
  }

  getAppointmentSlots = (location, date) => {
    const { appointments } = this.state

    return appointments
      .filter(
        a =>
          true &&
          a.active &&
          a.schedulable_type == this.props.service_type &&
          a.location == location &&
          moment(a.slot).format('MMMM Do YYYY') ==
            moment(date).format('MMMM Do YYYY')
      )
      .map(a => a.slot.toString())
  }

  getVacantSlot = (selectedDate, timesExcluded, start, end) => {
    let startDate = start
    const times = timesExcluded.map(time => {
      return moment(time).format('MMMM Do YYYY, h:mm')
    })
    //Checks if there are blocked off times
    if (times.length) {
      //if time selected is not blocked off, return it
      if (
        !times.some(
          time => time === moment(selectedDate).format('MMMM Do YYYY, h:mm')
        ) &&
        moment(selectedDate)._d >= startDate
      )
        return selectedDate

      //otherwise... search for next available time slot
      while (startDate.getTime() <= end.getTime()) {
        if (times.includes(moment(startDate).format('MMMM Do YYYY, h:mm'))) {
          startDate = moment(startDate).add(20, 'm').toDate()
          continue
        }
        return startDate
      }
      return startDate
    } else if (moment(selectedDate)._d < startDate) {
      return startDate
    }

    return selectedDate
  }

  getOccupiedSlots = (location, date, setDate = false) => {
    const metadata = this.state.data.locations[location]
    let startDate = metadata?.min
    let endDate = metadata?.max
    const currentDate = new Date()
    const pastTimes = []
    const selectedDate = date
    let start = moment(date)
      .hour(Number(metadata?.start))
      .minute(0)
      .second(0)._d
    let end = moment(date).hour(Number(metadata?.end)).minute(0).second(0)._d

    if (!this.isAllowedDays(selectedDate)) {
      while (startDate?.getTime() <= endDate?.getTime()) {
        pastTimes.push(startDate)
        startDate = moment(startDate).add(metadata?.interval, 'm').toDate()
      }
    } else if (
      moment(selectedDate).format('MMMM Do YYYY') ==
      moment(currentDate).format('MMMM Do YYYY')
    ) {
      while (startDate?.getTime() < currentDate.getTime()) {
        pastTimes.push(startDate)
        startDate = moment(startDate).add(metadata?.interval, 'm').toDate()
      }
      if (pastTimes[pastTimes.length - 1]?.getTime() >= endDate?.getTime()) {
        this.setState({ finishedDay: pastTimes[pastTimes.length - 1] })
      }
    }

    const filledSlots = this.getFilledSlots(location, selectedDate)

    const timesExcluded = [...pastTimes, ...filledSlots]

    this.setState({
      timesExcluded,
      date: setDate
        ? this.getVacantSlot(selectedDate, timesExcluded, start, end)
        : null,
    })
  }

  getFilledSlots = (location, date) => {
    const metadata = this.state.data.locations[location]
    const counts = this.getAppointmentSlots(location, date)?.reduce(
      (tally, slot) => {
        tally[slot] = (tally[slot] || 0) + 1
        return tally
      },
      {}
    )

    return Object.keys(counts)
      .filter(slot => counts[slot] >= metadata?.time_period[0]?.max_slots)
      .map(slot => new Date(slot))
  }

  isDayBooked = (filledSlots, location) => {
    const times = filledSlots.map(slot => {
      return moment(slot).format('MMMM Do YYYY h:mm')
    })

    const metadata = this.state.data.locations[location]
    let start = moment(filledSlots[0])
      .hour(Number(metadata?.start))
      .minute(0)
      .second(0)._d
    let end = moment(filledSlots[0])
      .hour(Number(metadata?.end))
      .minute(0)
      .second(0)._d

    while (start.getTime() <= end.getTime()) {
      const startString = moment(start).format('MMMM Do YYYY h:mm')
      if (times.includes(startString)) {
        start = moment(start).add(metadata?.interval, 'm').toDate()
        continue
      }
      return false
    }

    return true
  }

  formatDate = date =>
    typeof date == 'string'
      ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
      : date

  submit = () =>
    this.props.submit({
      ...extractKeys(this.state, 'location', 'date'),
      schedulable_type: this.props.service_type,
    })

  isAllowedDays = date => {
    const location = this.state.location || 'sports stadium'

    const holidays = this.state?.publicEvents.map(e => {
      return moment(e.event_date).format('MMMM Do YYYY')
    })

    const filledSlots = this.getFilledSlots(location, date)

    const day = moment(date).day()
    return (
      day !== 0 &&
      day !== 6 &&
      !moment(date).isSame(this.state.finishedDay, 'day') &&
      !holidays.includes(moment(date).format('MMMM Do YYYY')) &&
      !this.isDayBooked(filledSlots, location)
    )
  }

  render() {
    const { state, props, on, submit, formatDate, isAllowedDays } = this

    const { timesExcluded } = state

    if (state.loading && !state.data) {
      return <div className='text-center'>Fetching Appointment Data...</div>
    }

    const metadata = state.data.locations[state.location]
    const apptDate = state.date ? formatDate(state.date) : null

    const locations = Object.keys(this.state.data.locations).map(l => ({
      label: l.capitalize(),
      value: l,
    }))

    const holidays = state?.publicEvents.map(e => {
      return moment(e.event_date)._d
    })

    return (
      <ValidatorForm
        id={props.form}
        className='form-wizard__form'
        onSubmit={submit}
      >
        <Field name='location' label='Location'>
          <Fields.Select
            name='location'
            options={locations}
            value={state.location}
            onChange={on.location}
            className='form-control'
            validators={['required']}
            errorMessages={['Required']}
            required
          />
        </Field>

        {metadata ? (
          <Field name='date' label='Date'>
            <Fields.Date
              type='date'
              className='form-control'
              name={'apptDate'}
              // autoComplete='off'
              showTimeSelect
              onChange={on.date}
              selected={apptDate}
              value={apptDate}
              validators={['required']}
              errorMessages={['Required']}
              dateFormat='MMMM d, yyyy h:mm aa'
              filterDate={isAllowedDays}
              minDate={new Date()}
              timeIntervals={metadata.interval}
              timeCaption='Slots'
              minTime={metadata.min}
              maxTime={metadata.max}
              excludeTimes={timesExcluded}
              excludeDates={holidays}
            />
          </Field>
        ) : null}
        {this.props.closeModal ? (
          <div className='modal-footer'>
            <button
              className='btn btn-round mr-2'
              aria-label='Close'
              onClick={this.props.closeModal}
            >
              Cancel
            </button>
            <button
              className='btn custom-btn btn-round'
              type='submit'
              form={props.form}
            >
              Submit
            </button>
          </div>
        ) : null}
      </ValidatorForm>
    )
  }
}

function Field({ name, label, children }) {
  return (
    <div className='form-group form-show-validation row'>
      <label
        htmlFor={name}
        className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
      >
        {label}
        <span className='required-label'>*</span>
      </label>
      <div>{children}</div>
    </div>
  )
}
