import React, { Fragment, isValidElement } from 'react'
import ReactDOM from 'react-dom'
import Swal from 'sweetalert2'
import Tooltip from 'react-tooltip'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import MaskedInput from 'react-input-mask'
import { ValidatorForm } from 'react-form-validator-core'

import { resetSessionTimeout } from '@reducers/session'
import Digger from '@modules/traverse-object'
import Fields from '@shared/form-fields'
import STRINGS from '@constants/strings'
import mapServiceName from '@constants/maps.services'
import ISLANDS from '@constants/islands'
import { extractKeys, withoutKeys, getProperty } from '@helpers/objects'
import normalizeField from '@helpers/normalize-form-field'
import convertToBase64 from '@helpers/file-to-base64'
import { findMultiSelected } from '@helpers/find-selected'

const ignoredKeys = [
  'uploading',
  'modal',
  'previews',
  'eligible',
  '__validated',
  undefined,
]

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: null,
      uploading: false,
      acceptance: !!props.custom_acceptance,
      previews: {},
      __validated: false,
      ...this.generateState(props),

      proxy: {
        use: null,
        nib: '',
      },
    }

    this.form = React.createRef()
    this.tables = {}

    this.additional_keys = props.skip_location
      ? new Set()
      : new Set(['pickup_location', 'pickup_sub_location'])
  }

  shouldPickup = (record = {}) => {
    const sl = this.props.skip_location

    switch (typeof sl) {
      case 'boolean':
        return !sl
      case 'function':
        return !sl(record)
      default:
        return !sl
    }
  }

  generateState = ({ fields, defaults, foremost, dependencies: deps }) => {
    const dateRx = /^2\d{3}-\d{2}-\d{2}/
    const isDate = str => dateRx.test(str)

    const initial = {
      pickup_location: '',
      pickup_sub_location: '',
      acceptance: false,
      eligible: !foremost,
    }

    let defs = defaults
    if (typeof defs == 'function') defs = defs()
    defs && Object.assign(initial, defs)

    // prettier-ignore
    deps && Object.assign(initial, {
      serviceables: Object.keys(deps).map(k => ({
        service_type: k,
        id: deps[k]?.id,
      })),
    })

    return fields(initial).reduce((state, field) => {
      if (field?.type == 'linked') return state

      // prettier-ignore
      if (typeof field == 'string') return field.startsWith('::')
        ? state
        : { ...state, [field]: initial?.[field] || '' }

      if (field.columns || field.fields) {
        // const idObj = { name: 'sort_id', label: 'ID' }
        // const filtered = field.columns.filter(c => c.name == 'sort_id')
        // if (filtered.length === 0) field.columns.unshift(idObj)

        const use = field.fields ?? field.columns

        // prettier-ignore
        state[field.name] = Array.isArray(initial[field.name])
          ? initial[field.name]
          : (field.rows
            ? Array(field.rows.length).fill(0)
                .map(() => this.freshTableRow(use, field.name))
            : [this.freshTableRow(use, field.name)]
          )
      } else if (field.fields) {
        state[field.name] = [this.freshTableRow(field.fields, field.name)]
      } else if (field.use_profile) {
        state[field.name] = getProperty($app.applicant, field.use_profile)
      } else if (field.type == 'checklist' && !field.single) {
        state[field.name] = initial?.[field.name] ?? new Set()
      } else if (field.type == 'date' && isDate(initial?.[field.name])) {
        state[field.name] = new Date(initial[field.name])
      } else if (field.options && field.multi) {
        state[field.name] = initial?.[field.name] instanceof Set
          || Array.isArray(initial?.[field.name])
          ? [...initial[field.name]]
          : []
      } else if (field.options || /^(file|select)/.test(field.type)) {
        state[field.name] = initial?.[field.name]
      } else if (field.type == 'checkbox') {
        state[field.name] = !!initial[field.name] || false
      } else {
        state[field.name] = initial?.[field.name] ?? ''
      }

      return state
    }, initial)
  }

  generateFileExample = example => {
    if (!example) return null

    const [headings, ...rows] = example
      .trim()
      .split('\n')
      .filter(line => !!line)
      .map(line => line.trim().split(','))

    const head = headings.map(h => <th key={h}>{h}</th>)
    const body = rows.map(row => {
      const tds = row.map(d => <td key={d}>{d}</td>)
      return <tr>{tds}</tr>
    })

    return (
      <table>
        <thead>
          <tr>{head}</tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
    )
  }

  options = arr =>
    arr?.length
      ? arr.map(o =>
          typeof o == 'string' ? { label: o.initialCaps(), value: o } : o
        )
      : []

  findSelected = (options, target) => [
    options.find(o => o.value === target) || {},
  ]

  snakeCase = str => str.trim().toLowerCase().replace(/[ -]/g, '_')

  formatDate = date => date
    ? (typeof date == 'string' ? new Date(date) : date)
    : null

  checkKnownTypes = (name, is) => {
    const obj = (fn, icon, regex, type, inputMode) => ({
      type,
      fn,
      icon,
      regex,
      inputMode,
    })

    const num = (fn, icon, regex) => obj(fn, icon, regex, 'text', 'decimal')
    const { on } = this

    if (/(first|middle|last)_name$/.test(name))
      return obj(on.alphanumeric, 'font')

    if (/^passport/.test(name) || is == 'passport')
      return obj(on.passport, 'passport')

    if (/^(drivers?_licen[cs]e|licen[cs]e_number)$/.test(name))
      return num(on.integer, 'id-card', /^[0-9]{0,9}$/)

    if (is == 'currency') return num(on.float, 'dollar-sign')

    if (is == 'time') return obj(on.text, 'clock')
    if (is == 'email') return obj(on.text, 'at')

    if (/^(integer|float)$/.test(is)) return num(on[is], 'hashtag')

    return obj(on.text, 'font')
  }

  getField = (field, index) => {
    const { fields, heading, table, array } = this
    const { type, options } = field

    const key = index ?? Math.random()

    if (typeof field == 'string') {
      if (field == '::SPACER') return heading('<span>&nbsp;</span>', key)
      if (field.startsWith('::')) return heading(field.slice(2), key)

      if (/^phone/.test(field)) return fields.phone(field)
      return fields.text(field)
    }

    if (field.heading && !field.hide) return heading(field.heading, key)

    if (!type && options) return fields.select(field)

    if (field.columns) {
      return table(field)
    }

    if (field.fields) {
      return array(field)
    }

    return (fields[type] || fields.text)(field)
  }

  heading = (label, key) => {
    if (isValidElement(label)) return <Fragment key={key}>{label}</Fragment>

    if (label)
      return (
        <div className='form-group row' key={key || label}>
          <h5
            style={{ marginLeft: '2rem' }}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </div>
      )

    return null
  }

  handleCallback = async (cb, val) => {
    if (typeof cb == 'function') {
      const result = await cb(val, this.state)

      if (['object', 'function'].includes(typeof result)) {
        this.setState(result)
      }
    }

    this.props.resetSessionTimeout()
    setTimeout(Tooltip.rebuild, 200)
  }

  selectPickerOption = (target, value) => () =>
    this.setState(
      state => {
        const finder = new Digger(state)
        finder.dig(target, value)
        return finder.data
      },
      () => this.props.picker.onSelect(value)
    )

  confirmPartialSave = async ev => {
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: 'Confirm',
      text: `
        Are you sure you want to save the current state of your application
        so that you may return and complete it at a a later time?
      `,
      showCancelButton: true,
      confirmButtonText: 'Save',
    })

    if (isDismissed) return
    this.attemptSubmit(ev, true)
  }

  attemptSubmit = async (ev, partial) => {
    const { props, prepare, shouldPickup } = this
    const { next, step, fields, custom_notice } = props
    const { modal, uploading, previews, __validated, eligible, ...state } = this.state
    
    if (process.env.NODE_ENV == 'development') {
      console.log('Attempting Form Submission')
      console.log(state)
    }

    let valid = false

    ev.preventDefault()
    if (state.uploading) return
    if (step == 0) return next()

    const form = fields(state)
    let payload = { ...state }
    if (process.env.NODE_ENV == 'development') {
      console.log(payload)
      console.log('form', form)
    }
    let skipPreparation = false

    if (!partial) {
      valid = await this.form.current.isFormValid(false)
      if (!valid) return

      if (!state.__validated) {
        valid = await props.validate(state)
        this.setState({ __validated: !!valid })

        if (valid?.__TRANSFORM__ === false) {
          delete valid.__TRANSFORM__
          skipPreparation = true
        }

        if (valid instanceof FormData) {
          skipPreparation = true
          payload = valid
        } else if (valid?.__OVERWRITE__) {
          delete valid.__OVERWRITE__
          payload = valid
        } else if (typeof valid == 'object') {
          this.additional_data = valid

          for (let key of Object.keys(valid)) {
            if (!form.find(f => f == key || f?.name == key)) {
              this.additional_keys.add(key)
            }
          }
        }
      }

      if (!valid) return
      if (step == 1 && shouldPickup(state)) return next()
    }

    const complete = async () => {
      if (!form.find(f => f.name == 'acceptance')) {
        this.additional_keys.add('acceptance')
      }

      if (this.additional_data) {
        Object.assign(payload, this.additional_data)
      }

      if (this.additional_keys) {
        form.push(...this.additional_keys)
      }

      const use = partial ? props.partialSave : props.save

      if (skipPreparation) {
        use(payload)
      } else if (props.generate_form_data === true) {
        use(await prepare.form(payload, form))
      } else if (props.multiple_uploads || props.generate_form_data === false) {
        use(await prepare.json(payload, form, true))
      } else if (form.some(f => f.save !== false && /^file/.test(f.type))) {
        use(await prepare.form(payload, form))
      } else {
        use(await prepare.json(payload, form))
      }
    }

    if (partial) return complete()
    if (custom_notice) await Swal.fire('Notice', custom_notice, 'info')

    const { isDismissed } = await Swal.fire({
      title: 'ABOUT THIS FORM',
      html: STRINGS.INFO.FORM_SUBMISSION,
      showCancelButton: true,
      cancelButtonText: 'I Disagree',
      confirmButtonText: 'I Agree',
      customClass: {
        htmlContainer: 'text-gray-800',
      },
    })

    if (isDismissed) {
      props.return?.()
    } else complete()
  }

  prepare = {
    json: async (state = {}, fields = [], uploads = false) => {
      const result = uploads ? { additional_images: [] } : {}

      for (let field of fields) {
        const key = typeof field == 'string' ? field : field.name

        if (
          false ||
          ignoredKeys.includes(key) ||
          field.admin ||
          field.profile ||
          field.save === false ||
          (field.hide && !field.save)
        )
          continue

        const val = state[key]
        if (['', undefined, null].includes(val)) continue

        // Automatically capture "other" type fields that
        // usually accompany some select boxes
        if ((val == 'other' || val == 'Other') && state[key + '_other']) {
          result[key] = state[key + '_other']
        } else if (val instanceof Set) {
          if (val.has('other') || val.has('Other')) {
            val.delete('other')
            val.delete('Other')
            val.add(state[key + '_other'])
          }
          
          result[key] = field.format?.(val, field) ?? [...val].join(', ')
        } else if (Array.isArray(val) && !field.columns && !field.fields) {
          const otherIndex = val.findIndex(v => /^other$/i.test(v))
          
          if (otherIndex > -1) {
            val.splice(otherIndex, 1, state[key + '_other'])
          }
          
          result[key] = field.format?.(val, field) ?? [...val].join(', ')
        } else if (/^file/.test(field.type) && field.save !== true) {
          result.additional_images.push({
            title: field.label ?? field.name.initialCaps(),
            desc: field.desc ?? field.label,
            url: await convertToBase64(state[field.name]),
          })
        } else if (field.columns || field.fields) {
          result[key] = typeof field.format == 'function'
            ? field.format(val, field)
            : val.map(JSON.stringify)
        } else if (typeof field.format == 'function') {
          result[key] = field.format(val, field)
        } else {
          result[key] = val
        }
      }

      return result
    },

    form: (state = {}, fields = []) => {
      const data = new FormData()
      const { form_key: f } = this.props

      for (let field of fields) {
        const key = typeof field == 'string' ? field : field.name

        if (
          false ||
          ignoredKeys.includes(key) ||
          field.admin ||
          field.profile ||
          field.save === false ||
          (field.hide && !field.save)
        )
          continue

        const val = state[key]
        let prop = f ? `${f}[${key}]` : key
        if (['', undefined, null].includes(state[key])) continue

        if (key == 'serviceables') {
          data.append(prop, JSON.stringify(val))
        } else if (key == 'multiple_uploads') {
          for (let v of val) {
            prop += '[]'
            data.append(prop, JSON.stringify(v))
          }
        } else if (field.columns || field.fields) {
          prop += '[]'
          for (let row of val) {
            data.append(
              prop,
              typeof field.format == 'function'
                ? field.format(row)
                : JSON.stringify(row)
            )
          }
        } else if (field.type == 'checklist') {
          data.append(
            prop,
            typeof field.format == 'function'
              ? field.format(val)
              : [...val].join(', ')
          )
        } else if (field.multi) {
          if (typeof field.format == 'function') {
            data.append(prop, field.format(val))
          } else if (Array.isArray(val)) {
            prop += '[]'
            for (let row of val) {
              data.append(prop, row)
            }
          } else {
            data.append(prop, val)
          }
        } else {
          data.append(prop, val)
        }
      }

      return data
    },
  }

  modal = {
    open: modal => this.setState({ modal }),
    close: () => this.setState({ modal: null }),
  }

  on = {
    island: values => {
      const pickup_location = values.length && values[0].value
      const l = this.props.locations[pickup_location]
      const pickup_sub_location = l?.length == 1 ? l[0] : null
      this.setState({ pickup_location, pickup_sub_location })
    },

    location: values => {
      const pickup_sub_location = values.length && values[0].value
      this.setState({ pickup_sub_location })
    },

    text: (name, rx, cb, format) => ev => {
      const val = ev.target.value
      if (rx && val && !rx.test(val)) return

      if (name.includes('::')) {
        const [table, index, prop] = name.split('::')
        // console.log(table, index, prop)
        this.setState(state => {
          state[table][index][prop] = format ? format(val) : val
          state[table][index][prop] = val
          // const indexType = typeof table[0];
          // if(indexType === 'object') {
          //   state[table][index][prop] = format ? format(val) : val
          //   state[table][index][prop] = val
          // } else {
          //   state[table][index]= format ? format(val) : val
          //   state[table][index]= val
          // }
          state.__validated = false
          return state
        })
        this.handleCallback(cb, val, index)
      } else {
        this.setState({
          [name]: format ? format(val) : val,
          __validated: false,
        })

        this.handleCallback(cb, val)
      }
    },

    select: (name, validator, cb, multi) => values => {
      const { value } = values?.[0] || {}
      if (value === undefined || (validator && !validator(value))) return

      if (name.includes('::')) {
        const [table, index, prop] = name.split('::')
        this.setState(state => {
          state[table][index][prop] = value
          state.__validated = false
          return state
        })
        this.handleCallback(cb, value, index)
      } else if (multi) {
        const newValues = values.map(v => v?.value)
        this.setState({ [name]: newValues, __validated: false })
      } else {
        this.setState({ [name]: value, __validated: false })
        this.handleCallback(cb, value)
      }
    },

    date: (name, validator, cb) => val => {
      if (validator && !validator(val)) return

      if (name.includes('::')) {
        const [table, index, prop] = name.split('::')
        this.setState(state => {
          state[table][index][prop] = val
          state.__validated = false
          return state
        })
        this.handleCallback(cb, val, index)
      } else {
        this.setState({ [name]: val, __validated: false })
        this.handleCallback(cb, val)
      }
    },

    // prettier-ignore
    check: (name, cb) => ({ target }) => {
      if (name.includes('::')) {
        const [table, index, prop] = name.split('::')
        this.setState(state => {
          state[table][index][prop] = target.checked
          state.__validated = false
          return state
        })

        this.handleCallback(cb, target.checked, index)
      } else {
        this.setState({ [name]: target.checked, __validated: false })
        this.handleCallback(cb, target.checked)
      }
    },
    
    // prettier-ignore
    radio: (name, cb) => ({ target }) => {
      if (name.includes('::')) {
        const [table, index, prop] = name.split('::')
        this.setState(state => {
          state[table][index][prop] = target.value
          state.__validated = false
          return state
        })
  
        this.handleCallback(cb, target.value, index)
      } else {
        this.setState({ [name]: target.value, __validated: false })
        this.handleCallback(cb, target.value)
      }
    },

    // prettier-ignore
    checklist: (name, cb, single = false) => ({ target }) => {
      this.setState(state => {
        if (single) {
          state[name] = target.checked ? target.value : ''
        } else {
          state[name][target.checked ? 'add' : 'delete'](target.value)
        }
        state.__validated = false
        return state
      })

      this.handleCallback(cb, this.state[name])
    },

    alphanumeric: (name, cb) => this.on.text(name, /^[0-9a-z .'-]{0,}$/i, cb),

    integer: (name, cb) => this.on.text(name, /^[0-9]+$/, cb),

    // prettier-ignore
    passport: (name, cb) => this.on.text(
      name,
      /^[adocre][a-tz]?[0-9]{0,7}$/i,
      cb,
      v => v.toUpperCase()
    ),

    float: (name, cb) => this.on.text(name, /^[0-9]+(\.[0-9]{0,})?$/, cb),

    currency: (name, cb) =>
      this.on.text(name, /^[0-9]{0,}(\.[0-9]{0,2})?$/, cb),

    // prettier-ignore
    file: ({ name, callback: cb, width, height, table, index }) => ({ target }) => {
      const file = target?.files?.[0]

      if (table) {
        this.setState(state => {
          state[table][index][name] = file
          state.__validated = false
          return state
        })
        this.handleCallback(cb, file, index)
      } else {
        this.setState({ [name]: file, __validated: false }, () => {})
        this.handleCallback(cb, file)
      }
    },

    block: field => val => {
      if (val === undefined) return

      const { back } = this.props
      const result = field.test(val)
      const block = {
        icon: 'error',
        heading: 'Unable To Continue',
        message:
          'Sorry, but you will be unable to continue with the provided response',
      }

      if (result === true) {
        this.setState({ eligible: true })
        return
      }

      if (typeof result == 'object') {
        Object.assign(block, result)
      } else if (typeof result == 'string') {
        block.message = result
      }

      Swal.fire({
        icon: block.icon,
        title: block.heading,
        html: block.message,
        confirmButtonText: 'Return',
      }).then(() => {
        typeof back == 'function' && back()
      })
    },
  }

  fields = {
    'text': field => {
      const { state, on, snakeCase, checkKnownTypes } = this

      if (typeof field == 'string') {
        const { fn, icon, regex, type, inputMode } = checkKnownTypes(field)
        const name = snakeCase(field)
        const label = field
          .replace(/^po_?box/, 'P. O. Box')
          .replace(/^wife_/, "wife's_")
          .replace(/^husband_/, "husband's_")
          .replace(/^mothers/, "mother's")
          .replace(/^fathers/, "father's")
          .replace(/^drivers/, "driver's")
          .initialCaps()

        return (
          <Field name={name} label={label} key={name}>
            <Fields.Input
              type={type || 'text'}
              name={name}
              icon={icon}
              inputMode={inputMode}
              value={state[name]}
              onChange={fn(
                name,
                // prettier-ignore
                regex || (/(first|middle|last)_name$/.test(name)
                  && /^[0-9a-z .'-]{0,}$/i)
              )}
              className='form-control'
              validators={['required']}
              errorMessages={['Required']}
            />
          </Field>
        )
      } else {
        const {
          name,
          label,
          is,
          long,
          regex,
          callback,
          datalist,
          tooltip,
          table,
          vertical,
          index,
          value,
          format,
          test,
          onBlur,
          ...rest
        } = field

        const fullName = table ? [table, index, name].join('::') : name
        const fullValue = table ? state[table][index][name] : state[name]

        const verify = test
          ? () => {
              on.block(field)(fullValue)
            }
          : null

        const fieldProps = {
          name,
          long,
          tooltip,
          key: name,
          label: label || name.initialCaps(),
          verify,
          ...(rest || {}),
        }

        if (value) {
          const r =
            rest.required === false
              ? rest
              : {
                  validators: ['required'],
                  errorMessages: ['Required'],
                  ...rest,
                }

          const v = typeof value == 'function' ? value(fullValue) : value
          const f = (
            <Fields.Input
              {...r}
              className='form-control'
              value={v}
              icon={table ? null : rest.icon}
              disabled
            />
          )

          return table && !vertical ? f : <Field {...fieldProps}>{f}</Field>
        }

        const {
          fn,
          icon,
          type,
          regex: rx,
          inputMode,
        } = checkKnownTypes(name, is || rest.type)

        const suggestions = datalist ? (
          <datalist id={name + '_datalist'}>
            {datalist.map((val, i) => (
              <option key={i}>{val}</option>
            ))}
          </datalist>
        ) : null

        const args =
          (on[is] || fn).length == 2
            ? [fullName, callback]
            : [fullName, regex || rx, callback, format]

        const props = {
          inputMode,
          name: fullName,
          type: type || 'text',
          icon: rest.icon || icon,
          list: name + '_datalist',
          value: fullValue,
          onBlur: onBlur
            ? ev => this.handleCallback(onBlur, fullValue)
            : undefined,

          onChange: (on[is] || fn)(...args),
          className: test ? 'form-control col' : 'form-control',

          ...(rest.required === false
            ? {}
            : {
                validators: ['required'],
                errorMessages: ['Required'],
              }),

          ...(table ? { icon: null } : {}),
          ...(rest || {}),
        }

        if (table && !vertical)
          return (
            <Fragment>
              <Fields.Input {...props} />
              {suggestions}
            </Fragment>
          )

        return (
          <Field {...fieldProps}>
            <Fields.Input {...props} />
            {suggestions}
          </Field>
        )
      }
    },

    'phone': field => {
      const { state, on } = this

      const {
        name,
        label,
        is,
        long,
        regex,
        callback,
        tooltip,
        table,
        vertical,
        index,
        value,
        format,
        onBlur,
        onChange,
        ...rest
      } = field

      const fullName = table ? [table, index, name].join('::') : name
      const fullValue = table ? state[table][index][name] : state[name]

      const fieldProps = {
        name,
        long,
        tooltip,
        key: name,
        label: label || name.initialCaps(),
        ...(rest || {}),
      }

      if (value) {
        const r = rest.required === false
          ? rest
          : {
            validators: ['required'],
            errorMessages: ['Required'],
            ...rest,
          }

        const v = typeof value == 'function' ? value(fullValue) : value

        const f = (
          <Field {...fieldProps}>
            <MaskedInput
              mask='(999) 999-9999'
              value={v}
              onChange={onChange}
              disabled={rest.disabled || false}
            >
              <Fields.Input
                {...r}
                className='form-control'
                value={v}
                icon={table ? null : rest.icon}
                disabled
              />
            </MaskedInput>
          </Field>
        )

        return table && !vertical ? f : <Field {...fieldProps}>{f}</Field>
      }

      const props = {
        name: fullName,
        icon: 'phone',
        type: 'tel',
        value: fullValue,
        onBlur: onBlur
          ? ev => this.handleCallback(onBlur, fullValue)
          : undefined,
        
        onChange: on.text(fullName, null, callback),
        className: 'form-control',
        inputMode: 'tel',

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...(table ? { icon: null } : {}),
        ...(rest || {}),
      }

      if (table && !vertical)
        return (
          <MaskedInput
            mask='(999) 999-9999'
            value={fullValue}
            onChange={props.onChange}
            disabled={props.disabled || false}
            children={() => <Fields.Input {...props} />}
          />
        )

      return (
        <Field {...fieldProps}>
          <MaskedInput
            mask='(999) 999-9999'
            value={fullValue}
            onChange={props.onChange}
            disabled={props.disabled || false}
            children={() => <Fields.Input {...props} />}
          />
        </Field>
      )
    
    },

    'select': field => {
      const { state, on, findSelected } = this
      const {
        name,
        label,
        long,
        options,
        validator,
        callback,
        tooltip,
        table,
        vertical,
        index,
        value,
        multi,
        other,
        ...rest
      } = field

      const opts = this.options(options)
      const fullName = table ? [table, index, name].join('::') : name
      const fullValue = table ? state[table][index][name] : state[name]

      const props = {
        name: fullName,
        options: opts,
        values: multi
          ? findMultiSelected(opts, fullValue)
          : findSelected(opts, fullValue),
        value: fullValue,
        onChange: field.test
          ? val => {
              if (!val || !val.length) return
              on.block(field)(val.length
                ? (multi ? val.map(v => v.value) : val[0].value)
                : null
              )
              on.select(name, validator, callback)(val)
            }
          : on.select(fullName, validator, callback, multi),
        className: 'form-control',
        required: rest.required || true,
        multi,
        create: other ? true : false,
        onCreateNew: other
          ? val => {
              on.select(fullName, validator, callback, multi)
            }
          : null,

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...(rest || {}),
      }

      if (table && !vertical) return <Fields.Select {...props} />

      const fieldProps = {
        name,
        long,
        tooltip,
        label: label || name.initialCaps(),
        key: name,
        ...(rest || {}),
      }

      return (
        <Field {...fieldProps}>
          <Fields.Select {...props} />
        </Field>
      )
    },

    'radio': field => {
      const { state, on } = this
      const { name, options, callback } = field

      const boxes = this.options(options).map((o, i) => (
        <div key={o.value}>
          <label className='form-radio'>
            <input
              type='radio'
              name={name}
              value={o.value}
              checked={state[name] == o.value}
              onChange={on.radio(name, callback)}
            />
            <span></span>
            <span>{o.label}</span>
          </label>
        </div>
      ))

      return (
        <Field
          {...extractKeys(field, 'name', 'stack', 'name:key')}
          label={field.label ?? field.name.initialCaps()}
          style={{ flexWrap: 'nowrap' }}
          wider={true}
        >
          <div>{boxes}</div>
        </Field>
      )
    },

    'date': field => {
      const { state, on, formatDate } = this
      const {
        name,
        label,
        placeholder,
        long,
        validator,
        callback,
        tooltip,
        table,
        vertical,
        index,
        ...rest
      } = field

      let selected = formatDate(state[name])
      let fullName = name
      
      if (table) {
        selected = formatDate(state[table][index][name])
        fullName = [table, index, name].join('::')
      }

      const props = {
        name: fullName,
        style: { width: 600 },
        selected: selected,
        value: selected,
        className: 'form-control',
        dateFormat: 'do MMMM, yyyy',
        dropdownMode: 'select',
        placeholderText: placeholder,
        onChange: on.date(fullName, validator, callback),

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...(rest || {}),
      }

      const fieldProps = {
        name,
        long,
        tooltip,
        label: label || name.initialCaps(),
        key: name,
        ...(rest || {}),
      }

      if (table && !vertical) {
        return <Fields.Date {...props} />
      }

      return (
        <Field {...fieldProps}>
          <Fields.Date {...props} />
        </Field>
      )
    },

    'file': field => {
      const { state, on } = this
      const {
        name,
        view,
        label,
        btnText,
        accept,
        long,
        parser,
        example,
        hint,
        tooltip,
        callback,
        send,
        maxSize,
        table,
        vertical,
        index,
        ...rest
      } = field

      const fullName = table ? [table, index, name].join('::') : name
      const fullValue = table ? state[table][index][name] : state[name]

      const props = {
        name: fullName,
        accept,
        label: btnText,
        value: fullValue,
        preview: state.previews[name],

        ...(rest.required === false
          ? {
              validators: [],
              errorMessages: [],
            }
          : {
              validators: [
                'required',
                'isFile',
                'maxFileSize:' + (maxSize || 3) * 1024 * 1024,
              ],
              errorMessages: [
                'Required',
                'A file is required',
                `File size must not exceed ${maxSize || 3}MB`,
              ],
            }),

        ...(rest || {}),

        onChange: on.file(field),
      }

      if (props.allow && field.required) {
        props.validators.push(`allowedExtensions:${props.allow}`)
        props.errorMessages.push(props.allow_msg)
      }

      const component = /^image/.test(accept) ? (
        <Fields.Image {...props} />
      ) : (
        <Fields.File {...props} />
      )

      if (table && !vertical) {
        return component
      }

      const fieldProps = {
        name,
        long,
        hint,
        tooltip,
        label: label || name.initialCaps(),
        key: name,
        ...(rest || {}),
      }

      return (
        <React.Fragment key={name}>
          <Field {...fieldProps}>{component}</Field>
        </React.Fragment>
      )
    },

    'checkbox': field => {
      const { state, on } = this
      const { name, label, callback, ...rest } = field

      return (
        <div key={name} className='form-check form-show-validation'>
          <Fields.Checkbox
            name={name}
            label={label}
            checked={state[name]}
            value={state[name]}
            className='form-check-input'
            onChange={on.check(name, callback)}
            required={rest.required !== false}
            validators={rest.validators ?? ['required']}
            errorMessages={rest.errorMessages ?? ['Checkbox must be filled']}
          />
        </div>
      )
    },

    'checklist': field => {
      const { state, on } = this
      const { name, options, callback, single, inline } = field
      const boxClasses = 'form-check' + (inline ? ' inline-block' : '')

      const boxes = this.options(options).map((o, i) => (
        <div key={o.value} className={boxClasses}>
          <label className='form-check-label flex items-center'>
            <input
              type='checkbox'
              name={name}
              value={o.value}
              checked={
                single ? state[name] == o.value : state[name].has(o.value)
              }
              onChange={on.checklist(name, callback, single)}
            />
            <span className='form-check-sign'>{o.label}</span>
          </label>
        </div>
      ))

      return (
        <Field
          {...extractKeys(field, 'name', 'stack', 'name:key')}
          label={field.label ?? field.name.initialCaps()}
          wider={true}
        >
          <div className='form-wizard-checklist'>{boxes}</div>
        </Field>
      )
    },

    'textarea': field => {
      const { state, on } = this
      const {
        name,
        label,
        list,
        hint,
        long,
        options,
        validator,
        callback,
        tooltip,
        table,
        vertical,
        index,
        value,
        multi,
        other,
        ...rest
      } = field

      const fullName = table ? [table, index, name].join('::') : name
      const fullValue = table ? state[table][index][name] : state[name]

      const format = val => {
        return list ? val.split(',') : val
      }

      const props = {
        name: fullName,
        value: fullValue,

        onChange: on.text(fullName, null, callback, format),
        className: 'form-control',

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...withoutKeys(rest, 'format'),
      }

      const fieldProps = {
        name,
        hint,
        tooltip,
        key: name,
        label: label || name.initialCaps(),
        required: rest.required !== false
        // stack: true,
      }

      return (
        <Field {...fieldProps}>
          <Fields.Textarea {...props} />
        </Field>
      )
    },

    'select:bool': field =>
      this.fields.select({
        ...field,
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      }),

    'file:image': field =>
      this.fields.file({
        allow: 'image/png,image/jpeg,image/jpg',
        allow_msg: 'Only PNG or JPG images are allowed',
        accept: 'image/*',
        hint: 'Accepts: .jpg, .png',
        ...field,
      }),

    'file:pdf': field =>
      this.fields.file({
        allow: 'application/pdf',
        allow_msg: 'Only PDF allowed',
        hint: 'Accepts: .pdf',
        ...field,
        accept: 'application/pdf',
        type: 'file',
      }),

    'file:all': field =>
      this.fields.file({
        allow: 'application/pdf,image/png,image/jpeg,image/jpg',
        allow_msg: 'Only PDF, JPG, JPEG and PNG allowed',
        hint: 'Accepts: .pdf, .jpg, .jpeg, .png',
        ...field,
        accept: 'application/pdf,image/jpeg,image/jpg,image/gif,image/png',
        type: 'file',
      }),

    'email': field =>
      this.fields.text({
        ...field,
        inputMode: 'email',
        validators: ['required', 'isEmail'],
        errorMessages: ['Required', 'Invalid email address'],
      }),
  }

  freshTableRow = (columns, name) =>
    columns?.reduce((obj, col) => {
      if (col.name === 'sort_id') {
        return {
          ...obj,
          [col.name]: this.state ? this.state[name].length + 1 : 1,
        }
      }

      if (typeof col == 'string')
        return {
          ...obj,
          [col]: '',
        }

      if (col.options)
        return {
          ...obj,
          [col.name]: null,
        }

      return {
        ...obj,
        [col.name]: '',
      }
    }, {})

  table = field => {
    const { state, getField, freshTableRow } = this
    const { name, label, columns, rows, fixed, footer, required } = field

    const headings = columns.map(normalizeField).map(c => {
      if (c.name == 'sort_id') {
        return <th key='id'>ID</th>
      }

      const [id, text] = [c.name, c.label]
      return <th key={id}>{text}</th>
    })

    const widths = columns.map(c => {
      if (c.name === 'sort_id') {
        return <col key='id' span='1' width='32px' />
      }
      return <col key={c.name || c} span='1' width={c.width || 'auto'} />
    })

    // prettier-ignore
    const fresh = () => this.setState(state => {
      state[name].push(freshTableRow(columns, name))
      return state
    })

    // prettier-ignore
    const remove = index => () => this.setState(state => {
      state[name].splice(index, 1)
      return state
    })

    const data = state[name].map((row, index) => {
      const fields = columns.map((col, i) => {
        if (col.name === 'sort_id') {
          return <td key={row?.sort_id}>{row?.sort_id}</td>
        }

        // prettier-ignore
        const props = typeof col == 'string'
          ? { name: col, table: name, index }
          : { ...col, table: name, index }

        return <td key={props.name}>{getField(withoutKeys(props, 'width'))}</td>
      })

      const close =
        !fixed && !rows && state[name].length > 1 ? (
          <button type='button' data-close onClick={remove(index)}>
            <i className='fas fa-times-circle'></i>
          </button>
        ) : null

      return (
        <tr key={index}>
          {rows?.length ? (
            <td>{rows[index]?.label ?? rows[index]?.initialCaps()}</td>
          ) : null}
          {fields}
          <td>{close}</td>
        </tr>
      )
    })

    return (
      <div
        key={name}
        data-field={name}
        className='form-wizard-form-table table'
        data-table
      >
        <h5>
          {label || name.initialCaps()}
          {required !== false ? (
            <span className='required-label'>*</span>
          ) : null}
        </h5>
        <table className='table table-striped table-striped-bg-black'>
          <colgroup>
            {rows ? (
              <col span='1' width={field.labelRowWidth ?? '32px'} />
            ) : null}
            {widths}
            {rows ? null : <col span='1' width='32px' />}
          </colgroup>
          <thead>
            <tr className='header'>
              {rows ? <th>{field.firstColumnLabel ?? ''}</th> : null}
              {headings}
              {rows ? null : <th>&nbsp;</th>}
            </tr>
          </thead>
          <tbody>{data}</tbody>
        </table>
        {footer ? footer : null}
        {rows || fixed ? null : (
          <aside className='flex justify-end m-3'>
            <button type='button' data-action onClick={fresh}>
              Add Row&nbsp;<i className='fas fa-plus-circle'></i>
            </button>
          </aside>
        )}
      </div>
    )
  }

  array = field => {
    const { state, getField, freshTableRow } = this
    const { name, label, fields, fixed, required } = field
    const vertical = true

    // prettier-ignore
    const fresh = () => this.setState(state => {
      state[name].push(freshTableRow(fields, name))
      return state
    })

    // prettier-ignore
    const remove = index => () => this.setState(state => {
      state[name].splice(index, 1)
      return state
    })

    const data = state[name].map((row, index) => {
      const inputs = fields
      .filter(col => !(typeof col.hide == 'function' ? col.hide(index) : col.hide))
      .map((col, i) => {
        // prettier-ignore
        const props = typeof col == 'string'
          ? { key: col, name: col, table: name, index, vertical }
          : { ...col, key: col.name, table: name, index, vertical }

        return getField(props)
      })

      // prettier-ignore
      const close = !fixed && state[name].length > 1 ? (
        <button type='button' data-close onClick={remove(index)}>
          <span className='hidden md:inline'>Remove&nbsp;&nbsp;</span>
          <i className='fas fa-times-circle'></i>
        </button>
      ) : null

      return (
        <div key={index} data-row>
          <div className='flex justify-between px-4'>
            <span>
              {typeof field.rowLabel == 'function'
                ? field.rowLabel(row, index)
                : `${field.rowLabel ?? 'Item'} #${index + 1}`
              }
            </span>
            {close}
          </div>
          {inputs}
        </div>
      )
    })

    return (
      <div key={name} data-field={name} data-array>
        <h5>
          {label || name.initialCaps()}
          {required !== false ? (
            <span className='required-label'>*</span>
          ) : null}
        </h5>
        {data}
        {fixed ? null : (
          <aside className='flex justify-end mx-3 mb-3 max-w-2xl'>
            <button type='button' data-action onClick={fresh}>
              {field.addLabel ?? 'Add Row'}&nbsp;
              <i className='fas fa-plus-circle'></i>
            </button>
          </aside>
        )}
      </div>
    )
  }

  render() {
    const {
      state,
      props,
      on,
      options,
      findSelected,
      getField,
      attemptSubmit,
      selectPickerOption,
    } = this

    if (!state.eligible) {
      return (
        <ValidatorForm
          id={props.name}
          onSubmit={ev => attemptSubmit(ev)}
          ref={this.form}
          action='#'
          className={'form-wizard__form' + (props.stacked ? ' stacked' : '')}
        >
          {getField({ name: 'is_eligible', ...props.foremost })}
        </ValidatorForm>
      )
    }

    if (props.step == 0 && props.dependencies) {
      const deps = props.dependencies

      const list = Object.keys(deps).map(key => {
        const val = deps[key]
        const decision = val?.application_decision.toLowerCase()

        return (
          <div key={key} data-decision={decision || 'none'}>
            <strong>{mapServiceName(key)}</strong>
            <strong>{decision || 'Please Apply'}</strong>
          </div>
        )
      })

      const post = props.missingDeps
        ? 'Please apply for all the above services before applying for this service'
        : ''

      return (
        <ValidatorForm
          id={props.name}
          onSubmit={attemptSubmit}
          className='text-center form-wizard__dependencies'
        >
          <p>
            This application depends on <strong>{list.length}</strong>{' '}
            additional services:
          </p>
          <br />
          {list}
          <br />
          {post}
        </ValidatorForm>
      )
    }

    if (props.step == 2 && this.shouldPickup(state)) {
      const included = Object.keys(props.locations)
      const locations = options(props.locations[state.pickup_location] || [])
      const islands = ISLANDS.filter(island => included.includes(island.value))

      return (
        <ValidatorForm
          id={props.name}
          onSubmit={attemptSubmit}
          ref={this.form}
          action='#'
          className='form-wizard__form'
        >
          <Field name='island' label='Island'>
            <Fields.Select
              name='island'
              values={findSelected(islands, state.pickup_location)}
              value={state.pickup_location}
              options={islands}
              onChange={on.island}
              className='form-control'
              validators={['required']}
              errorMessages={['Please select an island']}
              required
            />
          </Field>
          <Field name='location' label='Location'>
            <Fields.Select
              key={state.pickup_location}
              name='island'
              values={findSelected(locations, state.pickup_sub_location)}
              value={state.pickup_sub_location}
              options={locations}
              onChange={on.location}
              className='form-control'
              validators={['required']}
              errorMessages={['Please select a pickup location']}
              disabled={!state.pickup_location}
              required
            />
          </Field>
        </ValidatorForm>
      )
    }

    const form = props.fields(state).reduce((fields, f, i) => {
      if (!f || f.hide || f.admin || f.profile) return fields
      if (f.type == 'linked') return fields

      delete f.hide
      delete f.admin
      delete f.view
      delete f.value
      delete f.save

      fields.push(getField(f, i))
      return fields
    }, [])

    const pickerOptions =
      props.picker?.options.map((opt, i) => (
        <div
          key={i}
          onClick={selectPickerOption(props.picker.target, opt.value)}
          dangerouslySetInnerHTML={{ __html: opt.label }}
        />
      )) || null

    return (
      <React.Fragment>
        <ValidatorForm
          id={props.name}
          onSubmit={attemptSubmit}
          ref={this.form}
          action='#'
          className='form-wizard__form'
        >
          {form}
          <Tooltip id='form' html={true} />
          {props.custom_acceptance ? null : (
            <this.fields.checkbox
              name='acceptance'
              label='By clicking here you agree that the information provided is accurate.'
            />
          )}
          <br />
          <p>
            <span className='required-label'>*</span>&nbsp; indicates that the
            field is required.
          </p>
        </ValidatorForm>
        {props.continueButton &&
          ReactDOM.createPortal(
            <div className='pull-right'>
              <span
                className='btn btn-muted border mr-4 hover:bg-gray-100'
                onClick={this.confirmPartialSave}
              >
                Save & Exit
              </span>
            </div>,
            props.continueButton
          )}
        <Modal
          open={props.picker}
          onClose={() => props.picker?.onSelect(null)}
          closeOnEsc={!pickerOptions?.length || !props.picker?.unskippable}
          closeOnOverlayClick={
            !pickerOptions?.length || !props.picker?.unskippable
          }
          showCloseIcon={!pickerOptions?.length || !props.picker?.unskippable}
          center
        >
          <div className='modal-header'>
            <h5>{props.picker?.heading || 'Please Select An Option'}</h5>
          </div>
          <div className='modal-body form-wizard-picker-options'>
            {pickerOptions}
          </div>
          {!props.picker?.unskippable && pickerOptions?.length ? (
            <div className='modal-footer'>
              <button
                className='btn custom-btn'
                onClick={() => props.picker.onSelect(null)}
              >
                Cancel
              </button>
            </div>
          ) : null}
        </Modal>
      </React.Fragment>
    )
  }
}

function Field({
  name,
  style,
  label,
  required,
  long,
  stack,
  hint,
  tooltip,
  children,
  verify,
  wider,
}) {
  const labelClasses = long
    ? 'col-lg-6 col-md-6 col-sm-6 text-right large-label'
    : 'col-lg-3 col-md-3 col-sm-4 text-right ' + (wider ? 'wide-input' : '')

  const hintText = hint ? (
    <span
      className='inline-block text-muted text-sm mt-1 mx-2'
      dangerouslySetInnerHTML={{ __html: hint }}
    ></span>
  ) : null

  const info = tooltip ? (
    <span data-for='form' data-tip={tooltip}>
      <i className='fas fa-info-circle'></i>
    </span>
  ) : null

  const verifyButton = verify ? (
    <div className='text-right col mb-5'>
      <button
        type='submit'
        className='btn custom-btn mr-2'
        onClick={() => {
          verify()
        }}
      >
        Ente
      </button>
    </div>
  ) : null

  return (
    <div
      style={style}
      className={
        'form-group form-show-validation row' + (stack ? ' stacked-field' : '')
      }
    >
      <label
        htmlFor={name}
        className={labelClasses}
        style={long || stack ? { whiteSpace: 'normal' } : null}
      >
        {label}
        {required !== false ? <span className='required-label'>*</span> : null}
      </label>
      <div>
        {children}
        {hintText}
      </div>
      {verifyButton}
      {info}
    </div>
  )
}

export default connect(() => ({}), { resetSessionTimeout })(Form)
