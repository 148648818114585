import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import generateRange from '@helpers/range'
import { BGCSES, BJCS } from '@constants/national-exams'
import nationalities from '@constants/nationalities'

export default class FreshStartProgramme extends React.Component {
  resource = '/youth_fresh_starts'
  form_key = 'youth_fresh_start'
  defaults = {}

  // Delete lines as needed
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    `::Child's Details`,
    {
      name: 'youth_first_name',
      label: 'First Name'
    },
    {
      name: 'youth_middle_name',
      label: 'Middle Name'
    },
    {
      name: 'youth_last_name',
      label: 'Last Name'
    },
    {
      name: 'youth_nib',
      is: 'integer',
      label: 'NIB#'
    },
    {
      name: 'youth_date_of_birth',
      type: 'date',
      label: 'Date of Birth',
      showYearDropdown: true,
    },
    {
      name: 'youth_nationality',
      otpions: nationalities,
    },
    {
      name: 'youth_sex',
      label: 'Sex',
      options: ['Male', 'Female']
    },
    {
      name: 'youth_phone_numb',
      type: 'phone',
      label: 'Primary Contact #',
      required: false,
    },
    {
      name: 'youth_home_numb',
      type: 'phone',
      label: 'Home Contact #',
      required: false,
    },
    {
      name: 'youth_address',
      label: 'Address',
    },
    {
      name: 'youth_po_box',
      label: 'P. O. Box',
      required: false
    },
    {
      name: 'youth_email',
      label: 'Email Address',
      type: 'email',
    },
    {
      name: 'youth_relationship',
      label: 'Relationship To You',
      datalist: [
        'Son',
        'Daughter',
        'Niece',
        'Nephew',
        'Cousin',
        'Grandson',
        'Granddaughter',
      ]
    },
    {
      name: 'schools_attended_list',
      label: 'Schoold Attended (Most Recent First)',
      columns: ['school'],
      format: v => v.school,
    },
    {
      name: 'grade_level_completed',
      label: 'Highest Grade Completed',
      options: generateRange(1, 12).reverse().map(n => ({
        label: n,
        value: n
      }))
    },
    {
      name: 'bjcs_passed_list',
      label: `BJC's Passed`,
      multi: true,
      options: BJCS,
      hide: form.grade_level_completed < 7
    },
    {
      name: 'bgcses_passed_list',
      label: `BGCSE's Passed`,
      multi: true,
      options: BGCSES,
      hide: form.grade_level_completed < 10
    },
    {
      name: 'computer_literate',
      label: 'Are you able to use a computer?',
      type: 'select:bool',
    },
    {
      name: 'accomplishments_list',
      label: 'Other Awards/Accomplishments',
      columns: ['accomplishment'],
      format: v => v.accomplishment
    },
    {
      name: 'last_place_employyed',
      label: 'Place of Last Employment',
      required: false,
      hint: 'Leave blank if not applicable'
    },
    {
      name: 'employment_interest',
      // label: '',
    },
    {
      name: 'completion_goals',
      type: 'textarea',
      label: 'What are your goals upon completing the programme?',
    },
    {
      name: 't_shirt_size',
      label: 'T-Shirt Size',
      options: [
        'Small',
        'Medium',
        'Large',
        'X-Large',
        '2X-Large',
        '3X-Large',
        '4X-Large',
      ],
    },

    '::Personal Details',
    {
      name: 'number_of_dependents',
      is: 'integer',
    },
    {
      name: 'place_of_employment',
    },

    '::Uploads',
    {
      name: 'police_cert_upload',
      type: 'file:image',
      label: 'Current Police Record',
    },
    {
      name: 'photo_upload',
      type: 'file:image',
      label: `Passport Photo of Child`,
    },
    {
      name: 'identification_upload',
      type: 'file:image',
      label: 'Passport/Birth Certificate',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
