import React from 'react'
import moment from 'moment'
import ReactDOM from 'react-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'
import AppGlobals from '@modules/setup-globals'

import 'react-responsive-modal/styles.css'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import './index.css'

/* eslint-disable */
String.prototype.spaceBeforeCap = function () {
  return this.replace(/([A-Z])/g, ' $1').trim()
}
String.prototype.capitalize = function () {
  return this.replace(/(?:^|\s)\S/g, c => c.toUpperCase())
    .replace(/ Ii$/, 'II')
    .replace(/Of\b/, 'of')
}

String.prototype.initialCaps = function () {
  return this.trim()
    .split(/[ _-]/)
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ')
}

String.prototype.dashCase = function () {
  return this.toLowerCase()
    .replace(/[^-_ 0-9a-z]+/, '')
    .replace(/[ _-]+/g, '-')
}

Date.prototype.dateMonth = function () {
  return moment(this).format('DD MMM')
}

Date.prototype.dateMonthTime = function () {
  return moment(this).format('DD MMM HH:mm')
}
/* eslint-enable */

global.$app = new AppGlobals(sessionStorage.user)

// App and Admin routes
ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
