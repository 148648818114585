import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ElectricalPermitExtension extends React.Component {
  resource = '/electrical_permits'
  form_key = 'electrical_permit'
  defaults = {
    is_extension: true,
  }
  read_params = {
    // admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        is_extension: true,
      },
    },
  }

  // Delete lines as needed
  // fee = 99
  free = true
  // postpaid = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null


  fields = form => [
    {
      name: 'is_extension',
      save: true,
      hide: true,
    },
    {
      name: 'specification_number',
      label: 'Specification No.',
    },
    {
      name: 'building_permit_numb',
      label: 'Building Permit number',
    },
    '::Property Owner',
    {
      name: 'property_owner',
      label: 'I am the Property Owner',
      type: 'select:bool',
    },
    {
      name: 'property_owner_first_name',
      label: 'First Name',
      hide: form.property_owner,
    },
    {
      name: 'property_owner_last_name',
      label: 'Last Name',
      hide: form.property_owner,
    },
    '::Property information',
    {
      name: 'building_po_box',
      label: 'P.O. Box of Building',
    },
    {
      name: 'building_address',
      label: 'Address of Building',
    },
    {
      name: 'total_floor_area',
      label: 'Total Floor Area',
    },
    {
      name: 'sq_ft',
      label: 'SQ. FT.',
    },
    '::Supply Required',
    {
      name: 'type_of_occupancy',
      label: 'Premises used as',
      options: [
        'Residential',
        'Commercial',
        'Industrial'
      ],
    },
    {
      name: 'current_supply_req',
      label: 'Existing Supply Required',
      options: [
        'Overhead',
        'Underground',
        '2-wires',
        '3-wires',
        '4-wires',
      ],
    },
    {
      name: 'voltage',
      label: 'Voltage',
      options: [
        '120/208',
        '120/240',
        '240/416',
        '254/440',
        '266/460',
        '277/480',
      ],
    },
    {
      name: 'supply_req',
      label: 'Supply to be Changed to',
      options: [
        'Overhead',
        'Underground',
        '2-wires',
        '3-wires',
        '4-wires',
      ],
    },
    {
      name: 'current_main_switch_size',
      label: 'Size of Existing Main Switch',
      hint: 'AMPS'
    },
    {
      name: 'current_service_conduit_size',
      label: 'Existing Service Conduit Size',
      // hint: ''
    },
    {
      name: 'current_service_conductors_list',
      label: 'Existing Service Conductors (Size + Insulation)',
      rowLabel: 'Existing Conductors',
      fields: [
        { name: 'size', label: 'Size', type: 'integer' },
        { name: 'insulation', label: 'Insulation' },
      ],
    },
    {
      name: 'main_switch_size',
      label: 'Main Switch Being Changed to',
      hint: 'AMPS'
    },
    {
      name: 'service_conduit_size',
      label: 'Service Conduit Being Changed to Size',
      // hint: ''
    },
    {
      name: 'service_conductors_list',
      label: 'Service Conductors Being Changed to (Size + Insulation)',
      rowLabel: 'Conductors',
      fields: [
        { name: 'size', label: 'Size', type: 'integer' },
        { name: 'insulation', label: 'Insulation' },
      ],
    },
    {
      name: 'max_demand',
      label: 'Existing Max. Demand',
      hint: 'KVA'
    },
    '::Details of Existing Load',
    {
      name: 'current_lighting',
      label: 'Lighting',
      hint: 'KW',
    },
    {
      name: 'current_water_heater',
      label: 'Water Heater',
      hint: 'KW',
    },
    {
      name: 'current_range',
      label: 'Range(s)',
      hint: 'KW',
    },
    {
      name: 'current_other_load_list',
      label: 'Other',
      required: false,
      hint: 'KW',
      fields: [
        { name: 'purpose', label: 'Purpose' },
        { name: 'voltage', label: 'Voltage', type: 'integer' },
      ],
    },
    {
      name: 'current_motors_list',
      label: 'Motors',
      required: false,
      rowLabel: 'Motors',
      fields: [
        { name: 'number', label: 'No.', type: 'integer' },
        { name: 'purpose', label: 'Purpose' },
        { name: 'voltage', label: 'Voltage', type: 'integer' },
        { name: 'horse_power', label: 'H. P.', type: 'integer' },
        { name: 'phase', label: 'Phase' },
      ],
    },
    '::Details of Additional Load',
    {
      name: 'lighting',
      label: 'Lighting',
      hint: 'KW',
    },
    {
      name: 'water_heater',
      label: 'Water Heater',
      hint: 'KW',
    },
    {
      name: 'range',
      label: 'Range(s)',
      hint: 'KW',
    },
    {
      name: 'other_load_list',
      label: 'Other',
      hint: 'KW',
      fields: [
        { name: 'purpose', label: 'Purpose' },
        { name: 'voltage', label: 'Voltage', type: 'integer' },
      ],
    },
    {
      name: 'motors_list',
      label: 'Motors',
      rowLabel: 'Motors',
      fields: [
        { name: 'number', label: 'No.', type: 'integer' },
        { name: 'purpose', label: 'Purpose' },
        { name: 'voltage', label: 'Voltage', type: 'integer' },
        { name: 'horse_power', label: 'H. P.', type: 'integer' },
        { name: 'phase', label: 'Phase' },
      ],
    },
    '::Site Inspection Date',
    {
      name: 'inspection_date',
      label: 'Inspection Date',
      type: 'date',
    },
    '::SPACER',
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
