import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import occupations from '@constants/occupations'

const commonInstitutions = [
  'Commonwealth Bank',
  'First Caribbean International Bank',
  'Royal Bank of Canada',
  'Scotiabank',
  'Finco',
  'Teacher\'s Credit Union',
]

export default class HomeOwnerMortgage extends React.Component {
  resource = '/home_owner_mortgages'
  form_key = 'home_owner_mortgage'
  fee      = 99
  free     = true
  defaults = {}

  skip_location = true
  hide_buttons  = false

  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    // Pull and update address info from Addresses Table
    // {
    //   name: 'street_address',
    //   use_profile: 'street_address',
    // },
    // {
    //   name: 'po_box',
    //   label: 'P. O. Box',
    //   use_profile: 'po_box',
    //   required: false,
    // },
    // {
    //   name: 'home_number',
    //   use_profile: 'phone_number',
    // },
    // {
    //   name: 'mobile_number',
    //   use_profile: 'additional_phone_number',
    // },

    {
      name: 'number_of_dependents',
      label: '# of Dependents',
      is: 'integer',
    },
    {
      name: 'self_employed',
      label: 'Are you self employed?',
      type: 'select:bool'
    },
    {
      name: 'occupation',
      options: occupations,
      use_profile: 'occupation',
    },

    '::Employment & Income',
    {
      name: 'employer',
    },
    {
      name: 'employment_date',
      label: 'Start of Employment Date',
      type: 'date',
    },
    {
      name: 'annual_full_time_salary',
      label: 'Annual Salary (Full Time)',
      is: 'currency',
    },
    {
      name: 'annual_part_time_salary',
      label: 'Annual Salary (Part Time)',
      is: 'currency',
      required: false,
    },
    {
      name: 'gratuity',
      is: 'currency',
      required: false,
    },
    {
      name: 'pay_cycle',
      options: ['weekly', 'bi-weekly', 'monthly']
    },
    {
      name: 'loans_and_credits',
      label: 'Loans & Credits',
      fields: [
        {
          name: 'type_of_loan',
          label: 'Type',
          options: [
            { value: 'loan', label: 'Loan' },
            { value: 'credit card', label: 'Credit Card' },
          ]
        },
        {
          name: 'financial_institution',
          datalist: commonInstitutions
        },
        {
          name: 'balance',
          is: 'currency',
        },
        {
          name: 'monthly_payment',
          is: 'currency',
        },
        {
          name: 'maturity_date',
          type: 'date',
        },
      ]
    },
    {
      name: 'total_loans',
      hide: true,
      view: {
        element: (noop, { loans_and_credits }) => (
          <div className=''>

          </div>
        )
      }
    },
    {
      name: 'savings_and_investments',
      label: 'Savings & Investments',
      columns: [
        {
          name: 'type_of_asset',
          options: [
            { label: 'Deposit Account', value: 'deposit account' },
            { label: 'Real Estate', value: 'real estate' },
            { label: 'Investment', value: 'investment' },
          ]
        },
        {
          name: 'financial_institution',
          datalist: commonInstitutions
        },
        {
          name: 'balance_or_value',
          is: 'currency',
        }
      ]
    },

    '::Uploads',
    {
      name: 'passport_upload',
      type: 'file:all',
      save: true,
    },
    {
      name: 'identification_upload',
      type: 'file:all',
      save: true,
    },
    {
      name: 'address_proof_upload',
      type: 'file:all',
      save: true,
    },
    {
      name: 'employment_letter_upload',
      type: 'file:all',
      save: true,
    },
    {
      name: 'employment_pay_slip_upload',
      type: 'file:all',
      save: true,
    },
    {
      name: 'declare',
      save: true,
      label: `
        By clicking this box, I confirm that I understand my obligations under
        the respective laws and regulations applicable to the application for 
        home owner's mortgage and agree to observe all applicable laws,
        regulations, policies and guidelines.
      `,
      type: 'checkbox',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
