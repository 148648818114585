import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import islands from '@constants/islands'

export default class BuilderStatusApplication extends React.Component {
  resource = '/approved_builders'
  form_key = 'approved_builder'
  defaults = {}

  // Delete lines as needed
  free = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null

  /*
  t.string :form_num
t.string :payment_amount
t.string :payment_invoice
t.string :payment_status
t.string :application_status
t.string :application_decision, default: 'pending'
t.boolean :acceptance, default: false
t.boolean :declare, default: false
t.string :pickup_location
t.string :pickup_sub_location
t.belongs_to :user, foreign_key: true
t.belongs_to :delivery, foreign_key: true, null: true
t.belongs_to :officer, foreign_key: { to_table: :users }, null: true
t.belongs_to :proxy, foreign_key: { to_table: :users }, null: true
t.string :delivery_status
t.date :app_status_change_date
t.string :ip_address
t.boolean :requested_proxy, default: false

# DIR Verification Info
t.string :client_type
t.string :company_name
t.string :company_email
t.string :bl_number
t.string :tin_number
t.string :business_nib
t.string :street_address
t.string :po_box
t.string :phone_number
t.string :trading_name
t.string :company_type
      
t.string :apprenticeship_period
t.string :apprenticeship_company
t.string :journeyman_hash_list, array: true, default: [] # [{years: 5, company_name}]
t.string :general_foremen_hash_list, array: true, default: [] # [{years: 5, company_name}]
t.string :supervisor_hash_list, array: true, default: [] # [{years: 5, company_name}]
t.date :commencement_date
t.string :bank_name
t.string :bank_address
t.string :qualifications_list, array: true, default: []
t.string :creditors_list, array: true, default: [] # [{name, address}]
t.string :references_list, array: true, default: [] # [{name, address}]
t.string :construction_list, array: true, default: [] # [{name, building_size, total_cost, bedrooms, bathrooms, island, city, settlement, address}]

t.attachment :reference_letter_upload
t.attachment :reference_photo_upload
t.attachment :reference_photo_2_upload
t.attachment :reference_photo_3_upload
t.attachment :nib_compliance_upload
t.attachment :bank_reference_upload
t.attachment :sales_reference_upload
t.attachment :personal_reference_upload
t.attachment :personal_reference_2_upload
t.attachment :personal_reference_3_upload
t.attachment :engineer_reference_upload
t.attachment :town_reference_upload
t.attachment :certficate_upload
t.attachment :certficate_2_upload
t.attachment :certficate_3_upload
  */

  fields = form => [
    ...BUSINESS_FIELDS(form),

    {
      name: 'apprenticeship_period',
      hint: 'State the period of apprenticeship and under whom',
      type: 'textarea',
    },
    {
      name: 'apprenticeship_company',
      hint: 'State names of builders under whom you have worked and for what period in respect of each case',
      type: 'textarea',
    },
    {
      name: 'journeyman_hash_list',
      label: 'Journeyman/Carpenter/Mason',
      type: 'table',
      columns: ['years', 'company name'],
    },
    {
      name: 'general_foremen_hash_list',
      label: 'General Foremen',
      type: 'table',
      columns: ['years', 'company name'],
    },
    {
      name: 'supervisor_hash_list',
      label: 'Supervisor or Superintendent',
      type: 'table',
      columns: ['years', 'company name'],
    },
    {
      name: 'commencement_date',
      label: 'Date started',
      type: 'date',
    },
    {
      name: 'qualifications_list',
      label: 'Qualifications',
      columns: ['qualifcation'],
      format: row => row.qualification,
    },
    {
      name: 'creditors_list',
      columns: ['name', 'address'],
    },
    {
      name: 'references_list',
      label: 'References',
      columns: ['name', 'address'],
    },
    {
      name: 'construction_list',
      label: 'Construction',
      columns: [
        {
          name: 'name',
        },
        {
          name: 'building_size',
        },
        {
          name: 'total_cost',
          is: 'currency',
        },
        {
          name: 'bedrooms',
          is: 'integer',
        },
        {
          name: 'bathrooms',
          is: 'integer',
        },
        {
          name: 'island',
          options: islands,
        },
        {
          name: 'city',
        },
        {
          name: 'settlement',
        },
        {
          name: 'address',
        },
      ],
    },
    {
      name: 'bank_name',
    },
    {
      name: 'bank_address',
    },
    {
      name: 'reference_letter_upload',
      label: 'Reference letter',
      key: 'image.0',
      type: 'file:pdf',
    },
    {
      name: 'reference_photo_upload',
      label: 'Reference photo',
      key: 'image.1',
      type: 'file:image',
    },
    {
      name: 'reference_photo_2_upload',
      label: 'Reference photo 2',
      key: 'image.2',
      type: 'file:image',
    },
    {
      name: 'reference_photo_3_upload',
      label: 'Reference photo 3',
      key: 'image.3',
      type: 'file:image',
    },
    {
      name: 'nib_compliance_upload',
      label: 'NIB Compliance',
      key: 'image.4',
      type: 'file:pdf',
    },
    {
      name: 'bank_reference_upload',
      label: 'Bank reference',
      key: 'image.5',
      type: 'file:pdf',
    },
    {
      name: 'sales_reference_upload',
      label: 'Sales reference',
      key: 'image.6',
      type: 'file:pdf',
    },
    {
      name: 'personal_reference_upload',
      label: 'Personal reference',
      key: 'image.7',
      type: 'file:pdf',
    },
    {
      name: 'personal_reference_2_upload',
      label: 'Personal reference 2',
      key: 'image.8',
      type: 'file:pdf',
    },
    {
      name: 'personal_reference_3_upload',
      label: 'Personal reference 3',
      key: 'image.9',
      type: 'file:pdf',
    },
    {
      name: 'engineer_reference_upload',
      label: 'Engineer reference',
      key: 'image.10',
      type: 'file:pdf',
    },
    {
      name: 'town_reference_upload',
      label: 'Town reference',
      key: 'image.11',
      type: 'file:pdf',
    },
    {
      name: 'certficate_upload',
      label: 'Certificate',
      key: 'image.12',
      type: 'file:pdf',
    },
    {
      name: 'certficate_2_upload',
      label: 'Certificate 2',
      key: 'image.13',
      type: 'file:pdf',
    },
    {
      name: 'certficate_3_upload',
      label: 'Certificate 3',
      key: 'image.14',
      type: 'file:pdf',
    },
  ]
  hidden_fields = () => [
    {
      name: 'pdf_upload',
      label: 'Upload pdf',
    },
  ]
  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
