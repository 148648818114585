import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import Loader from '@shared/loader'
import SERVICE_PROPS from '@helpers/extract-service-props'
import STRINGS from '@constants/strings'

export default class LicenceRenewal extends React.Component {
  resource = '/drivers_licenses'
  form_key = 'drivers_license'
  c_key = 'driver_license'

  licence = null
  recentlyPaid = false

  state = {
    ready: !$app.hasRole('pilot')
  }

  async componentDidMount () {
    if ($app.hasRole('pilot')) {
      const { error } = await this.getLicenceDetails(true)
      this.setState({ ready: true })

      if (error) {
        toastr.warning('Warning', error)
        this.isUnreachable = true
      }
    }
  }

  hooks = {
    'post:read': data => {
      if (!data?.length) return

      const last = data.findLast(record => record.payment_status)?.updated_at
      if (!last) return

      this.recentlyPaid = moment().diff(last, 'months') < 10
    },
  }

  before_apply = async () => {
    if (this.isUnreachable) {
      return `
        Sorry, but we currently are unable to process driver's licences
        at this time. Please try again later.
      `
    }

    const m = moment()

    try {
      const { Expiry } = await this.getLicenceDetails()

      if (!this.isGeneralLicence) {
        return `
          Unfortunately, at this time we are only able to
          process General Driver's Licence
        `
      } else if (m.isBefore(Expiry, 'month')) {
        return `
          Your licence has not yet expired and is not expiring this month.
        `
      }
    } catch (err) {
      return `
        We were unable to fetch your driver's licence's details from
        the Road Traffic Department. Please try again later.
      `
    }

    if (this.recentlyPaid) {
      const { isConfirmed } = await Swal.fire({
        icon: 'warning',
        title: 'Confirm Resubmission',
        html: `
          We see that you've recently paid for a renewal;
          are you certain that you want to create a new application?
        `,
        showCancelButton: true,
        confirmButtonText: 'Resubmit',
      })

      return isConfirmed || null
    }

    return true
  }

  getLicenceDetails = async (refetch = false) => {
    try {
      if (refetch || !this.licence) {
        const url = '/drivers_licenses/fetch_rtd/' + $app.applicant.NIB
        const { data } = await $app.axios.get(url)
        const expiry_arr = data.Expiry.split(/[- ]/)
        const today = moment()
        const expiry_month = `${today.year()}-${expiry_arr[1]}-${expiry_arr[2]}`

        this.licence = data
        this.isGeneralLicence = data.Type == "General Driver's Licence"

        // Expiry month on license must be at least 1 or more months ahead of current year/month
        this.bringingUpToDate = today.isAfter(data.Expiry) && (today.diff(expiry_month, 'month') < -1)

        // Calculate fee total balance
        let today2 = new Date();
        const { base_fee } = this.props.service
        let exp_date = new Date(data.Expiry);
    
        const yearDiff = (start_date, end_date) => {
          let years = start_date.getFullYear() - end_date.getFullYear()
          if ((start_date.getMonth() < end_date.getMonth()) ||
            (start_date.getMonth() == end_date.getMonth() && start_date.getDate() < end_date.getDate())) {
              years -= 1
            }
          return years
        }
    
        // Old payment amount to account for the price change in DL renewal fees starting 2023
        const old_expiry_date = new Date(2023, exp_date.getMonth(), exp_date.getDate());
        let old_payment_amount = 0;
        let old_diff = 0;
        let diff = 0;
        if(exp_date.getFullYear() <= 2023) {
          old_diff = yearDiff(old_expiry_date, exp_date)
          if(old_diff > 0) { old_payment_amount = 20 * old_diff; }
    
          diff = yearDiff(today2, old_expiry_date)
        }
        else {
          diff = yearDiff(today2, exp_date);
        }

        if(diff > 0) {
          this.balance = (base_fee * (0 + diff)) + old_payment_amount;
          this.balance_1 = (base_fee * (1 + diff)) + old_payment_amount;
          this.balance_3 = (base_fee * (3 + diff)) + old_payment_amount;
          this.balance_5 = (base_fee * (5 + diff)) + old_payment_amount;
        } else {
          this.balance = (base_fee * 0) + old_payment_amount;
          this.balance_1 = (base_fee * 1 ) + old_payment_amount;
          this.balance_3 = (base_fee * 3) + old_payment_amount;
          this.balance_5 = (base_fee * 5) + old_payment_amount;
        }
      }

      return this.licence
    } catch (err) {
      console.error(err)
      return {
        error: 'Unable to retrieve licence details from the RTD database',
      }
    }
  }

  fee_adjustment = amount => ({
    input: 'range',
    inputValue: amount,
    inputAttributes: {
      min: 20,
      max: 200,
      step: 20,
    },
  })

  validate = async form => {
    const { TMN, error } = await this.getLicenceDetails()

    if (error) return error
    return TMN == form.license_number || STRINGS.ERRORS.NIB_DL_MISMATCH
  }

  // Check RTD API (TMN System) for applicant's last renewal
  // And add backpayment amount if applicable
  // fee = async ({ period }) => {
  //   const today = moment()
  //   const { Expiry } = await this.getLicenceDetails()
  //   const { base_fee } = this.props.service
  //   let total = 0
  //   let diff = 0

  //   const yearDiff = (m1, m2) => {
  //     const years = m1.year() - m2.year()

  //     if (m1.month() < m2.month()) return years - 1
  //     if (m1.month() == m2.month() && m1.day() < m2.day()) return years - 1
  //     return years
  //   }

  //   // Use end of month as the cutoff date since
  //   // licences are allowed to be renewed on any date within
  //   // applicant's birth month usually
  //   const expDate = moment(Expiry).endOf('month')
  //   const _2023 = expDate.clone().year(2023)

  //   // Licence fees were updated at the beginning of 2023
  //   // The old fee must be applied to the years in arrears prior
  //   if (expDate.year() <= 2023) {
  //     // 20 is past base fee
  //     let yearsBeforeChange = yearDiff(_2023, expDate)
  //     if (yearsBeforeChange > 0) total += (20 * yearsBeforeChange)

  //     diff = yearDiff(today, _2023)
  //   } else {
  //     diff = yearDiff(today, expDate)
  //   }

  //   return total + base_fee * (diff > 0 ? period : period + diff)
  // }

  fee = async ({period}) => {
    let today = new Date();
    const { Expiry } = await this.getLicenceDetails()
    const { base_fee } = this.props.service
    let exp_date = new Date(Expiry);

    const yearDiff = (start_date, end_date) => {
      let years = start_date.getFullYear() - end_date.getFullYear()
      if ((start_date.getMonth() < end_date.getMonth()) ||
        (start_date.getMonth() == end_date.getMonth() && start_date.getDate() < end_date.getDate())) {
          years -= 1
        }
      return years
    }

    // Old payment amount to account for the price change in DL renewal fees starting 2023
    const old_expiry_date = new Date(2023, exp_date.getMonth(), exp_date.getDate());
    let old_payment_amount = 0;
    let payment_amount = 0;
    let old_diff = 0;
    let diff = 0;
    if(exp_date.getFullYear() <= 2023) {
      old_diff = yearDiff(old_expiry_date, exp_date)
      if(old_diff > 0) { old_payment_amount = 20 * old_diff; }

      diff = yearDiff(today, old_expiry_date)
    }
    else {
      diff = yearDiff(today, exp_date);
    }
    if(diff > 0) {
      payment_amount = base_fee * (period + diff);
    }
    else {
      payment_amount = base_fee * period
    }
    payment_amount += old_payment_amount

    return payment_amount;
  }

  // Without Moment
  // const dl_calc = (expiry_date, period) => {
  //   let today = new Date();
  //   let exp_date = new Date(expiry_date);

  //   const yearDiff = (start_date, end_date) => {
  //     let years = start_date.getFullYear() - end_date.getFullYear()
  //     if ((start_date.getMonth() < end_date.getMonth()) ||
  //       (start_date.getMonth() == end_date.getMonth() && start_date.getDate() < end_date.getDate())) {
  //         years -= 1
  //       }
  //     return years
  //   }

  //   // Old payment amount to account for the price change in DL renewal fees starting 2023
  //   const old_expiry_date = new Date(2023, exp_date.getMonth(), exp_date.getDate());
  //   let old_payment_amount = 0;
  //   let payment_amount = 0;
  //   let old_diff = 0;
  //   let diff = 0;
  //   if(exp_date.getFullYear() <= 2023) {
  //     old_diff = yearDiff(old_expiry_date, exp_date)
  //     if(old_diff > 0) { old_payment_amount = 20 * old_diff; }

  //     diff = yearDiff(today, old_expiry_date)
  //   }
  //   else {
  //     diff = yearDiff(today, exp_date);
  //   }
  //   if(diff > 0) {
  //     payment_amount = 25 * (period + diff);
  //   }
  //   else {
  //     payment_amount = 25 * period
  //   }
  //   payment_amount += old_payment_amount

  //   return payment_amount;
  // }

  fields = () => [
    {
      name: 'license_number',
      label: "Driver's Licence #",
      use_profile: 'drivers_license_number',
    },
    {
      name: 'period',
      label: 'Licence Period',
      type: 'select',
      options: this.bringingUpToDate ? [
        { label: `I want to bring my licence up to date ($${this.balance})`, value: 0 },
        { label: `1 Year ($${this.balance_1})`, value: 1 },
        { label: `3 Years ($${this.balance_3})`, value: 3 },
        { label: `5 Years ($${this.balance_5})`, value: 5 },
      ] : [
        { label: `1 Year ($${this.balance_1})`, value: 1 },
        { label: `3 Years ($${this.balance_3})`, value: 3 },
        { label: `5 Years ($${this.balance_5})`, value: 5 },
      ],
      hint: this.bringingUpToDate
        ? `"By selecting "<b>Bring your license up to date</b>", you will be charged for the balance of those outstanding fees in arrears. 
          <br/>Once you have paid the outstanding fees, your license will be considered up to date and valid until your birth month.
          <br/><br/>If you choose a <b>license period of 1, 3, or 5 years</b> and have an outstanding balance in your account, 
          you will be charged for both the balance of the outstanding fees in arrears as well as the cost of the license itself. 
          <br/>Once both amounts are paid, your license will be renewed and considered valid for the selected period.`
        : `If you choose a <b>license period of 1, 3, or 5 years</b> and have an outstanding balance in your account, 
          you will be charged for both the balance of the outstanding fees in arrears as well as the cost of the license itself. 
          <br/>Once both amounts are paid, your license will be renewed and considered valid for the selected period.`,

      view: {
        value: val => val == 0 ? 'Late Payment' : val + ' Year(s)',
      },
    },
  ]

  render = () => this.state.ready
    ? (<Wizard {...SERVICE_PROPS(this)} />)
    : (<Loader loading message='Fetching RTD Info...' />)
}
