import React, { useReducer } from 'react'
import $ from 'jquery'
import Swal from 'sweetalert2'
import { ValidatorForm } from 'react-form-validator-core'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

import toastr from '@modules/toastr'
import InputField from '@shared/form-fields/input'
import ENV from '@constants/env'

const ensureSupportedCard = ({ cardType }) => {
  if (['Visa', 'Mastercard'].includes(cardType.displayName)) return
  return 'Please use either a Visa or Mastercard'
}

const CCForm = props => {
  const [state, setState] = useReducer(
    (state, action) => {
      const next = { ...state }
      const [field, value] = action

      if (field == 'card-name') {
        if (/[^a-z ]/i.test(value)) return next
      }

      next.values[field] = value
      next.valid[field] = !!value

      return next
    },
    {
      values: {
        'card-number': '',
        'card-amount': Number(props.fee).toFixed(2),
        'card-name': '',
        'card-cvv': '',
        'card-exp': '',
      },
      valid: {
        'card-number': false,
        'card-name': false,
        'card-cvv': false,
        'card-exp': false,
      },
    }
  )

  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    cardNumberValidator: ensureSupportedCard,
  })

  const value = field => props[field] || state.values[field]

  const submit = async () => {
    if (meta.error || Object.values(state.valid).includes(false)) {
      console.log($('.sc-ifAKCX').length)
      !state.valid['card-number'] &&
        toastr.error('Error', 'Card number is invalid')
      !state.valid['card-exp'] &&
        toastr.error('Error', 'Card Expiry is invalid')
      !state.valid['card-cvv'] && toastr.error('Error', 'Card CVV is invalid')

      return
    }

    const { isConfirmed } = await Swal.fire({
      icon: 'question',
      title: 'Are you sure you’d like to make this transaction?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      footer: `
        <span class='text-sm text-gray-700'>
          ${ENV.TRANSACTION_REQUEST}
        </span>
      `,
    })

    if (isConfirmed) props.submit(state.values)
  }

  return (
    <ValidatorForm id={props.name} onSubmit={submit}>
      <div className='form-group form-show-validation row'>
        <label
          htmlFor='name'
          className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
        >
          Card holder name <span className='required-label'></span>
        </label>
        <div className='col-lg-7 col-md-6 col-sm-6'>
          <InputField
            onChange={ev => setState(['card-name', ev.target.value])}
            name='card-name'
            icon={null}
            value={value('card-name')}
            validators={['required']}
            errorMessages={['This field is required']}
            className='form-control'
            placeholder='Card Holder Name'
          />
        </div>
      </div>

      <div className='form-group form-show-validation row'>
        <label
          htmlFor='card-amount'
          className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
        >
          Amount
        </label>
        <div className='col-lg-7 col-md-6 col-sm-6'>
          <InputField
            name='card-amount'
            icon={null}
            value={value('card-amount')}
            className='form-control'
            disabled
          />
        </div>
      </div>

      <div className='form-group form-show-validation row'>
        <label
          htmlFor='name'
          className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
        >
          Card <span className='required-label'></span>
        </label>
        <div className='col cc-input'>
          <PaymentInputsWrapper {...wrapperProps}>
            <svg {...getCardImageProps({ images })} />
            <input
              {...getCardNumberProps({
                onChange: ev => setState(['card-number', ev.target.value]),
              })}
              value={value('card-number')}
            />
            <input
              {...getExpiryDateProps({
                onChange: ev => setState(['card-exp', ev.target.value]),
              })}
              value={value('card-exp')}
            />
            <input
              {...getCVCProps({
                onChange: ev => setState(['card-cvv', ev.target.value]),
              })}
              value={value('card-cvv')}
            />
          </PaymentInputsWrapper>
          <div className='flex flex-row mt-2 space-x-3'>
            <span className='text-xs text-gray-600 italic'>We Accept:</span>
            <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
              {images.visa}
            </svg>
            <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
              {images.mastercard}
            </svg>
          </div>
        </div>
      </div>
    </ValidatorForm>
  )
}

export default CCForm
