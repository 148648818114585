import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import passportPhotoExample from '@helpers/passport-photo-example'
export default class JunkanooEmployment extends React.Component {
  resource = '/junkanoo_employments'
  form_key = '/junkanoo_employment'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ":: Personal Preferences",
    {
      name: 'preferred_parade',
      label: 'Please choose your preferred parade(s) to work',
      multi: true,
      options: ['Boxing day parade', `New year's day parade`, 'Junior Junkanoo parade'],
    },
    {
      name: 'preferred_area',
      label: 'Please choose your prefered area to work',
      options: ['Entry Point', 'Bleacher Marshall', 'Facilities', 'Protocol', 'Supplies'],
    },
    "::Personal Info",
    {
      name: 'First Name',
    },
    {
      name: 'Last Name',
    },
    {
      name: 'phone_number',
      label: 'Phone No',
      type: 'phone',
    },
    ":: Banking Info",
    {
      name: 'Bank Name',
    },
    {
      name: 'Bank Branch',
    },
    {
      name: 'Bank Account Number',
      type: 'integer',
    },

    ":: Uploads",
    {
      key: 'images.0',
      name: 'banking_info_upload',
      label: 'Bank Statement',
      type: 'file:pdf',
    },
    {
      key: 'image.1',
      name: 'passport_upload',
      label: 'Passport',
      type: 'file:image',
      hint: passportPhotoExample,
    },
    {
      key: 'image.2',
      name: 'nib_card_upload',
      label: 'NIB Card',
      type: 'file:pdf',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
