import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class FoodNoveltyItemVendor extends React.Component {
  resource = '/food_novelty_vendors'
  form_key = 'food_novelty_vendor'

  postpaid = true
  defaults = {}

  business_only     = true
  skip_location     = true
  hide_buttons      = false
  custom_acceptance = true

  custom_actions = []

  validate = null

  foremost = null

  fields = (form, viewing = false) => {
    const list = []
    
    if (!viewing) {
      list.push({
        heading: `
          <strong>
            Please be advised that this application does not guarantee a
            booth/space at any Junkanoo Parade
          </strong>
        `
      })
    }
    
    list.push(
      ...BUSINESS_FIELDS(form),
      '::Contact Details',
      {
        name: 'i_am_contact',
        type: 'checkbox',
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      },
    )

    if (!form.i_am_contact) {
      list.push(
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_phone_numb',
          label: 'Primary Phone #',
          type: 'phone'
        },
        {
          name: 'contact_work_numb',
          label: 'Work Phone #',
          type: 'phone',
          required: false
        },
        {
          name: 'contact_home_numb',
          label: 'Home Phone #',
          required: false
        },
        {
          name: 'contact_po_box',
          label: 'P. O. Box',
          required: false
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
        )
      }
      
      list.push(
        '::SPACER',
        {
          name: 'vendor_type',
          options: [
            { label: 'Food', value: 'food' },
            { label: 'Novelty Items', value: 'novelty' }
        ],
      },
      {
        name: 'stand_position',
        options: [
          { label: 'Booth', value: 'booth' },
          { label: 'Space', value: 'space' }
        ],
      },
      {
        name: 'preferred_parade_list',
        label: 'Preferred Parade(s)',
        multi: true,
        options: [
          'Boxing Day',
          'New Years Day',
          'Junior Junkanoo',
        ],
      },
      {
        name: 'products_list',
        columns: ['product'],
        format: v => v.product
      },
      
      '::Uploads',
      {
        key: 'image.0',
        name: 'food_handler_cert_upload',
        label: `Food Handler's Certificate`,
        type: 'file:all',
      },
      {
        key: 'image.1',
        name: 'passport_drivers_license_upload',
        label: `Passport or Driver's License`,
        type: 'file:all',
      },
      {
        key: 'image.2',
        name: 'nib_card_upload',
        label: 'NIB Card',
        type: 'file:all',
      }
    )

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
