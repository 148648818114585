import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import DateTimePicker from 'react-datetime-picker'
import "react-datetime-picker/dist/DateTimePicker.css"


class DateTimeFieldV2 extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () => this.state.isValid ? null : (
    <div style={this.errorStyles}>
      {this.getErrorMessage()}
    </div>
  )

  render () {
    const { errorMessages, validators, requiredError, validatorListener, withRequiredValidator, ...rest } = this.props
    if (!this.state.isValid) rest.className += ' input_error'

    return (
      <div>
        <DateTimePicker
          amPmAriaLabel="Select AM/PM"
          calendarAriaLabel="Toggle calendar"
          clearAriaLabel="Clear value"
          dayAriaLabel="Day"
          hourAriaLabel="Hour"
          maxDetail="second"
          minuteAriaLabel="Minute"
          monthAriaLabel="Month"
          nativeInputAriaLabel="Date and time"
          secondAriaLabel="Second"
          yearAriaLabel="Year"
          {...rest}
        />
        {this.errorText()}
      </div>
    )
  }
}

export default DateTimeFieldV2
