import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class JunkanooRegistration extends React.Component {
  resource = '/junkanoo_registrations'
  form_key = 'junkanoo_registration'
  free     = true

  skip_location  = true
  custom_actions = []

  validate = null

  foremost = null

  fields = form => {
    const list = [
      {
        name: 'organization_name',
      },
      {
        name: 'category',
        options: [
          { label: 'Adult Group (Divison A)', value: 'adult group division a' },
          { label: 'Adult Group (Divison B)', value: 'adult group division b' },
          { label: 'Adult Group (Divison D)', value: 'adult individual division d' },
          { label: 'Fun Group (Division F)', value: 'fun group division f' }
        ],
      },
      {
        name: 'group_number',
        label: 'Number in group',
        required: false,
      },
      {
        name: 'sponsor',
      },
      '::Contact Details',
      {
        name: 'i_am_contact',
        type: 'checkbox',
        required: false,
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      },
    ]

    if (!form.i_am_contact) {
      list.push(
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_phone_numb',
          label: 'Primary Phone #',
          type: 'phone'
        },
        {
          name: 'contact_work_numb',
          label: 'Work Phone #',
          type: 'phone',
          required: false
        },
        {
          name: 'contact_home_numb',
          label: 'Home Phone #',
          type: 'phone',
          required: false
        },
        {
          name: 'contact_po_box',
          label: 'P. O. Box',
          required: false
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
      )
    }

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
