import React from 'react'

import Wizard from '@modules/form-wizard'
import islands from '@constants/islands'
import { common, hull_types } from './duty-free'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class DutyFreeBonefishing extends React.Component {
  resource = '/duty_free_bone_fishings'
  form_key = 'duty_free_bone_fishing'
  free = true
  
  business_only = true
  skip_location = true
  defaults = {
    island: $app.current_user.island,
  }

  hide_buttons = {
    approve: true,
    status: true,
  }

  metadata = {
    denied: { license_numb: '####' }
  }

  fields = values => [
    ...BUSINESS_FIELDS(values),
    '::Applicant Contact Information',
    {
      name: 'phone',
      is: 'phone',
      use_profile: 'additional_phone_number',
    },
    {
      name: 'po_box',
      label: 'P. O. Box',
      required: false,
    },
    {
      name: 'island',
      options: islands,
    },
    {
      name: 'lodge_name',
      required: false,
      hint: 'If Applicable',
    },

    {
      heading: `
        <div>
          <h5 class='mb-0'>Captain Details</h5>
          <span class='text-sm'>
            Please note that your captain must be a Bahamian
            to be eligible for the permit.
            </span>
        </div>
      `,
      view: {
        heading: 'Captain Details'
      }
    },
    {
      name: 'captain',
      type: 'select:bool',
      label: 'Are you the intended captain?',
      view: {
        hide: !values.captain,
        label: 'Is Own Intended Captain',
        value: v => v ? 'Yes' : 'No',
      },
    },
    {
      name: 'captain_license_numb',
      label: 'Captain Licence #'
    },
    {
      name: 'captain_first_name',
      label: 'First Name',
      hide: values.captain !== false,
    },
    {
      name: 'captain_last_name',
      label: 'Last Name',
      hide: values.captain !== false,
    },
    {
      name: 'captain_license_upload',
      key: 'image.2',
      type: 'file:image',
      label: 'Copy of Captain Licence',
    },
    // {
    //   name: 'captain_nationality',
    //   label: 'Nationality',
    //   hide: values.captain !== false,
    //   options: countries,
    // },

    {
      heading: `
        <div>
          <h5 class='mb-0'>Vessel Details</h5>
          <span class='text-sm'>All length measurements should be in feet.</span>
        </div>
      `,
      view: {
        heading: 'Vessel Details'
      }
    },
    {
      name: 'vessel_length',
      label: 'Overall Length',
      is: 'float',
      view: {
        value: v => v + ' ft.'
      }
    },
    {
      name: 'vessel_hull_type',
      label: 'Hull Type',
      options: hull_types,
    },
    {
      name: 'vessel_engine',
      label: 'Make of Engine(s)',
    },
    {
      name: 'vessel_hp',
      label: 'Total Horsepower of Engine(s)',
      view: {
        value: v => v + 'hp'
      }
    },
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Certificate of Registration',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...common(this)} />
}
