import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { VectorMap } from 'react-jvectormap'
import { withRouter, Link } from 'react-router-dom'

import { extractKeys } from '@helpers/objects'
import { getUser } from '@actions/users'
import { setAgencyAlert } from '@actions/agencies'
import {
  getTotalSpend,
  getTopRequest,
  getCounts,
  recentHistory,
} from '@actions/dashboard'

import {
  staticalCircle,
  incomeChart,
  userStaticChart,
  providerChart,
} from '@assets/js/dashboard.js'
import Banner from '@images/main-banner.jpg'
import '@styles/dashboard.css'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    const start = props.location.search.match(/tab=(.*)/)?.pop()
    this.state = {
      open: false,
      user: sessionStorage.user && JSON.parse(sessionStorage.user),
      mode: start || 'agencies',
    }
  }

  componentDidMount() {
    this.props.getUser()
    this.props.getCounts()
    this.props.getTotalSpend()
    this.props.getTopRequest()
    this.props.recentHistory()

    staticalCircle()
    userStaticChart()
    providerChart()
  }

  componentWillReceiveProps({ get_counts, last_7_spend }) {
    get_counts && staticalCircle(get_counts)
    last_7_spend && incomeChart(last_7_spend)
  }

  setActiveTab = mode => () => this.setState({ mode })
  showActiveTab = (mode, cls) =>
    cls + (this.state.mode == mode ? ' active show' : '')
  lastAlertCheck = moment()

  handleClick = () => {}

  renderMap = () => {
    const props = {
      map: 'world_mill',
      backgroundColor: 'transparent', //change it to blue !!!
      zoomOnScroll: false,
      onRegionClick: this.handleClick, //gets the country code
      containerClassName: 'map',
      regionsSelectable: true,

      containerStyle: {
        width: '100%',
        height: '520px',
      },

      regionStyle: {
        initial: {
          'fill': '#e4e4e4',
          'fill-opacity': 0.9,
          'stroke': 'none',
          'stroke-width': 0,
          'stroke-opacity': 0,
        },
        hover: {
          'fill-opacity': 0.8,
          'cursor': 'pointer',
        },
        selected: {
          fill: '#2938bc', //what colour clicked country will be
        },
        selectedHover: {},
      },

      series: {
        regions: [
          {
            values: [], //this is your data
            scale: ['#146804', '#ff0000'], //your color game's here
            normalizeFunction: 'polynomial',
          },
        ],
      },
    }

    return <VectorMap {...props} />
  }

  checkAlerts = name => async () => {
    const { alerts } = this.props
    this.props.setAgencyAlert(
      alerts?.find(a => a.status && a.agency == name) || {}
    )
  }

  getStatus = s => (s === null ? 'Pending' : s ? 'Approved' : 'Denied')
  // goToAgencyPage = code => () => this.props.history.push('/agency/' + code)
  goToAgencyPage = code => () => {}

  list = (data = [], fn) => (data && data.length ? data.map(fn) : null)

  historyDetails = (data = {}, history = {}) =>
    Object.keys(data).map(key => {
      const { getStatus } = this
      const title = str => str.replace(/_/g, ' ').capitalize()

      switch (key) {
        case 'created_at':
          return null
        case 'status':
        case 'application_status':
        case 'application_decision':
          return (
            <span key={key}>
              {title(key)}: {getStatus(data[key])}{' '}
            </span>
          )
        case 'updated_at':
        case 'slot':
          return (
            <span key={key}>
              {title(key)}:{' '}
              {moment(history.updated_at || data[key]).format(
                'DD-MM-YYYY H:mm A'
              )}{' '}
            </span>
          )
        case 'amount':
          return (
            <span key={key}>Amount: ${Number(data.amount).toFixed(2)} </span>
          )
        default:
          return (
            <span key={key}>
              {title(key)}: {data[key]}{' '}
            </span>
          )
      }
    })

  Cards = {
    Services: $app.getServices('*').reduce((list, service, index) => {
      if (!service?.path) return list

      list.push(
        <div className='search-result col-md-3' key={index}>
          <div className='card'>
            <Link
              to={service.path}
              onClick={this.checkAlerts(service.agency.id)}
            >
              <span className='text-muted text-capitalize'>
                {service.agency.name}
              </span>
              <strong className='card-title text-capitalize fw-bold'>
                {service.title}
              </strong>
            </Link>
          </div>
        </div>
      )

      return list
    }, []),

    Agencies: $app.agencies.map(agency => (
      <div
        key={agency.id}
        className='sm:w-1/2 lg:w-1/3 2xl:w-1/4 4xl:w-1/5 cursor-pointer p-2'
        onClick={this.goToAgencyPage(agency.code)}
      >
        <div className='card'>
          <div className='p-2 text-center'>
            <img
              className='card-img-top rounded'
              src={agency.thumbnail}
              alt={agency.name + ' Thumbnail'}
            />
          </div>
          <div className='card-body pt-2'>
            <h3 className='card-title fw-bold'>{agency.name.capitalize()}</h3>
            <p className='card-text'>{agency.summary}</p>
          </div>
        </div>
      </div>
    )),
  }

  render() {
    const { state, props, list, getStatus, historyDetails, Cards } = this
    const { in_person_status } = this.props

    const history = list(props.histories, (item, i) => (
      <li key={i} className='feed-item feed-item-secondary'>
        <time className='date' dateTime={item.updated_at}>
          {moment(item.updated_at).format('MMM DD').toUpperCase()}
        </time>
        <span className='text'>
          {item.entity.spaceBeforeCap()}
          <br />
          <a href='#'>{historyDetails(item.new_data, item)}</a>
        </span>
        <br />
        <span className='text-gray'>
          <a href='#'>{historyDetails(item.old_data)}</a>
        </span>
      </li>
    ))

    const topRequests = list(
      props.top_requests,
      ({ status, amount, created_at, class_name }, i) => (
        <React.Fragment key={i}>
          <div className='d-flex'>
            <div className='flex-1 ml-3 pt-1'>
              <h6 className='text-uppercase fw-bold mb-1'>
                {class_name.spaceBeforeCap()}
                <span className='text-info pl-3'>{getStatus(status)}</span>
              </h6>
              <span className='text-muted'>Amount: ${amount}</span>
            </div>
            <div className='float-right pt-1'>
              <small className='text-muted'>
                {moment(created_at).format('DD-MM-YYYY')}
              </small>
            </div>
          </div>
          <div className='separator-dashed'></div>
        </React.Fragment>
      )
    )

    const lastStar = in_person_status ? (
      <a href='#' className=' mr-2'>
        <i className='fa fa-star'></i>
      </a>
    ) : (
      <a href='#' className=' mr-2'>
        <i className='far fa-star'></i>
      </a>
    )

    const banner =
      state.mode == 'agencies' ? (
        <div
          className='page-inner no-v-padding active main-banner'
          style={{ margin: '0 0 -3rem' }}
        >
          <img
            src={Banner}
            alt='My Gateway Banner'
            style={{ display: 'block', margin: '2rem auto 0' }}
          />
        </div>
      ) : null

    return (
      <React.Fragment>
        <div className='page-navs bg-white'>
          <div className='nav-scroller'>
            <div
              className='nav nav-tabs nav-line nav-color-primary d-flex align-items-center justify-contents-center w-100'
              role='tablist'
            >
              <a
                onClick={this.setActiveTab('agencies')}
                className={
                  'nav-link' + (state.mode == 'agencies' ? ' active show' : '')
                }
                data-toggle='tab'
                href='#agencies'
              >
                Agencies
              </a>
              <a
                onClick={this.setActiveTab('stats')}
                className={
                  'nav-link' + (state.mode == 'stats' ? ' active show' : '')
                }
                data-toggle='tab'
                href='#statistics'
              >
                My Statistics
              </a>
              <a
                onClick={this.setActiveTab('services')}
                className={
                  'nav-link mr-5' +
                  (state.mode == 'services' ? ' active show' : '')
                }
                data-toggle='tab'
                href='#services'
              >
                Services
              </a>
            </div>
          </div>
        </div>
        <div className='dashboard-tabs'>
          {banner}
          <div className={this.showActiveTab('agencies', 'page-inner')}>
            <div className='row row-projects'>{Cards.Agencies}</div>
          </div>
          <div
            className={this.showActiveTab('stats', 'content dashboard-section')}
          >
            <div className='panel-header bg-primary-gradient'>
              <div className='page-inner py-5'>
                <div className='d-flex align-items-left align-items-md-center flex-column flex-md-row'>
                  <div>
                    <h2 className='text-white pb-2 fw-bold'>
                      Bahamas Citizen Portal
                    </h2>
                    <h5 className='text-white op-7 mb-2'>
                      Making Government Work for You
                    </h5>
                  </div>
                  <div className='ml-md-auto py-2 py-md-0'>
                    <a href='#' className=' mr-2'>
                      <i className='fa fa-star'></i>
                    </a>
                    <a href='#' className=' mr-2'>
                      <i className='fa fa-star'></i>
                    </a>
                    {lastStar}
                  </div>
                </div>
              </div>
            </div>
            <div className='page-inner mt--5'>
              <div className='row mt--2'>
                <div className='col-md-6'>
                  <div className='card full-height'>
                    <div className='card-body'>
                      <div className='card-title'>Overall statistics</div>
                      <div className='card-category'>
                        Daily information about statistics in system
                      </div>
                      <div className='d-flex flex-wrap justify-content-around pb-2 pt-4'>
                        <div className='px-2 pb-2 pb-md-0 text-center'>
                          <div id='circles-1'></div>
                          <h6 className='fw-bold mt-3 mb-0'>Submissions</h6>
                        </div>
                        <div className='px-2 pb-2 pb-md-0 text-center'>
                          <div id='circles-2'></div>
                          <h6 className='fw-bold mt-3 mb-0'>In Progress</h6>
                        </div>
                        <div className='px-2 pb-2 pb-md-0 text-center'>
                          <div id='circles-3'></div>
                          <h6 className='fw-bold mt-3 mb-0'>Completed</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='card full-height'>
                    <div className='card-body'>
                      <div className='card-title'>Total Spend Statistics</div>
                      <div className='row py-3'>
                        <div className='col-md-4 d-flex flex-column justify-content-around'>
                          <div>
                            <h6 className='fw-bold text-uppercase text-danger op-8'>
                              Total Spend
                            </h6>
                            <h3 className='fw-bold'>
                              ${this.props.total_spend || '0.00'}
                            </h3>
                          </div>
                        </div>
                        <div className='col-md-8'>
                          <div id='chart-container'>
                            <canvas id='totalIncomeChart'></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='card full-height'>
                    <div className='card-header'>
                      <div className='card-title'>Change History</div>
                    </div>
                    <div className='card-body'>
                      <ol className='activity-feed'>{history}</ol>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='card full-height'>
                    <div className='card-header'>
                      <div className='card-head-row'>
                        <div className='card-title'>Most Recent Requests</div>
                        <div className='card-tools'></div>
                      </div>
                    </div>
                    <div className='card-body'>{topRequests}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={this.showActiveTab(
              'services',
              'content dashboard-section'
            )}
          >
            <div className='row row-projects page-inner'>{Cards.Services}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      ...extractKeys(
        state.users,
        'error',
        'in_person_status',
        'success_message'
      ),
      ...extractKeys(
        state.dashboard,
        'get_counts',
        'histories',
        'top_requests',
        'total_spend',
        'last_7_spend'
      ),
      ...extractKeys(state.agencies, 'agencies', 'alerts'),
    }),
    {
      getTotalSpend,
      getTopRequest,
      getCounts,
      recentHistory,
      getUser,
      setAgencyAlert,
    }
  )(Dashboard)
)
