import React from 'react'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import { common } from './duty-free'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import islands from '@constants/islands'
import ENV from '@constants/env'

export default class DutyFreeCommercialFishing extends React.Component {
  resource = '/duty_free_commercial_fishings'
  form_key = 'duty_free_commercial_fishing'
  free = true

  defaults = {}
  business_only = true
  skip_location = true

  hide_buttons = {
    approve: true,
    status: true,
  }

  metadata = {
    denied: { license_numb: '####' }
  }

  canned = {
    'TEST CANNED': `
      This is a test canned message; it can be whatever you like.
    `
  }

  approve = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          license_numb: record.license_numb,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application successfully approved')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to approve application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - New Compressor Permit',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Congratulations! Your application for a new Compressor Permit has been approved.
            <br/>
            Your permit number is as follows: ${
              record.license_numb
            }. A downloadable PDF document will be sent to your email shortly.
            <br/>
            Thank you for using the MyGateway Portal.
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
      try {
        const { data } = await $app.axios.get('/services/all_paid_forms', {
          params: { service_type: this.service_type || props.service.type },
        })
        const updated_record = data.paid_forms.filter(
          d => d.id === record?.id
        )[0]
        await $app.axios.post('/emails', {
          email: record.user.email,
          subject: `MyGateqway Portal Update -  New Compressor Permit`,
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
           Your application for a Compressor Permit has been approved. As promised, below is a link to your downloadable PDF Permit.
            <br/><br />
            <p><b>Links</b></p>
            <ul>
            <li><a href='${`${ENV.WEB_SERVICE_URL}${updated_record?.image[0]}`}' target='_blank'>Compressor Permit</a></li>
            </ul>
            <br/><br/>
            Thank you for using MyGateway Portal.
          `,
        })
      } catch (error) {
        toastr.error('Error', 'Unable to send permit email')
        console.error(error)
      }
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'phone',
      is: 'phone',
      use_profile: 'additional_phone_number',
    },
    {
      name: 'po_box',
      label: 'P. O. Box',
      required: false,
    },
    {
      name: 'nationality',
      use_profile: 'country_of_citizenship',
      disabled: true,
      view: {
        value: v => v?.initialCaps()
      }
    },
    {
      name: 'company_name',
      label: 'Name of Company',
    },
    '::Captain Detais',
    {
      name: 'captain',
      type: 'select:bool',
      label: 'Are you the intended captain?',
      view: {
        hide: !form.captain,
        label: 'Is Own Intended Captain',
        value: v => v ? 'Yes' : 'No',
      },
    },
    {
      name: 'captain_license_numb',
      label: 'Captain Licence #'
    },
    {
      name: 'captain_first_name',
      label: 'First Name',
      hide: form.captain !== false,
    },
    {
      name: 'captain_last_name',
      label: 'Last Name',
      hide: form.captain !== false,
    },
    {
      name: 'captain_license_upload',
      key: 'image.8',
      type: 'file:image',
      label: 'Copy of Captain Licence',
    },
    // {
    //   name: 'captain_nationality',
    //   label: 'Nationality',
    //   hide: form.captain !== false,
    //   options: countries,
    // },

    '::Equipment Details',
    {
      type: 'textarea',
      name: 'vessel_fixed_gear',
      label: 'Fixed Fishing Gear',
      hint: 'Winches, reels, haulers, etc.',
    },
    {
      type: 'textarea',
      name: 'vessel_expandable_gear',
      label: 'Expandable Fishing Gear',
      hint: 'Winches, reels, haulers, etc.',
    },
    {
      name: 'vessel_electric_apparatus',
      required: false,
      label: 'Electric Apparatus',
      type: 'textarea',
      hint: 'Electric reels/winches, other electronics, etc.',
    },

    '::Registration Details',
    {
      name: 'vessel_registry_port',
      label: 'Port of Registry',
      // options: ports,
      required: false,
      hint: 'Omit if not available',
    },
    {
      name: 'vessel_registry_number',
      label: 'Port Official Number',
      required: false,
      hint: 'Omit if not available',
    },
    {
      name: 'vessel_home_port',
      label: 'Intended Home Port',
      // options: ports,
    },
    {
      name: 'fishing_areas',
      label: 'Main Fishing Areas',
      options: islands,
      multi: true,
      hint: 'Select all that apply'
    },
    {
      name: 'vessel_value',
      is: 'currency',
      label: 'Value of Equipment',
    },
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Certificate of Registration',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...common(this)} />
}
