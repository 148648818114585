import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelArtsFestivalEntry extends React.Component {
  resource = '/clement_national_art_festivals'
  form_key = 'clement_national_art_festival'
  defaults = {}

  fee           = form => {
    // Payment per entry
    // $5
    // $10
    // $20
    // Additional $5 or $10 for late fee

    // TODO: Calculate This
    // I don't know when the late fee is or how to go about
    // figuring out the classification of who the form is for
    // to charge them properly

    return 5
  }

  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'entry_list',
      label: 'Entry',
      multi: true,
      options: [
        'Drama',
        'Music',
        'Dance',
        'Art & Craft',
        'Community'
      ]
    },
    {
      name: 'class_numb',
      label: 'Class No.',
    },
    {
      name: 'class_title',
      label: 'Title of Class',
    },
    {
      name: 'instruments_list',
      label: 'Instruments',
      required: false,
      hint: 'Applicable for instrument class only',
      columns: ['instrument'],
      format: v => v.instrument,
    },

    '::Competitor Details',
    {
      name: 'i_am_competitor',
      type: 'select:bool',
      label: 'I am applying for myself to compete',
    },
    {
      name: 'competitor_first_name',
      label: 'First Name',
      hide: form.i_am_competitor,
    },
    {
      name: 'competitor_middle_name',
      label: 'Middle Name',
      hide: form.i_am_competitor,
      required: false
    },
    {
      name: 'competitor_last_name',
      label: 'Last Name',
      hide: form.i_am_competitor,
    },
    {
      name: 'school_name',
      label: 'School Name',
    },
    {
      name: 'competitor_address',
      label: 'Address',
      hide: form.i_am_competitor,
    },
    {
      name: 'competitor_mobile_numb',
      type: 'phone',
      label: 'Mobile Phone #',
      hide: form.i_am_competitor,
    },
    {
      name: 'competitor_phone_numb',
      type: 'phone',
      label: 'Home Phone #',
      hide: form.i_am_competitor,
      required: false,
    },
    {
      name: 'competitor_email',
      type: 'email',
      label: 'Email',
      hide: form.i_am_competitor,
    },
    {
      name: 'employed_position',
      label: 'Position Employed As',
      datalist: [
        'Director of Choir',
        'Producer',
        'Choreographer'
      ]
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
