import $ from 'jquery'
import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import Alert from '@modules/alert'
import OTPPreferences from '@modules/otp-preferences'
import useWindowDimensions from '@hooks/dimensions'

import { extractKeys } from '@helpers/objects'
import { setAgencyAlert } from '@actions/agencies'

import '@styles/sidebar.css'

const shouldHide = (allowed, admin) => {
  if (admin && $app.hasRole('pilot')) return true
  if (!allowed?.length) return false

  return !$app.hasRole('pilot') && !$app.hasAnyRole(...allowed)
}

function getAgency() {
  if ($app.hasRole('road_traffic')) {
    return 'Road Traffic Department'
  } else if ($app.hasRole('moea')) {
    return 'Min. Of Economic Affairs'
  } else if ($app.hasRole('moa')) {
    return 'Dept. of Agriculture'
  } else if ($app.hasRole('moa_ministry')) {
    return 'Ministry of Agriculture'
  }

  return initialCaps($app.roles[0])
}

function getRole() {
  
  // General positional roles
  if ($app.hasRole('minister')) {
    return 'Minister'
  } else if ($app.hasRole('permanent_secretary')) {
    return 'Secretary'
  } else if ($app.hasRole('deputy_permanent_secretary')) {
    return 'Dep. Permanent Secretary'
  } else if ($app.hasRole('under_secretary')) {
    return 'Under Secretary'
  } else if ($app.hasRole('director')) {
    return 'Director'
  } else if ($app.hasRole('moea_minister')) {
    return 'Minister'
  } else if ($app.hasRole('officer')) {
    return 'Officer'
  } else if ($app.hasRole('senior_officer')) {
    return 'Sr. Officer'
  } else if ($app.hasRole('sib_officer')) {
    return 'SIB Officer'
  } else if ($app.hasRole('certificate_registrar')) {
    return 'Certificate Registrar'
  }

  // MOEA
  if ($app.hasRole('moea_secretary')) {
    return 'Permanent Secretary'
  } else if ($app.hasRole('moea_director')) {
    return 'Director'
  } else if ($app.hasRole('moea_processing')) {
    return 'FAS'
  }
  
  // RTD
  if ($app.hasRole('road_traffic')) {
    return 'Customer Service Rep.'
  }

  return 'Officer'
}

const SiteLinks = props => {
  const { width } = useWindowDimensions()
  const [groups, setGroups] = useState({})

  const user = useMemo(
    () => ({
      email: $app.current_user.email,
      agency: $app.hasRole('pilot') ? null : getAgency(),
      role: $app.hasRole('pilot') ? 'Citizen' : getRole(),
      isSupport: $app.hasRole('support'),
    }),
    [$app.token]
  )

  const { pathname } = props.location
  const alert = props.current_alert

  useEffect(() => {
    if (props.toggled) {
      $('.collapse-dt').removeClass('show')
      $('.caret').removeClass('ct-180').addClass('ct-0')
    }
  }, [props.toggled])

  const toggleGroup = name => () =>
    setGroups({
      ...groups,
      [name]: !groups[name] || undefined,
    })

  const closeOnMobile = () => {
    if (props.toggled && width < 480) props.togglePanel()
  }

  const SingleLink = ({ path, icon, label, allow, admin, onClick }) => {
    if (shouldHide(allow, admin)) return null

    const iconCls = icon && /^fa(r|s)\s/.test(icon) ? icon : `fas fa-${icon}`
    const name = label || initialCaps(path.slice(path.lastIndexOf('/') + 1))

    return (
      <Link
        to={path}
        data-active={matches(path)}
        onClick={ev => {
          closeOnMobile()
          onClick?.(ev)
        }}
      >
        {icon ? <i className={iconCls}></i> : null}
        <span>{name}</span>
      </Link>
    )
  }

  const checkAlerts = (name, cb) => () => {
    props.setAgencyAlert(
      props.alerts?.find(a => a.status && a.agency == name) || {}
    )

    if (typeof cb == 'function') cb()
  }

  const clearAlert = () => props.setAgencyAlert({})

  const serviceLinks = useMemo(() => {
    return $app.agencyCodes.map((code, i) => {
      const agency = $app.getAgency(code)
      const services = $app.getServices(code)

      if (!services) return null

      const { short, name, icon } = agency

      const iconCls = /^fa(r|s)\s/.test(icon) ? icon : `fas fa-${icon}`
      const withinGroup = services.map(s => s.path).some(matches) || undefined
      const items = services.map((s, i) => {
        if (s.allow && $app.hasRole(s.allow)) return null
        if (s.subheading) return <span key={i}>{s.subheading}</span>

        return (
          <Link
            to={s.path}
            key={i}
            data-active={matches(s.path, true)}
            onClick={checkAlerts(agency.id, closeOnMobile)}
          >
            {s.title}
          </Link>
        )
      }).filter(l => l)

      if (!items.length) return null

      return (
        <section key={i} className='site-nav__group' data-open={groups[name]}>
          <div
            className='site-nav__group__toggle'
            data-active={withinGroup}
            onClick={toggleGroup(name)}
          >
            <i className={iconCls}></i>
            <span>{short || name}</span>
            <span className='caret'></span>
          </div>
          <div className='site-nav__group__links'>{items}</div>
        </section>
      )
    })
  }, [groups, pathname])

  if (props.hide) return null
  if (/^\/(otp|404)/.test(pathname)) return null

  return (
    <React.Fragment>
      <section className='site-nav'>
        <div className='site-nav__wrapper'>
          <div className='site-nav__current-user'>
            <span>{user.email}</span>
            <span className='user-level' style={{ margin: 0 }}>
              {user.agency}
            </span>
            <span className='user-level' style={{ margin: 0 }}>
              {user.role}
            </span>
          </div>

          <nav className='site-nav__links'>
            {user.role == 'Citizen' ? (
              <h4 className='site-nav__subheading'>About Me</h4>
            ) : null}

            <SingleLink path='/dashboard' icon='home' allow={[null]} />

            <SingleLink
              path='/stats'
              icon='chart-line'
              allow={['gamification']}
              admin
            />

            <SingleLink path='/profile' icon='user' allow={[null]} />
            <SingleLink path='/transactions' icon='list-alt' allow={[null]} />

            <SingleLink
              path='/citizens'
              icon='user'
              label='Find User'
              allow={[
                'passport',
                'road_traffic',
                'registrar_general',
                'police',
                'post_office',
                'support',
              ]}
              admin
            />

            <SingleLink
              path='/nib-search'
              icon='search-plus'
              label='NIB Search'
              allow={[
                'passport',
                'road_traffic',
                'registrar_general',
                'police',
                'post_office',
                'support',
              ]}
              admin
            />

            {/* <SingleLink
              path='/appointments'
              icon='calendar'
              label='Appointments'
              badge={false}
            /> */}

            {/* <SingleLink
              path='/services/appointments'
              icon='calendar-plus'
              label='Make Appointment'
              badge={false}
              allow={[null]}
            /> */}

            {/* <SingleLink
              path='/services/appointments/list'
              icon='calendar'
              label='View Appointments'
              badge={false}
              admin
            /> */}

            <OTPPreferences>
              <SingleLink path='#' icon='lock' label='OTP Preferences' admin />
            </OTPPreferences>

            <section className='site-nav__group' data-open={groups.certs}>
              <div
                className='site-nav__group__toggle'
                data-active={matches('/certs')}
                onClick={toggleGroup('certs')}
              >
                <i className='fas fa-certificate'></i>
                <span>Certificates</span>
                <span className='caret'></span>
              </div>
              <div className='site-nav__group__links'>
                <SingleLink path='/certs/own' label='My Certificates' />
                <SingleLink path='/certs/shared' label='Shared Certificates' />
                <SingleLink path='/certs/history' label='Notifications' />
                <SingleLink path='/certs/scan' label='Scan & Verify' admin />
              </div>
            </section>

            {user.isSupport ? null : (
              <React.Fragment>
                <h4 className='site-nav__subheading'>Services</h4>
                {serviceLinks}
              </React.Fragment>
            )}

            <h4 className='site-nav__subheading'>Actions</h4>

            <SingleLink
              path='/deliveries/scanner'
              icon='qrcode'
              label='Scan Delivery Code'
              allow={['courier']}
              admin
            />

            <SingleLink path='/map' icon='map' />
            <SingleLink path='/faqs' icon='question-circle' label='FAQs' />

            <Link to='/logout'>
              <i className='fas fa fa-sign-out-alt' aria-hidden='true'></i>
              <span>Sign Out</span>
            </Link>
          </nav>
        </div>
      </section>

      <Alert
        {...alert}
        open={alert.message}
        close={clearAlert}
        onClose={clearAlert}
      />
    </React.Fragment>
  )
}

function matches(path, exact = false) {
  if (exact) return window.location.pathname == path || undefined
  return window.location.pathname.startsWith(path) || undefined
}

function initialCaps(str) {
  return str
    .replace('drivers', "driver's")
    .trim()
    .split(/[ _-]/)
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ')
}

export default withRouter(
  connect(state => extractKeys(state.agencies, 'alerts', 'current_alert'), {
    setAgencyAlert,
  })(SiteLinks)
)
