import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class JrJunkanooRegistration extends React.Component {
  resource = '/jr_junkanoo_registrations'
  form_key = 'jr_junkanoo_registration'

  free          = true
  skip_location = true

  validate = null

  foremost = null

  fields = form => {
    const list = [
      {
        name: 'organization_name',
      },
      {
        name: 'category',
        options: [
          { label: 'Competitive', value: 'competitive', },
          { label: 'For Fun', value: 'for fun' }
        ],
      },
      {
        name: 'group_number',
        required: false,
      },
      {
        name: 'sponsor',
      },
      '::Contact Details',
      {
        name: 'i_am_contact',
        type: 'checkbox',
        required: false,
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      },
    ]

    if (!form.i_am_contact) {
      list.push(
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_phone_numb',
          type: 'phone',
          label: 'Primary Phone #',
        },
        {
          name: 'contact_work_numb',
          type: 'phone',
          label: 'Work Phone #',
          required: false
        },
        {
          name: 'contact_home_numb',
          type: 'phone',
          label: 'Home Phone #',
          required: false
        },
        {
          name: 'contact_po_box',
          label: 'P. O. Box',
          required: false
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
      )
    }

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
