import React from 'react'
import Wizard from '@modules/form-wizard'
import common from './vacancy-notice.common'

export default class VacancyNoticeRenewal extends React.Component {
  defaults = {
    renewal: true,
  }

  filter_records = r => r.renewal

  render = () => {
    const props = common(this, true)

    const fields = form => {
      const shared = props.fields(form)
      const checkbox = shared.pop()

      return [
        ...shared,
        {
          key: 'image.7',
          save: true,
          name: 'labour_cert_upload',
          type: 'file:pdf',
          label: 'Last Work Permit',
        },
        checkbox,
      ]
    }

    return <Wizard {...props} fields={fields} />
  }
}
