import React from 'react'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import toastr from '@modules/toastr'
import passportPhotoExample from '@helpers/passport-photo-example'

const FOODS = /(food|vegetable|fruit|drink|dessert)/i

export default class FarmersMarketVendor extends React.Component {
  resource = '/farmer_market_vendors'
  form_key = 'farmer_market_vendor'

  free = true
  skip_location = true
  business_only = true
  disable_fpo = true

  hide_buttons = {
    status: !$app.hasAnyRole('under_secretary', 'permanent_secretary'),
    deny: $app.hasRole('officer')
  }

  canned = {
    'Invalid Police Record': `
      Your police character certificate has been found to be expired.
      Please reapply with a valid character certificate.
    `
  }

  custom_actions = [
    {
      text: 'Forward to Manager',
      icon: 'chevron-right',
      test: r => r.application_decision == 'pending',
      fn: forwardApplication.bind(this, 'Manager', null, 'approved')
    },
    {
      text: 'Forward to Under Secretary',
      icon: 'chevron-right',
      roles: ['senior_officer'],
      // test: r => r.application_decision == 'senior officer viewed',
      fn: forwardApplication.bind(
        this,
        'Under Secretary',
        'Under Secretary',
        'approved'
      )
    },
    {
      text: 'Forward to Permanent Secretary',
      icon: 'chevron-right',
      roles: ['under_secretary'],
      // test: r => r.application_decision == 'under secretary viewed',
      fn: forwardApplication.bind(
        this,
        'Permanent Secretary',
        'Permanent Secretary',
        'recommend'
      )
    },
  ]

  defaults = {}

  validate = form => ({
    items_sold: form.items_sold
      .split('\n')
      .map(item => JSON.stringify({ item })),
  })

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'registered_farmer',
      label: `Are you a registered farmer?`,
      type: 'select:bool'
    },
    {
      name: 'farm_registration_numb',
      label: `Farmer's Registration #`,
      hide: form.registered_farmer === false
    },
    {
      name: 'categories_of_interest',
      multi: true,
      hint: 'Select all that apply',
      options: [
        'Ornamental Plants',
        'Handicraft',
        'Root Crops',
        'Fruits',
        // 'Seafood',
        'Food Processing',
        // 'Marine Resources',
        'Vegetables',
        'Livestock',
        'Food',
        'Farming Products',
        'Processed Food',
        'Body Products',
        'Drinks',
        'Desserts',
        'Visual Arts',
        'Other'
      ]
    },
    {
      name: 'categories_of_interest_other',
      type: 'textarea',
      hide: !form.categories_of_interest?.includes('Other'),
      save: false,
      label: 'Additional Interests',
    },
    {
      name: 'items_sold',
      type: 'textarea',
      hint: 'Please separate each item with a newline',
      label: 'Items To Be Sold',
      format: v => v.split('\n').map(str => str.trim()),
    },
    {
      name: 'special_requirements',
      type: 'textarea',
      hint: 'i.e: Special Electrical Requirements',
      label: 'Special Requirement(s)',
      required: false,
    },

    '::Tent',
    {
      view: false,
      heading: `
        <p class='text-base'>
          You may bring your own tent, or rent one of ours at an additional
          price. If bringing your own, please note that the tent must be either
          plain or have your your own company's branding. Tents
          that display other brands, such as those displaying
          the Kalik or Heineken logo, will not be permitted.
        </p>
      `,
    },
    {
      name: 'company_branded_tent',
      label: 'Do you have your own tent?',
      type: 'select:bool',
    },
    {
      name: 'company_tent_size',
      ...(form.company_branded_tent
        ? {
          label: 'How large is your tent?',
          hint: 'In feet'
        }
        : {
          label: 'What size tent will you require?',
          options: [
            { label: "Single - 1 [10' x 10'] ($42)", value: 'Single' },
            { label: "Double - 2 [10' x 10'] ($60)", value: 'Double' },
            { label: "Triple - 3 [10' x 10'] ($80)", value: 'Triple' },
            // "20' x 20'",
          ]
        }
      )
    },
    {
      name: 'tent_photo_upload',
      key: 'image.3',
      type: 'file:image',
      label: 'Photo of Tent',
      hide: !form.company_branded_tent
    },

    '::Uploads',
    {
      name: 'identification_upload',
      key: 'image.1',
      type: 'file:image',
      label: 'Passport Page',
      hint: passportPhotoExample,
    },
    {
      name: 'police_cert_upload',
      key: 'image.2',
      type: 'file:all',
      label: 'Valid Police Character Certificate',
    },
    {
      name: 'food_handlers_cert_upload',
      type: 'file:all',
      key: 'image.4',
      label: `Food Handler's Certificate`,
      hide: !form.categories_of_interest?.some(str => FOODS.test(str)),
      required: false,
    },
    {
      name: 'registration_cert_upload',
      type: 'file:pdf',
      key: 'image.5',
      label: `Farmer's Registration Certificate`,
      hide: !form.registered_farm
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

async function forwardApplication(position = '', label = '', status = 'approved', record) {
  const { isDismissed } = await Swal.fire({
    icon: 'question',
    title: `Forward To ${position}`,
    html: `
      Are you sure that you are ready to push this application to
      the ${label ?? position}?
    `,
    showCancelButton: true,
    confirmButtonText: 'Yes'
  })

  if (isDismissed) return

  try {
    await $app.axios.put(this.resource + '/update_application', {
      [this.form_key]: {
        form_num: record.form_num,
        application_decision: status || 'approved'
      }
    })

    toastr.success('Success', 'Application successfully forwarded')
  } catch (err) {
    console.error(err)
  }
}