import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class YouthLeader extends React.Component {
  resource = '/certified_youth_leaders'
  form_key = 'certified_youth_leader'
  defaults = {}

  // Delete lines as needed
  fee           = 25
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'qualifications_list',
      label: 'Qualifications (Degrees, Certifications, Etc.)',
      columns: ['qualification'],
    },
    {
      name: 'experience_list',
      label: 'Experience in Youth/Work Organizations',
      columns: ['organization', 'position'],
    },

    '::Organization & Contact Reference',
    {
      name: 'organization_name',
      label: 'Name of Organiztion',
    },
    {
      name: 'contact_first_name',
      label: 'Contact Reference First Name',
    },
    {
      name: 'contact_last_name',
      label: 'Contact Reference Last Name',
    },
    {
      name: 'contact_position',
      label: 'Contact Reference Position',
    },
    {
      name: 'contact_years_as_leader',
      label: 'Contact Reference # of Years as Member/Leader',
      is: 'integer'
    },
    {
      name: 'contact_phone_numb',
      type: 'phone',
      label: 'Contact Reference Phone #',
    },
    
    '::Emergency Contact(s)',
    {
      name: 'emergency_contacts',
      columns: [
        'first_name',
        'last_name',
        {
          name: 'relationship',
          datalist: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
        },
        {
          name: 'phone_numb',
          label: 'Phone #',
          type: 'phone',
        }
      ]
    },
  
    '::SPACER',
    {
      name: 'photo_upload',
      key: 'image.0',
      type: 'file:image',
      label: 'Self Portrait',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
