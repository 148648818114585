import React, { Fragment } from 'react'
import Swal from 'sweetalert2'
import Modal from 'react-responsive-modal'
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import InputField from '@shared/form-fields/input'
import Loader from '@shared/loader'

export default class TradeDispute extends React.Component {
  state = { record: null, loading: false, date: '' }

  resource = '/trade_disputes'
  form_key = 'trade_dispute'

  free = true
  skip_location = true
  hide_buttons = false

  hide_buttons = {
    status: true,
    deny: true,
  }

  color_codes = (wizard, bg) => {
    if (wizard.citizen) return []

    return [
      {
        style: bg('#0E23'),
        when: r => r.notes.find(note =>
          /marked as ready .+ industrial tribunal/i.test(note.text)
        )
      }
    ]
  }

  custom_actions = [
    // Records Manager
    // Concilliator
    // Minister
    {
      text: 'Set Appointment & Forward To Deputy Director',
      icon: 'chevron-right',
      test: r => r.application_decision == 'pending',
      fn: async record => this.setState({ record })
    },
    {
      text: 'Stamp As Ready To Move To Tribunal',
      icon: 'bullhorn',
      roles: ['director'],
      test: r => r.appointment_date,
      fn: async record => {
        const { props } = this
        const { service } = props

        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Forward To Industrial Tribunal',
          text: `
            Are you prepared to allow this application to be sent off
            to the Industrial Tribunal for further processing?
          `,
          showCancelButton: true,
          confirmButtonText: 'Yes',
        })

        if (isDismissed) return

        try {
          await $app.axios.post('/services/notes/add_note', {
            note: {
              note_type: 'note',
              internal_view_only: true,
              notable_type: service.type,
              notable_id: record.id,
              text: 'SYSTEM: Application marked as ready to be forward to the'
                + ' Industrial Tribunal by Deputy Director '
                + $app.current_user.last_name.capitalize()
            },
          })

          toastr.success('Success', 'Note successfully attached to application')
        } catch (error) {
          console.error(error)
          Swal.fire(
            'Oops...',
            'There has been an error with processing your application',
            'error'
          )
        }
      }
    },
  ]

  defaults = {
    "client_type": "individual",
    "po_box": "N00001",
    "phone_number": "(242) 441-3229",
    "employer_first": "Wendy's\n(242) 555-5555",
    "employer_position": "Store Manager",
    "union_name": "Bahamas Hotel Catering And Allied Workers Union.",
    "job_title": "Floor Staff",
    "relevant_dispute_issues": "Overtime not being clocked and paid correctly",
    "action_taken": "N/A",
  }

  validate = values => {
    const update = {
      employer: {
        sex: values.employer_sex,
        first_name: values.employer_first,
        middle_name: values.employer_middle,
        last_name: values.employer_last,
      },
    }

    return update
  }

  foremost = null

  fields = form => [
    {
      heading: `
        <span class='uppercase'>
          Persuant to the provisions of paragraph (a), (b), (c) and (d) of
          subsection (1) of section (68) and subsection (3) of
          section 68 of the industrial relations act, chapter 321 of the
          statute law counselate of The Bahamas 2000, you are hereby
          notified that a trade dispute exists between the parties below:
        </span>
      `,
      view: false,
    },
    ...BUSINESS_FIELDS(form),
    {
      name: 'po_box',
      label: 'P. O. Box',
      required: false,
    },
    {
      name: 'phone_number',
      type: 'tel',
      use_profile: 'additional_phone_number'
    },
    '::Employer Details',
    {
      name: 'employer_first',
      label: '(1) Name and Address of Company (including Phone No:)',
      type: 'textarea',
      long: true,
    },
    // {
    //   name: 'employer_middle',
    //   label: 'Middle Name',
    //   required: false,
    // },
    // {
    //   name: 'employer_last',
    //   label: 'Last Name',
    // },
    // {
    //   name: 'employer_sex',
    //   label: 'Sex',
    //   options: ['Male', 'Female'],
    // },
    {
      name: 'employer_position',
      label: '(1a) Name and position of the Employer',
      type: 'textarea',
      long: true
    },
    '::SPACER',
    {
      name: 'union_name',
      label: '(2) Name of Employee or Trade Union',
      type: 'textarea',
      long: true,
    },
    {
      name: 'job_title',
      label: '(2a) Job Title of Employee',
      type: 'textarea',
      long: true,
    },
    {
      name: 'employee_names_on_behalf',
      label: '(3) Name of Person(s) on behalf of whom the report is made',
      required: false,
      columns: [
        // 'title',
        // {
        //   name: 'nib',
        //   label: 'NIB',
        // },
        {
          name: 'first_name',
          required: false,
        },
        {
          name: 'middle_name',
          required: false,
        },
        {
          name: 'last_name',
          required: false,
        },
      ],
    },
    {
      name: 'authority_to_act',
      label: '(4) The Authority to act on behalf of the person desiring the dispute to be reported',
      type: 'textarea',
      long: true,
      required: false,
    },
    {
      name: 'counsel_address',
      label: '(4a) Address of Counsel/Representative',
      type: 'textarea',
      long: true,
      required: false,
    },
    {
      name: 'relevant_dispute_issues',
      type: 'textarea',
      label: '(5) Issues Relevant to the Trade Dispute',
    },
    {
      name: 'action_taken',
      type: 'textarea',
      label: '(6) Action taken for dealing with dispute under existing agreement',
    },
    // {
    //   name: 'physical_lease',
    //   type: 'select:bool',
    // },
    {
      name: 'acknowledge_forwarding',
      type: 'checkbox',
      label: `
        I acknowledge that it is my responsibility to forward a copy
        of this report to the employer once my own report is generated
        by MyGateway
      `,
    },
    {
      name: 'declare',
      type: 'checkbox',
      label: `
        I declare that the information entered is true and correct
        to the best of my knowledge
      `,
    },
  ]

  closeModal = () => this.setState({ record: null })

  setAppt = async () => {
    const { props, state, resource, form_key } = this
    const { history, location, service } = props
    const { date, record } = state

    this.setState({ loading: true })

    try {
      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'processing',
          appointment_date: new Date(date)
        },
      })

      await $app.axios.post('/services/notes/add_note', {
        note: {
          note_type: 'note',
          internal_view_only: true,
          notable_type: service.type,
          notable_id: record.id,
          text: 'SYSTEM: Application forwarded to Director by officer '
            + record.user.first_name.charAt(0).capitalize()
            + '. '
            + record.user.last_name.capitalize()
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'processing'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      toastr.success('Success', 'Application successfully updated')
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing your application',
        'error'
      )
    } finally {
      this.setState({ record: null, loading: false })
    }
  }

  render = () => (
    <Fragment>
      <Loader loading={this.state.loading} />
      <Wizard {...SERVICE_PROPS(this)} />
      <Modal
        open={!!this.state.record && !this.state.loading}
        onClose={this.closeModal}
        classNames={{ modal: 'w-full sm:w-128' }}
        center
      >
        <header className='modal-header'>
          <h4 className='mb-0'>Set Concilliation Hearing Date</h4>
        </header>
        <ValidatorForm onSubmit={this.setAppt}>
          <article className='modal-body'>
            <label htmlFor='date' className='form-label'>
              Please set the applicant's appointment date
            </label>
            <InputField
              type='date'
              name='date'
              icon={null}
              min={new Date().toISOString().slice(0, 10)}
              value={this.state.date}
              onChange={ev => this.setState({ date: ev.target.value })}
              validators={['required']}
              errorMessages={['Please enter a valid date']}
              className='form-control'
            />
          </article>
          <footer className='modal-footer'>
            <button type='button' className='btn' onClick={this.closeModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </footer>
        </ValidatorForm>
      </Modal>
    </Fragment>
  )
}
