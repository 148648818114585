import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import axios from 'axios'

import toastr from '@modules/toastr'
import Fields from '@shared/form-fields'
import ENV from '@constants/env'

export const calculateExemptionToatal = arr => {
  if (!arr?.length) return 0
  return arr.reduce(
    (sum, item) =>
      sum +
      Number(item.qty) * Number(String(item.value)?.replace('$', '') || 0),
    0
  )
}

export const VehicleForm = viewMode => [
  'make',
  'model',
  {
    name: 'year',
    is: 'integer',
  },
  {
    name: 'qty',
    is: 'integer',
    width: 64,
  },
  {
    name: 'value',
    type: 'currency',
    width: 128,
  },
  ...(viewMode ? [{ name: 'status', width: 128 }] : []),
]

export const ExemptionsForm = (table, update, viewMode) => ({
  columns: [
    {
      name: 'item',
      placeholder: 'Item Description...',
      onBlur: async ({ target }) => {
        if (!target.value) return
        const { data } = await axios.get(ENV.TARIFF_CODES_URL, {
          params: { query: target.value },
        })
        update(
          data.hscodes,
          target.name.replaceAll('::', '.').replace('item', 'tariff')
        )
      },
    },
    {
      name: 'qty',
      is: 'integer',
      placeholder: '0',
      width: viewMode ? 80 : 128,
    },
    {
      name: 'tariff',
      value: v => v?.value,
      width: 128,
      hide: viewMode,
    },
    {
      name: 'value',
      is: 'currency',
      placeholder: '0.00',
      width: 128,
    },
    ...(viewMode
      ? [
          { name: 'tariff_code', width: 128 },
          { name: 'status', width: 128 },
        ]
      : []),
  ],

  footer: (
    <div className='float-left ml-4'>
      <strong>Total: ${calculateExemptionToatal(table).toFixed(2)}</strong>
    </div>
  ),
})

const headings = (
  <thead>
    <tr>
      <th>Item</th>
      <th>Qty</th>
      <th>Price</th>
      <th>Approved?</th>
      {/* <th>Value</th> */}
    </tr>
  </thead>
)

const colgroup = (
  <colgroup>
    <col span='1' width='auto'></col>
    <col span='1' width='128px'></col>
    <col span='1' width='128px'></col>
    <col span='1' width='80px'></col>
    {/* <col span='1' width='128px'></col> */}
  </colgroup>
)

export class ApprovalForm extends React.Component {
  constructor(props) {
    super(props)
    this.__labels = {}

    this.state = props.fields
      .filter(
        f =>
          f.name != 'shareholders' &&
          !!f.columns &&
          props.record[f.name]?.length
      )
      .reduce(
        (obj, f) => ({
          ...obj,
          [f.name]: props.record[f.name].map(r => ({
            ...r,
            status: r.status || false,
          })),
        }),
        {}
      )

    this.state.total_value_of_exemption = this.computeTotal(this.state)
  }

  on = {
    change:
      (key, index) =>
      ({ target }) =>
        this.setState(state => {
          const val = target.value
          if (val && !/^[1-9][0-9,]{0,}$/.test(val)) return state

          state[key][index].qty = val ? Number(val) : ''
          state.total_value_of_exemption = this.computeTotal(state)
          return state
        }),

    check:
      (table, index) =>
      ({ target }) =>
        this.setState(state => {
          state[table][index].status = target.checked
          state.total_value_of_exemption = this.computeTotal(state)
          return state
        }),
  }

  label = (key, fields) => {
    if (this.__labels[key]) return this.__labels[key]

    const target = fields.find(f => f.name == key)
    const label = target.label || key.replaceAll('_', ' ').capitalize()

    this.__labels[key] = label
    return label
  }

  submit = async ev => {
    const { state, props } = this
    const { resource, form_key, record, history, location, update } = props
    const view = location.state?.view

    let decision = null
    update('SAVING')

    for (let [key, arr] of Object.entries(state)) {
      if (!Array.isArray(arr)) continue

      // eslint-disable-next-line
      state[key] = arr.map(row => {
        if (!decision && row.status) {
          decision = 'approved'
        }

        return JSON.stringify({
          ...row,
          status: row.status ? 'approved' : 'denied',
          value: Number(row.value.replace('$', '')),
        })
      })
    }

    try {
      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          ...state,
          application_decision: decision || 'denied',
        },
      })
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to update statuses of exemption items')
      update('FAILED')
      return
    }

    if (view) {
      const tables = Object.keys(state)
      const fields = [...view.fields].map(f =>
        tables.includes(f.name) ? { ...f, value: state[f.name] } : f
      )

      history.replace(location.pathname, {
        ...location.state,
        view: { ...location.state?.view, fields },
      })
    }

    update('DONE')
    toastr.success('Success', 'Successfully updated approved exemption items')
  }

  computeTotal = state =>
    Object.entries(state).reduce((sum, [key, arr]) => {
      if (!Array.isArray(arr)) return sum
      return sum + calculateExemptionToatal(arr.filter(r => r.status))
    }, 0)

  formatDate = date =>
    typeof date == 'string'
      ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
      : date

  render() {
    const { state, props, on, label, submit } = this
    const { close, fields } = props

    const tables = Object.entries(state).map(([key, data]) => {
      if (!Array.isArray(data)) return null

      const rows = data.map((row, i) => (
        <tr key={i}>
          <td>
            {row.item || `${row.year} ${row.make} ${row.model}`}
            <br />
            <span className='text-muted'>{row.tariff_code}</span>
          </td>
          <td>
            <Fields.Input
              icon={null}
              value={row.qty}
              className='form-control'
              onChange={on.change(key, i)}
              validators={['required']}
              errorMessages={['Required']}
            />
          </td>
          <td>@ {row.value}</td>
          <td>
            <input
              type='checkbox'
              checked={data.status}
              onChange={on.check(key, i)}
            />
          </td>
        </tr>
      ))

      return (
        <div key={key} className='form-wizard-form-table table-responsive'>
          <h5>{label(key, fields)}</h5>
          <table className='table table-striped table-striped-bg-black'>
            {colgroup}
            {headings}
            <tbody>{rows}</tbody>
          </table>
        </div>
      )
    })

    return (
      <div style={{ maxWidth: '100%', width: '1024px' }}>
        <div className='modal-header'>
          <h5 className='modal-title'>Approve Items</h5>
        </div>
        <ValidatorForm id='exemption-items-approvals' onSubmit={submit}>
          <div className='modal-body'>
            {tables}
            <br />
            <div className='clearfix'>
              <strong>Total Approved Exemption Value:</strong>
              <strong className='float-right'>
                $
                {Number(state.total_value_of_exemption || '0').toLocaleString()}
              </strong>
            </div>
          </div>
        </ValidatorForm>
        <div className='modal-footer'>
          <button className='btn btn-round mr-2' onClick={close}>
            Cancel
          </button>
          <input
            className='btn custom-btn btn-round'
            form='exemption-items-approvals'
            type='submit'
            value='Submit'
          />
        </div>
      </div>
    )
  }
}
