import React from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'

import { Spinner } from './utils'
import { extractKeys } from '@helpers/objects'
import toastr from '@modules/toastr'
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm'

export default class RecordsTable extends React.Component {
  state = {
    records: [],
    clear: false,
    selected: [],

    page: 1,
    start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    end_date: moment().add(1, 'days').format('YYYY-MM-DD'),
    per_page: 10,
    metadata: {},
  }

  tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')

  componentDidMount() {
    this.fetch(true)
  }

  fetch = async () => {
    this.props.setLoading('applications')

    try {
      const { data } = await $app.axios.get('/services/all_paid_forms', {
        params: {
          ...extractKeys(
            this.state,
            'start_date',
            'end_date',
            'page',
            'per_page'
          ),
          service_type: this.props.service_type ?? this.props.service?.type,
        },
      })

      this.setState(state => ({
        ...state,
        metadata: data.results_params,
        records: data.paid_forms,
      }))
    } catch (err) {
      toastr.error('Error', 'Failed to fetch paid requests')
    }

    this.props.setLoading(false)
  }

  on = {
    date: ev =>
      this.setState({ [ev.target.name]: ev.target.value }, () =>
        this.fetch(true)
      ),
    clear: () => this.setState({ clear: !this.state.clear }),

    page: page => this.setState({ page }, this.fetch),
    per_page: per_page => this.setState({ per_page }, this.fetch),
  }

  columns = [
    {
      name: 'Name',
      selector: 'user.name',
      sortable: true,
      cell: r => (
        <span onClick={this.props.view(r)} data-action>
          {`${r.user.last_name}, ${r.user.first_name}`.capitalize()}
        </span>
      ),
    },
    {
      name: 'Submitted',
      selector: 'created_at',
      sortable: true,
      width: '144px',
      format: ({ created_at }) => moment(created_at).format('YYYY-MM-DD'),
      sortFunction: (a, b) =>
        new Date(a.created_at).getTime() < new Date(b.created_at).getTime(),
    },
    {
      name: 'Last Update',
      selector: 'updated_at',
      sortable: true,
      width: '144px',
      format: ({ created_at }) => moment(created_at).format('YYYY-MM-DD'),
      sortFunction: (a, b) =>
        new Date(a.updated_at).getTime() < new Date(b.updated_at).getTime(),
    },
    // {
    //   name: 'Pickup Location',
    //   omit: this.props.skip_location,
    //   selector: 'pickup_location',
    //   sortable: true,
    //   format: r => r.pickup_location ? `${r.pickup_sub_location}, ${r.pickup_location}`.capitalize() : 'N/A',
    // },
    {
      name: 'Status',
      maxWidth: '150px',
      selector: 'application_decision',
      format: r => (r.application_decision || 'pending').toUpperCase(),
      sortable: true,
    },
    {
      name: 'Amount',
      maxWidth: '150px',
      selector: 'payment_amount',
      format: r => r.payment_amount
        ? '$' + r.payment_amount
        : 'N/A',
      sortable: true,
    },
    {
      name: 'Invoice',
      maxWidth: '150px',
      selector: 'payment_invoice',
      sortable: true,
    },
    {
      name: 'AUTH CODE',
      selector: 'auth_dcode',
      maxWidth: '150px',
      omit: this.props.service.agency.code != 'rtd',
    },
  ]

  render() {
    const { state, props, on, columns, tomorrow } = this

    const datatable = {
      columns,

      noHeader: true,
      data: state.records,
      defaultSortField: 'created_at',
      defaultSortAsc: true,

      progressPending: !!props.loading,
      progressComponent: <Spinner />,
      paginationTotalRows: state.metadata.no_of_records,
      onChangeRowsPerPage: on.per_page,
      onChangePage: on.page,
    }

    return (
      <React.Fragment>
        <div className='flex md:flex-row md:justify-between items-center mb-4'>
          <div className='form-records-table-action'>
            <ValidatorForm
              onSubmit={() => {}}
              className='flex flex-row items-center'
            >
              <span>From:&nbsp;</span>
              <input
                type='date'
                name='start_date'
                value={state.start_date}
                onChange={on.date}
                max={state.end_date}
                className='form-control ml-1 mr-2'
              />
              <span>To:&nbsp;</span>
              <input
                type='date'
                name='end_date'
                value={state.end_date}
                max={tomorrow}
                onChange={on.date}
                className='form-control ml-1 mr-2'
              />
            </ValidatorForm>
          </div>
        </div>

        <DataTable {...datatable} pagination paginationServer striped />
      </React.Fragment>
    )
  }
}
