import React from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'

import { getCurrentStage } from './_marine-utility'
import { getLists } from '@actions/users'
import fishing_areas from '@constants/fishing-areas'
import islands from '@constants/islands'
import ENV from '@constants/env'
import SERVICE_PROPS from '@helpers/extract-service-props'
import passportPhotoExample from '@helpers/passport-photo-example'

class CompressorPermitRenewal extends React.Component {
  state = {
    backspace: false,
  }

  componentDidMount() {
    !this.props.lists && this.props.getLists()
  }

  long_name = 'Compressor Permit Renewal'
  resource = '/compressor_permits'
  form_key = 'compressor_permit'
  free = false
  postpaid = false
  defaults = {
    renewal: true,
  }

  read_params = {
    // admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: true,
      },
    },
  }
  
  skip_location = true
  hide_buttons = {
    status: true,
    deny: true,
  }
  custom_actions = [
    {
      text: 'Submit to Director',
      icon: 'id-badge',
      test: r => r.payment_status && r.application_decision == 'pending',
      fn: record =>
        Swal.fire({
          title: 'Are you sure that you would like to submit?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and the accompanying attachments, and are ready to submit it to a Director/Approved Signatories. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.submitToDirector(record)
          }
        }),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      test: r => r.payment_status && (r.application_decision == 'processing' || r.application_decision == 'officer viewed'),
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.approve(record)
          }
        }),
    },
    {
      text: 'Deny',
      icon: 'ban',
      test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
  ]
  custom_acceptance = false
  // filter_records = r => r.renewal

  // fee = 12.2

  validate = null

  foremost = {
    type: 'select:bool',
    label: `
      Are you a commercial fisherman with a port licence?
    `,
    test: v =>
      v || 'Sorry, but this application is only for commercial fishermen',
  }

  columns = props => [
    {
      name: 'Current Stage',
      selector: 'application_decision',
      width: '300px',
      cell: r => <span>{getCurrentStage(r) || 'N/A'}</span>,
      searchable: r => getCurrentStage(r),
    },
  ]

  service_type = 'CompressorPermit'

  submitToDirector = async record => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'officer viewed'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application is now ready for final approval by the Director/Approved Signatories.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application for final approval',
        'error'
      )
      return
    }
  }

  approve = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application successfully approved')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to approve application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - Compressor Permit Renewal',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Congratulations! Your application for a Compressor Permit Renewal has been approved.
            <br/>
            A downloadable link for your PDF permit will be emailed to you shortly.
            <br/>
            Thank you for using the MyGateway Portal.
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
      try {
        const { data } = await $app.axios.get('/services/all_paid_forms', {
          params: {
            service_type: this.service_type || props.service.type,
          },
        })
        const updated_record = data.paid_forms.filter(
          d => d.id === record?.id
        )[0]
        await $app.axios.post('/emails', {
          email: record.user.email,
          subject: `MyGateqway Portal Update -  Compressor Permit Renewal`,
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
           Your application for a Compressor Permit Renewal has been approved. As promised, below is a link to your downloadable PDF Permit.
            <br/><br />
            <p><b>Links</b></p>
            <ul>
            <li><a href='${`${ENV.WEB_SERVICE_URL}${updated_record?.image[0]}`}' target='_blank'>Compressor Permit Renewal</a></li>
            </ul>
            <br/><br/>
            Thank you for using MyGateway Portal.
          `,
        })
      } catch (error) {
        toastr.error('Error', 'Unable to send permit email')
        console.error(error)
      }
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
          license_numb: record.license_numb || 0,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - Compressor Permit Renewal',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Thank you for your interest in applying for a compressor permit renewal. 
            Unfortunately, your application was denied due to outstanding documents required. 
            Please review the requirements and resubmit. 
            Thank you for using MyGateway.gov.bs, the centralized way to request and pay for online government services. 
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  fields = form => [
    {
      name: 'renewal',
      hide: true,
      save: true,
    },
    {
      name: 'license_numb',
      label: 'Permit Number #',
      is: 'integer',
    },
    {
      name: 'captain',
      type: 'select:bool',
      label: 'Are you the captain?',
    },
    ':: Vessel Details',
    {
      name: 'vessel_name',
      label: 'Name of Vessel',
    },
    {
      name: 'port_registration_number',
      label: 'Port Registration Number of Vessel',
      callback: v => {
        let backspace = this.state?.backspace
        let val = v.toUpperCase()

        if (v.length === 2 && !backspace) {
          val += '-'
          this.setState((prevState, props) => ({
            backspace: !prevState.backspace,
          }))
        }

        if (v.length < 2 && backspace)
          this.setState((prevState, props) => ({
            backspace: !prevState.backspace,
          }))

        if (v.length > 3) {
          console.log(v)
          if (!/^[0-9]+$/.test(v.substring(3)))
            return { port_registration_number: val.substring(0, 3) }
        }

        return { port_registration_number: val }
      },
      hint: 'Registration Number is alphanumeric, e.g. BS-3434354',
    },
    {
      name: 'overall_length',
      label: 'Overall Length of Vessel',
      is: 'integer',
      hint: 'In Feet',
    },
    {
      name: 'home_port',
      label: 'Home Port',
      options: islands,
    },
    {
      heading: "Captain's Details",
    },
    {
      name: 'captain_first_name',
      label: 'First Name',
      hide: form.captain,
      view: !form.captain,
    },
    {
      name: 'captain_last_name',
      label: 'Last Name',
      hide: form.captain,
      view: !form.captain,
    },
    // {
    //   name: 'captains_nationality',
    //   label: 'Nationality',
    //   options: nationalities,
    // },
    {
      name: 'captain_address',
      label: 'Street Address',
      hide: form.captain,
      view: !form.captain,
    },
    {
      name: 'captain_passport_upload',
      label: 'Passport Photo',
      type: 'file:image',
      hide: form.captain,
      view: !form.captain,
      key: 'image.3',
      hint: passportPhotoExample,
    },
    {
      name: 'captain_license_numb',
      label: 'Captain License Number',
      required: false,
    },
    '::SPACER',
    {
      name: 'numb_of_compressors',
      label: 'Number of Compressors Requested',
      is: 'integer',
    },
    {
      name: 'working_areas',
      label: 'Proposed Working Areas',
      options: fishing_areas,
      multi: true,
      hint: `<span style="background-color: #FFFF00">Select <strong>ALL</strong> of the options that apply</span>`,
    },
    {
      name: 'numb_compressors_prev_used',
      label: 'Number of Compressors used last season',
      is: 'integer',
    },
    {
      name: 'dive_cert_upload',
      label: 'Dive Certification',
      type: 'file:all',
      key: 'image.2',
    },
    {
      name: 'port_registration_upload',
      label: 'Port Registration Card',
      type: 'file:all',
      key: 'image.1',
      hint: `<span style="background-color: #FFFF00">WARNING: This card should be a reflection of your commercial status as a fisherman. If you are a leisure fisherman, your application will be <strong>denied</strong></span>`,
    },
    {
      name: 'declare',
      label:
        'I DECLARE THAT ANY PERMIT USED AS A RESULT OF THIS APPLICATION WILL BE USED ONLY IN ACCORDANCE WITH THE FISHERIES RESOURCES (JURISDICTION AND CONSERVATION) REGULATIONS, 1986 AND THE REGULATIONS MADE THEREUNDER.',
      type: 'checkbox',
    },
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Compressor Permit',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export default connect(
  ({ users }) => ({
    lists: users.lists,
    countries: users.lists?.countries || [],
  }),
  { getLists }
)(CompressorPermitRenewal)
