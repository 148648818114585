import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class PlaysControlBoard extends React.Component {
  resource = '/plays_control_boards'
  form_key = 'plays_control_board'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Applicant info",
    {
        name: 'first_name',
    },
    {
        name: 'last_name',
    },
    {
        name:'email',
        type: 'email',
    },
    "::Additional Info",
    {
        name: 'date',
        type: 'date',
    },
    {
        name:'script_name',
        label: 'Name of script submission',
    },
    {
        name:'script_format',
        label: 'Format of script',
        options: ['Film', 'Stageplay'],
    },
    {
        name: 'name',
        label: 'Name of the person or organization releasing film or stage to the public',
    },
    {
        name: 'proposed_date',
        label: 'Proposed date of upcoming public release',
        hint: 'Date of stageplay or film exhibition',
        type: 'date',
    },
    {
        name: 'brief_synopsis',
        label: 'Brief synopsis of the script submission',
        typ: 'textarea',
    },
    {
        name: 'select_multiple',
        label: 'Select all that applies to this script submission',
        multi: true,
        options:[
            'Has scenes unsuitable for children',
            'Use of foul language',
            'Use of sexual imagery or references',
            'Use of alcohol, smoking, drug use',
            'Frequent use of graphic violence',
            'Has scene depicting sexual acts or nudity',
            'Has scenes depicting sexual violence, suicide, incest, drug abuse, gore, ritual sacrifice',
        ]
    },
    {
        name: 'page_reference',
        label: 'Based on your selection above, please reference at least one page number per box for further verification',
        type:'textarea',
    },
    {
        name: 'script_rating',
        label: 'Proposed rating of submitted script',
        options:[
            'Rating A - General Audience',
            'Rating B - Parental Guidance',
            'Rating T - Not suitable for persons under 15 years',
            'Rating C - Suitable for adults only'
        ],
       
    },
    {
        name: 'script_upload',
        label: 'Upload script',
        key: 'image.0',
        type: 'file:pdf',
    },
    {
        name: 'additional_notes',
        hint: 'Optional',
        required: false,
    }

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
