import React from 'react'
import Swal from 'sweetalert2'

import SERVICE_PROPS from '@helpers/extract-service-props'
import Wizard from '@modules/form-wizard'
import breeds from '@constants/dog-breeds'
import countries from '@constants/countries'
import { extractKeys } from '@helpers/objects'

export default class DogLicensing extends React.Component {
  title = 'Annual Dog Registration & Licencing Renewal'
  resource = '/dog_licenses'
  form_key = 'dog_license'

  skip_location = true

  state = { dogs: [] }
  today = new Date()
  before_apply = false
  filter_records = r => r.renewal

  before_apply = async () => {
    if (!this.dogs?.length) {
      Swal.fire(
        `You Don't Have Any Registered Dogs`,
        '',
        'warning',
      )

      return null
    }
  }

  validate = form => {

    return {
      __OVERWRITE__: true,
      __TRANSFORM__: false,

      [this.form_key]: extractKeys(
        this.dogs[form.dog_name],
        'form_num',
        'license_numb',
        'neutered',
        'dog_name',
        'dog_sex',
        'dog_date_of_birth',
        'dog_place_of_birth',
        'dog_breed',
        'dog_predominant_breed',
        'dog_color',
        'dog_desc_of_use',
        'countries_visited_last_6_months',
      )
    }
  }

  hooks = {
    'post:read': data => {
      this.dogs = data.filter(row => row.application_decision == 'approved')
    }
  }

  fields = (form, viewing) => {
    if (!viewing) {
      return [
        {
          heading: `
            <span class='text-base'>
              Please select the dog(s), whose licence(s) you wish to renew
            </span>`,
        },
        {
          name: 'dog_name',
          save: false,
          label: 'Dog',
          options: this.dogs
            ?.map((r, i) => ({
              label: r.dog_name
                + (r.predominant_breed
                  ? ` (${r.predominant_breed} Mixed)`
                  : ` (${r.breed})`
                ),
              value: i
            }))
        },
        {
          name: 'rabies_expiration_date',
          label: 'Rabies Expiration Date',
          type: 'date',
          showYearDropdown: true,
          minDate: this.today,
        },
        {
          name: 'dhlpp_expiration_date',
          label: 'DHLPP Expiration Date',
          type: 'date',
          showYearDropdown: true,
          minDate: this.today,
        },
        {
          name: 'vaccination_upload',
          type: 'file:image',
          label: 'Vaccination Card',
        },
      ]
    }

    return [
      {
        name: 'renewal',
        label: 'Renewal?',
        type: 'select:bool',
        hide: true,
        view: true,
      },
      {
        name: 'license_numb',
        label: 'License Number',
        hide: !form.renewal,
        view: true,
      },
      {
        name: 'renewal_date',
        type: 'date',
        hide: !form.renewal,
        view: true,
      },
      '::SPACER',
      {
        name: 'dog_name',
        label: `Pet's Name`
      },
      {
        name: 'dog_sex',
        label: 'Sex',
        options: ['Male', 'Female'],
      },
      {
        name: 'dog_date_of_birth',
        label: 'Birth Date',
        type: 'date',
      },
      {
        name: 'breed',
        label: 'Breed of Dog',
        options: breeds,
        view: {
          value: v => v + (form.mixed_breed ? ', Mixed' : '')
        }
      },
      {
        name: 'microchip_numb_or_brand',
        label: 'Microchip #',
        hint: 'Leave blank if dog is not chipped',
      },
      {
        name: 'neutered',
        label: 'Neutered?',
        type: 'select:bool',
      },
      {
        name: 'dog_color',
        label: 'Color',
        multi: true,
        options: [
          'Tan',
          'Black',
          'Blonde',
          'Brindle',
          'Brown',
          'Fawn',
          'Golden',
          'Silver',
          'Grey',
          'Tri-Color',
          'White',
          'Red',
          'Spotted',
          'Blue Merle',
          'Other',
        ],
      },
      {
        name: 'dog_place_of_birth',
        label: 'Country of Birth',
        options: countries
      },
      {
        name: 'countries_visited_last_6_months',
        label: 'What countries has this dog visited in the last 6 months?',
        multi: true,
        options: countries,
      },
      {
        name: 'dog_desc_of_use',
        label: 'Description of Use',
        multi: true,
        options: [
          'Pet/Companion',
          'Breeding Animal',
          'National Security Canine',
          'Private Security Canine',
          'Show Dog',
          'Service Dog',
          'Other',
        ]
      },
      {
        name: 'rabies_expiration_date',
        label: 'Rabies Expiration Date',
        type: 'date',
      },
      {
        name: 'dhlpp_expiration_date',
        label: 'DHLPP Expiration Date',
        type: 'date',
      },
      {
        name: 'vaccination_upload',
        label: 'Vaccination Card',
        type: 'file:image',
      },

      {
        heading: 'Previous Owner',
        view: !!(form.original_dog_owner || form.transfer_of_ownership)
      },
      {
        name: 'original_dog_owner',
        label: 'Are you the prev owner?',
        type: 'select:bool',
      },
      {
        name: 'transfer_of_ownership',
        label: 'Ownership Transferred?',
        type: 'select:bool',
      },
      {
        name: 'original_dog_owner_f_name',
        hide: !(form.original_dog_owner && form.dog_ownership_transfer),
        label: 'First Name',
      },
      {
        name: 'original_dog_owner_m_name',
        hide: !(form.original_dog_owner && form.dog_ownership_transfer),
        label: 'Middle Name',
        required: false,
      },
      {
        name: 'original_dog_owner_l_name',
        hide: !(form.original_dog_owner && form.dog_ownership_transfer),
        label: 'Last Name',
      },
      {
        name: 'original_dog_owner_phone_numb',
        is: 'phone',
        hide: !(form.original_dog_owner && form.dog_ownership_transfer),
        label: 'Phone of previous Owner',
      },
    ]
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
