// export const advanced_poll_date = 'To be Announced'
export const advanced_poll_date = '9th September, 2021'

export const cutoff_dates = {
  j: '2021-08-28T00:00:00',
  k: '2021-09-05T00:00:00',
}

export const ministries = [
  'Ministry of Foreign Affairs',
  'Maritime Authority',
  'Ministry of Tourism',
  'Not Applicable',
]

export const overseas_locations = [
  { label: 'St. James, Barbados', value: 'BAR' },
  { label: 'Port of Spain, Trinidad & Tobago', value: 'TRI' },
  { label: 'Kingston, Jamaica', value: 'JAM' },
  { label: 'Atlanta, Georgia', value: 'ATL' },
  { label: 'Miami, Florida', value: 'MIA' },
  { label: 'New York', value: 'NEW' },
  { label: 'Washington D.C', value: 'WAS' },
  { label: 'Ottawa, Canada', value: 'OTT' },
  { label: 'England, United Kingdom', value: 'LON' },
]

export const approval_reasons = [
  // { label: 'I am a member of the Defence Force', value: 'D' },
  // { label: 'I am a member of the Police Force', value: 'P' },
  { label: 'I am a candidate', value: 'C' },
  { label: 'I am the spouse of a candidate', value: 'S' },
  { label: 'I am an Election Day Worker', value: 'W' },
  { label: 'I am a Parliamentary Employee', value: 'E' },
  { label: 'I am an Election Official', value: 'L' },
  // { label: 'I am 65 years of age or older', value: 'A' },
  { label: 'I have a disabilty', value: 'A' },
  { label: 'I have a medical condition', value: 'M' },
  { label: 'I will be outside of The Bahamas on Election day', value: 'T' },
]

export const approval_strings = {
  M: 'Medical Condition',
  A: 'Physical Disabilty',
  C: 'Candidate',
  S: 'Spouse of Candidate',
  W: 'Election Day Worker',
  E: 'Parliamentary Employee',
  L: 'Election Official',
  T: 'Travelling Out Of Country',
}

export const APPLICATION_IDS = [
  'OverseasVisitorPrd',
  'SpecialVoterPrd',
  'AdvancedPollerApplyPrd'
]

export const constituencies = {
  'New Providence': [
    "Bains & Grant Town",
    "Bamboo Town",
    "Carmichael",
    "Centreville",
    "Elizabeth",
    "Englerston",
    "Fort Charlotte",
    "Fox Hill",
    "Garden Hills",
    "Golden Gates",
    "Golden Isles",
    "Killarney",
    "Marathon",
    "Mount Moriah",
    "Nassau Village",
    "Pinewood",
    "Saint Anne's",
    "Saint Barnabas",
    "Seabreeze",
    "South Beach",
    "Southern Shores",
    "Tall Pines",
    "Yamacraw",
  ],

  'Grand Bahama': [
    "Central Grand Bahama",
    "East Grand Bahama",
    "Marco City",
    "Pineridge",
    "West Grand Bahama & Bimini",
  ],
  
  'Family Islands': [
    "West Grand Bahama & Bimini",
    "Central & South Abaco",
    "North Abaco",
    "Mangrove Cay & South Andros",
    "North Andros & The Berry Islands",
    "Cat Island / Rum Cay / San Salvador",
    "Central & South Eleuthera",
    "North Eleuthera",
    "The Exumas & Ragged Island",
    "Long Island",
    "Mayaguana/Inagua/Crooked Island/Acklins & Long Cay",
  ]
}
