import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelDance extends React.Component {
  resource = '/clementbetheldances'
  form_key = 'clementbetheldance'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Personal Info",
    {
        name: 'gender_status',
        label: 'Gender',
        options: ['Male','Female', 'Other'],
    },
    {
        name: 'First Name'
    },
    {
        name: 'Last Name',
    },
    {
        name: 'Email',
        type: 'email',
    },
    "::Additional Info",
    {
        name: 'island_deadline_date',
        label: 'Island Deadline Date',
        options: [
            'Abaco February 15th',
            'Acklins February 15th',
            'Andros April 1st',
            'Bimini May 1st',
            'Berry Islands May 1st',
            'Cat Island Feb 15th',
            'Crooked Island Feb 15th',
            'Eleuthera May 1st',
            'Exuma Feb 15th',
            'Grand Bahama April 1st',
            'Inagua Feb 15th',
            'New Providence March 1st',
            'Mayaguana Feb 15th',
            'San Salvador Feb 15th',
            'Rum Cay Feb 15th',
            'Ragged Island Feb 15th',
            'Long Island May 1st',
            'Other'
    ],
    
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.island_deadline_date !='Other',
    },
    {
        name: 'school_or_ensemble',
        label:'Name of school or Ensemble',
    },
    {
        name: 'number_of_male_and_female',
        label: 'If the entry is an Ensemble please provide the number of females and males',
        type: 'textarea',
    },
    {
        name: 'level',
        options: [
            'Primary PR ($5 Single $10 Group/Ensemble)',
            'Jr. High JH ($5 Single $10 Group/Ensemble)',
            'Sr. High SH ($5 Single $10 Group/Ensemble)',
            'Community COM ($10 Single $20 Group Ensemble)',
            'Pre-School PS ($5 Single $10 Group/Ensemble)',
            'Other'
        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.level !='Other',
    },
    {
        name: 'class_number',
        hint: 'This can be found in the national arts festival booklet'
    },
    {
        name: 'name_of_guardian',
        label: 'Name of Director/Teacher/Parent', 
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
