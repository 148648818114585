import React, { Fragment } from 'react'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import nationalities from '@constants/nationalities'
import { splitEvery, stringifyValues } from '@helpers/arrays'
import { multiUpload } from '@helpers/file-to-base64'
import { extractKeys } from '@helpers/objects'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'

const textlist = {
  type: 'textarea',
  hint: 'Separate each entry with a new line',
}

const splitByNewlines = str =>
  str
    .split('\n')
    .map(v => v.trim())
    .filter(v => v)

const calculateExpenditureForecast = form => () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const next = form.income_expenditure_forecast.map(row => {
        row.gross_profit = Number(row.sales) + Number(row.production)
        row.net_profit = row.gross_profit - row.other_expenses

        return row
      })

      resolve({ income_expenditure_forecast: next })
    }, 200)
  })

export default class ManufacturerApprovalRenewal extends React.Component {
  long_name = 'Manufacturer Approval Renewal'
  resource = '/moea_manufacturer_approvals'
  form_key = null
  c_key = 'moea_manufacturer_approval'
  free = false
  postpaid = false
  pausable = true

  skip_location = true
  hide_buttons = false
  multiple_uploads = true
  disable_fpo = true
  disable_pending_check = true

  approvedProducts = []

  async componentDidMount() {
    const { data } = await $app.axios.get('/moea_product_approvals', {
      page: 1,
      per_page: 100,
    })

    this.approvedProducts = data.records
      .filter(r => r.application_decision == 'approved')
  }

  defaults = {
    renewal: true,
    // planned_investment_analysis: [
    //   {
    //     item: 'Main Productive Equipment',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Workshop & Laboratory Equipment',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Accessories & Tools',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Internal Transport & Handling',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Spares & Inventories',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Land: factory & other buildings',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Furniture, fitting, office, factory stores etc.',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Power supply, electrification',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Water Supply',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Effluent & Waste Disposal',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Erection',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Vehicles',
    //     local: '',
    //     foreign: '',
    //   },
    //   {
    //     item: 'Others',
    //     local: '',
    //     foreign: '',
    //   },
    // ],

    // employment_local_wages: [
    //   {
    //     grade: 'Administrative',
    //     shift_1_no: '',
    //     shift_1_wage: '',
    //     shift_2_no: '',
    //     shift_2_wage: '',
    //   },
    //   {
    //     grade: 'Technical',
    //     shift_1_no: '',
    //     shift_1_wage: '',
    //     shift_2_no: '',
    //     shift_2_wage: '',
    //   },
    //   {
    //     grade: 'Supervisory',
    //     shift_1_no: '',
    //     shift_1_wage: '',
    //     shift_2_no: '',
    //     shift_2_wage: '',
    //   },
    //   {
    //     grade: 'Skilled',
    //     shift_1_no: '',
    //     shift_1_wage: '',
    //     shift_2_no: '',
    //     shift_2_wage: '',
    //   },
    //   {
    //     grade: 'Semi-Skilled',
    //     shift_1_no: '',
    //     shift_1_wage: '',
    //     shift_2_no: '',
    //     shift_2_wage: '',
    //   },
    //   {
    //     grade: 'Unskilled',
    //     shift_1_no: '',
    //     shift_1_wage: '',
    //     shift_2_no: '',
    //     shift_2_wage: '',
    //   },
    // ],

    // consumption_indices: [
    //   {
    //     item: 'Consultants, preliminary tests, & feasibility studies',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Plant, equipment, and inventories',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Royalties & Licence Fees',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Erection, management and supervision',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Foreign Staff commitments',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Technical services',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Materials for plant operation',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Depreciation',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Profits and dividends',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    //   {
    //     item: 'Loan payments including interest',
    //     annual_amount: '',
    //     liability_period: '',
    //     remarks: '',
    //   },
    // ],

    // DUMMY DATA
    acceptance: true,
    pickup_location: '',
    pickup_sub_location: '',
    planned_investment_analysis: [
      {
        item: 'Main Productive Equipment',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Workshop & Laboratory Equipment',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Accessories & Tools',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Internal Transport & Handling',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Spares & Inventories',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Land: factory & other buildings',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Furniture, fitting, office, factory stores etc.',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Power supply, electrification',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Water Supply',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Effluent & Waste Disposal',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Erection',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Vehicles',
        local: '0',
        foreign: '0',
      },
      {
        item: 'Others',
        local: '0',
        foreign: '0',
      },
    ],
    employment_local_wages: [
      {
        grade: 'Administrative',
        shift_1_no: '20',
        shift_1_wage: '25.75',
        shift_2_no: '40',
        shift_2_wage: '25.75',
      },
      {
        grade: 'Technical',
        shift_1_no: '40',
        shift_1_wage: '25.75',
        shift_2_no: '40',
        shift_2_wage: '25.75',
      },
      {
        grade: 'Supervisory',
        shift_1_no: '40',
        shift_1_wage: '25.75',
        shift_2_no: '40',
        shift_2_wage: '25.75',
      },
      {
        grade: 'Skilled',
        shift_1_no: '30',
        shift_1_wage: '30.00',
        shift_2_no: '40',
        shift_2_wage: '30.00',
      },
      {
        grade: 'Semi-Skilled',
        shift_1_no: '35',
        shift_1_wage: '24',
        shift_2_no: '35',
        shift_2_wage: '24',
      },
      {
        grade: 'Unskilled',
        shift_1_no: '40',
        shift_1_wage: '20',
        shift_2_no: '40',
        shift_2_wage: '20',
      },
    ],
    consumption_indices: [
      {
        item: 'Gasoline (Gallons)',
        annual_amount: '32000',
        liability_period: '1 month',
        remarks: 'None',
      },
    ],
    product_name: 'TEST PRODUCT',
    production_method: 'TEST PRODUCT METHOD',
    client_type: 'individual',
    approved_products: [
      {
        product_name: 'Product Name',
        production_method: 'Product Method',
      },
    ],
    raw_materials: [
      {
        name: 'Raw Name',
        source: 'Raw Source',
      },
    ],
    capital_labour_ratio: '1.0',
    feasibility: '1.0',
    occupation: 'Software Dev',
    street_address: 'test street',
    company_name: '',
    company_type: '',
    trading_name: '',
    bl_number: '',
    tin_number: '',
    business_nib: '',
    place_of_business: 'TEST STREET',
    factory_location_match: false,
    factory_location: 'TEST FACTORY PLACE',
    po_box: 'N0001',
    phone_number: '(242) 555-5555',
    directors: [
      {
        first_name: 'FIRST',
        last_name: 'DIRECTOR',
        nationality: 'Albanian',
      },
    ],
    officers: [
      {
        first_name: 'FIRST',
        last_name: 'OFFICER',
        job_title: 'FOREMAN',
        nationality: 'Algerian',
      },
    ],
    shareholders: [
      {
        first_name: 'ONLY',
        last_name: 'SHAREHOLDER',
        shares_held_percentage: '40',
      },
    ],
    commercial_references: 'Scotia',
    number_of_production_workers: '40',
    number_of_administrators: '5',
    skills_required: 'Welding\nMasonry',
    training_programmes: 'Test Programme\nTest Programme 2',
    management_tech_qualifications:
      "Bachelor's Degree\nForeman Licence\nForklift Certified",
    local_market_price_policy: [
      {
        percentage: '45',
        market_type: 'Local',
      },
      {
        percentage: '25',
        market_type: 'Foreign',
      },
    ],
    product_disposition: [
      {
        product_name: 'TEST DISPOSITION',
        percentage: '50',
        market_type: 'local',
      },
    ],
    product_distribution: [
      {
        product_name: 'TEST DISTRIBUTION',
        percentage: '50',
        distributor: 'wholesaler',
      },
    ],
    capital_structure: [
      {
        capital_type: 'equity',
        percentage: '50',
        market_type: 'local',
      },
    ],
    cost_of_production: [
      {
        amount: '50000',
      },
      {
        amount: '25000',
      },
      {
        amount: '60000',
      },
      {
        amount: '200000',
      },
      {
        amount: 335000,
      },
    ],
    utilities: [
      {
        amount: '50000',
      },
      {
        amount: '15000',
      },
      {
        amount: '10000',
      },
      {
        amount: '5000',
      },
    ],
    income_expenditure_forecast: [
      {
        sales: '12',
        production: '22',
        gross_profit: 34,
        other_expenses: '1',
        net_profit: 33,
      },
      {
        sales: '13',
        production: '23',
        gross_profit: 36,
        other_expenses: '2',
        net_profit: 34,
      },
      {
        sales: '14',
        production: '24',
        gross_profit: 38,
        other_expenses: '3',
        net_profit: 35,
      },
      {
        sales: '15',
        production: '25',
        gross_profit: 40,
        other_expenses: '4',
        net_profit: 36,
      },
      {
        sales: '16',
        production: '26',
        gross_profit: 42,
        other_expenses: '5',
        net_profit: 37,
      },
    ],
    factory_size: '5000',
    factory_building_type: 'Concrete and Pre-Fab Steel',
    special_location_reqs: 'REQ. 1\nREQ. 2',
    obnoxious_conditions_other: '',
    environment_measures: 'No',
    undefined: '',
    bank_references: [
      {
        description: 'Bank Letter',
      },
    ],
    additional_comments: 'None at this time',
    principal_exec_f_name: 'Principal',
    principal_exec_l_name: 'Executive',
    principal_exec_title: 'CEO',
    project_consultant_f_name: 'Project',
    project_consultant_l_name: 'Consultant',
    project_consultant_address: 'Out West, Lyford Cay',
    project_name: 'Ultra Project',
    location_planned: 'Test Location',
    facility_site: '200 Acres',
    est_project_period: '28 months',
    products_planned: [
      {
        product_name: 'Ultra Comfort Rug',
        unit: '50000',
        unit_value: '500',
        local_quantity: '20000',
        local_value: '650',
        foreign_quantity: '30000',
        foreign_value: '900',
      },
    ],
    product_brand_names: 'ULTRA',
    production_sequence_desc:
      'Rugs made from Merino Wool to provide maximum comfort, strength, and longevity',
    planned_capital_structure: [
      {
        organization: 'Share Company',
        market_type: 'foreign',
        capital_type: 'project',
        amount: '250000',
      },
    ],
    equipment_exemption_list: [
      {
        desc: 'Forklift',
        qty: '3',
        cost: '60000',
      },
    ],
    operational_expenditure: [
      {
        item: 'Looms',
        unit: '1000',
        quantity: '1000',
        foreign_cost: '50',
        local_cost: '200',
      },
    ],
    assembly_raw_materials: [
      {
        item: 'Wool',
        unit: '2000',
        quantity: '2000',
        foreign_cost: '500',
        local_cost: '0',
      },
    ],
    packing_materials: [
      {
        item: 'Packing Wrap',
        unit: '1',
        quantity: '5000',
        foreign_cost: '2',
        local_cost: '4',
      },
    ],
    components: [
      {
        item: 'Component #1',
        unit: '1',
        quantity: '2500',
        foreign_cost: '4',
        local_cost: '5',
      },
      {
        item: 'Component #2',
        unit: '1',
        quantity: '3000',
        foreign_cost: '5',
        local_cost: '4',
      },
    ],
    immigration_facilities: [
      {
        staff_grade: 'Administative',
        residence_period_months: '36',
        reason: 'Overseeing construction and initial operations efforts',
      },
    ],
    anti_pollution_measures: [
      {
        effluent: 'Effluent',
        annual_unit: '0',
        quantity: '0',
        desc: 'None Needed',
      },
    ],
    electric_installed: true,
    electric_max_demand: '40000',
    electric_annual_kvp: '1',
    main_water_supply_net_consumption: '20000',
    concession_incentives: [
      {
        short_desc: 'Duty Free Import',
        reason: 'Equipment needed cannot be purchased locally',
      },
    ],
    main_production_machinery: '2nd Hand',
    main_production_machinery_percentage: '',
    joint_venture: [
      {
        equity_capital: '25',
        loan_capital: '25',
        machinery_contribution: '25',
      },
    ],
    erection_commissioning_cost: '20000',
    turnkey_contract_cost: '5000',
    management_contract: [
      {
        period: '3',
        months_years: 'Year(s)',
      },
    ],
    bahamians_in_training: '40',
    technical_servicing: [
      {
        period: '2',
        months_years: 'Year(s)',
      },
    ],
    product_number_specification: '#242-8765',
    assembly_additional_comments: 'None at this time',
    declare: true,
    proxy: {
      use: null,
      nib: '',
    },
  }

  canned = {
    'Agency Request for more information': `
      Dear Client,
      <br />
      Your Industries Encouragement Act application requires additional
      information and/or documentation to advance. Please submit a copy
      of your (valid business licence) via email:
      <a href='mailto:financialservices@bahamas.gov.bs'>financialservices@bahamas.gov.bs</a>
      or if we can be of further assistance to you, call our office at
      <a href='tel:2423979922'>(242) 397-9922</a>.
    `,
  }

  hide_buttons = {
    email: () =>
      $app.hasAnyRole('moea_secreatary', 'moea_director', 'moea_minister'),
    status: true,
    deny: true,
  }

  custom_actions = [
    {
      text: 'Submit to Director',
      icon: 'user-tie',
      roles: ['moea_processing'],
      // test: r => r.application_decision == 'pending',
      fn: async record => {
        const { props, resource, c_key } = this
        const { history, location } = props
        const { axios } = $app

        const { value: license_numb } = await Swal.fire({
          icon: 'question',
          input: 'text',
          text: `Please enter the applicant's New Licence #`,
          inputPlaceholder: 'Licence #',
          showCancelButton: true,
          confirmButtonText: 'Set Licence #',
        })

        if (!license_numb) return
        this.setState({ loading: true })

        try {
          await axios.put(resource + '/update_application', {
            [c_key]: {
              license_numb,
              form_num: record.form_num,
              application_decision: 'processing',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'processing'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          Swal.fire(
            'Success!',
            'The application has been submitted to the Secretary for review',
            'success'
          )
        } catch (error) {
          console.error(error)
          Swal.fire(
            'Oops...',
            'There has been an error with processing your application',
            'error'
          )
        }
      },
    },
    {
      text: 'Defer to FAS',
      icon: 'chevron-left',
      roles: [['moea_secretary', 'moea_director', 'moea_minister']],
      fn: async record => {
        const { props } = this

        const { value: reason, isDismissed } = await Swal.fire({
          input: 'textarea',
          type: 'question',
          title: 'Refer Back To FAS',
          inputLabel: 'Please enter a note for the FAS (Optional)',
          showCancelButton: true,
          confirmButtonText: 'Defer',
        })

        if (isDismissed) return

        try {
          await $app.axios.post('/services/notes/add_note', {
            note: {
              internal_view_only: true,
              notable_type: props.service.type,
              notable_id: record.id,
              note_type: 'note',
              // prettier-ignore
              text: `
                SYSTEM: Application Deferred to First Assistant Secretary by ${fullName($app.current_user)}.
                ${reason ? `Reason For Deferral:\n${reason}` : ''}
              `,
            },
          })
        } catch (err) {
          console.error(err)
        }
      },
    },
    {
      text: 'Recommend to Minister',
      icon: 'user-tie',
      roles: [['moea_director', 'moea_secretary']],
      test: r => r.license_numb,
      fn: async record => {
        const { props, resource, c_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_application', {
            [c_key]: {
              form_num: record.form_num,
              application_decision: 'processing',
            },
          })

          await $app.axios.post('/services/notes/add_note', {
            note: {
              internal_view_only: true,
              notable_type: props.service.type,
              notable_id: record.id,
              note_type: 'note',
              // prettier-ignore
              text: `
                SYSTEM: Application Recommended for Approval to Minister by ${fullName($app.current_user)}
              `,
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'processing'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          Swal.fire(
            'Success!',
            'The application has been recommended to the Minister for final approval',
            'success'
          )
        } catch (error) {
          console.error(error)
          Swal.fire(
            'Oops...',
            'There has been an error with processing your application',
            'error'
          )
        }
      },
    },
    // {
    //   text: 'Send to Public Notice',
    //   icon: 'scroll',
    //   roles: ['moea_secretary']
    // },
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['moea_minister'],
      test: r => r.license_numb,
      fn: async record => {
        const { resource, c_key } = this
        const { isDismissed } = await Swal.fire({
          title: 'Approve Application',
          text: `
            Are you sure that you would like to approve this application?
            By clicking "Confirm", you are acknowleding that you have reviewed this
            application and are satisfied with its contents.
          `,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        })

        if (isDismissed) return

        const { props } = this
        const { history, location } = props
        const { axios } = $app

        try {
          await axios.put(resource + '/update_application', {
            [c_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'approved'

            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success('Success', 'Application successfully approved')
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to approve application')
          return
        }

        this.setState({ loading: false })
      },
    },
    {
      text: 'Deny Application',
      icon: 'ban',
      roles: ['moea_minister'],
      fn: async record => {
        const { resource, c_key } = this
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Deny Application',
          text: 'Are you sure you want to deny this application?',
          showCancelButton: true,
          confirmButtonColor: '#C00',
          confirmButtonText: 'Deny',
        })

        if (isDismissed) return
        this.setState({ loading: true })

        const { props } = this
        const { history, location } = props
        const { axios } = $app

        try {
          await axios.put(resource + '/update_application', {
            [c_key]: {
              form_num: record.form_num,
              application_decision: 'denied',
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'denied'

            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success('Success', 'Application successfully denied')
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to deny application')
          return
        }

        this.setState({ loading: false })
      },
    },
  ]

  read_params = {
    admin: true,
    label: 'Product Status',
    default: 'Pending',
    values: {
      'Pending': {
        status: 'pending',
        product_status: 'pending',
      },
      'Approved': {
        status: 'approved',
        product_status: 'approved',
      },
      'Processing': {
        status: 'processing',
        product_status: 'processing',
      },
      'Public Notice': {
        status: 'public notice',
        product_status: 'public notice',
      },
    },
  }

  // before_apply = (records) => {
  //   const existing = records
  //     ?.find(f => /^(app|pen|pro)/.test(f.application_decision))

  //   if (!existing) return true

  //   Swal.fire(
  //     `Application Already ${existing.application_decision.initialCaps()}`,
  //     ``,
  //     'warning'
  //   )

  //   return null
  // }

  validate = async form => {
    console.log(form)

    const {
      // Shared by each sub form
      occupation,
      client_type,
      company_name,
      company_type,
      trading_name,
      bl_number,
      tin_number,
      business_nib,
      po_box,
      phone_number,

      // For product approval
      acceptance,
      product_name,
      production_method,
      capital_labour_ratio,
      raw_materials,
      equipment_exemption_list,
      product_disposition,
      product_distribution,
      feasibility,
      declare,

      // For product assembly
      principal_exec_f_name,
      principal_exec_l_name,
      principal_exec_title,
      project_consultant_f_name,
      project_consultant_l_name,
      project_consultant_address,
      project_name,
      location_planned,
      facility_site,
      facilities_needed,
      facilities_needed_other,
      est_project_period,
      products_planned,
      product_brand_names,
      production_sequence_desc,
      planned_capital_structure,
      planned_investment_analysis,
      operational_expenditure,
      assembly_raw_materials,
      packing_materials,
      components,
      immigration_facilities,
      employment_local_wages,
      anti_pollution_measures,
      electric_installed,
      electric_max_demand,
      electric_annual_kvp,
      main_water_supply_net_consumption,
      consumption_indices,
      concession_incentives,
      main_production_machinery,
      joint_venture,
      erection_commissioning_cost,
      turnkey_contract_cost,
      management_contract,
      bahamians_in_training,
      technical_servicing,
      product_number_specification,
      assembly_additional_comments,

      // Form values to be transformed/spread
      cost_of_production,
      bank_references,
      utilities,
      income_expenditure_forecast,
      directors,
      officers,
      shareholders,
      local_market_price_policy,
      capital_structure,
      skills_required,
      training_programmes,
      special_location_reqs,
      obnoxious_conditions,
      commercial_references,
      business_license_upload,
      tax_comp_cert_upload,
      incorporation_cert_upload,
      business_plan_upload,
      other_uploads,
      ...rest
    } = form

    const shared = {
      // Shared by each sub form
      occupation,
      client_type,
      company_name,
      company_type,
      trading_name,
      bl_number,
      tin_number,
      business_nib,
      po_box,
      phone_number,
    }

    const multiple_uploads = [
      JSON.stringify(
        await multiUpload(
          'Business Licence',
          'Business Licence for verification',
          business_license_upload
        )
      ),
      JSON.stringify(
        await multiUpload(
          'Tax Compliance Certificate',
          'Tax Compliance Certificate for verification',
          tax_comp_cert_upload
        )
      ),
      JSON.stringify(
        await multiUpload(
          'Incorporation Certificate',
          'Incorporation Certificate for verification',
          incorporation_cert_upload
        )
      ),
      JSON.stringify(
        await multiUpload(
          'Business Plan',
          'Business Plan for review',
          business_plan_upload
        )
      ),
    ]

    for (let holder of shareholders) {
      const name = fullName(holder, false)

      multiple_uploads.push(
        JSON.stringify(
          await multiUpload(
            `NIB Upload: ${name}`,
            `Upload of NIB card for shareholder ${name}`,
            holder.nib_upload
          )
        ),
        JSON.stringify(
          await multiUpload(
            `Passport Upload: ${name}`,
            `Upload of Passport for shareholder ${name}`,
            holder.passport_upload
          )
        )
      )
    }

    for (let ref of bank_references) {
      multiple_uploads.push(
        JSON.stringify(
          await multiUpload(ref.description, ref.description, ref.file_upload)
        )
      )
    }

    // Join additional facilities
    if (facilities_needed.has('Other')) {
      facilities_needed.delete('Other')

      for (let f of facilities_needed_other.split('\n')) {
        facilities_needed.add(f)
      }
    }

    // prettier-ignore
    return {
      __OVERWRITE__: true,
      __TRANSFORM__: false,
      moea_manufacturer_approval: {
        ...rest,
        ...shared,
        declare,
        feasibility,
        multiple_uploads,
        capital_labour_ratio,
        land_cost: cost_of_production[0].amount,
        building_cost: cost_of_production[1].amount,
        machinery_cost: cost_of_production[2].amount,
        working_capital: cost_of_production[3].amount,
        electricity_cost: utilities[0].amount + ' kWh',
        other_fuel_cost: utilities[1].amount + ' kWh',
        water_cost: utilities[2].amount + ' Gallons',
        sewage_cost: utilities[3].amount + ' Gallons',
        product_disposition: stringifyValues(product_disposition),
        product_distribution: stringifyValues(product_distribution),
        directors: stringifyValues(directors),
        officers: stringifyValues(officers),
        shareholders: stringifyValues(shareholders
          .map(({ nib_upload, passport_upload, ...rest }) => rest)
        ),
        local_market_price_policy: stringifyValues(local_market_price_policy),
        capital_structure: stringifyValues(capital_structure),
        training_programmes: splitByNewlines(training_programmes),
        special_location_reqs: splitByNewlines(special_location_reqs),
        obnoxious_conditions: [...obnoxious_conditions],
        skills_required: splitByNewlines(skills_required),
        commercial_references: splitByNewlines(commercial_references),
        // Need to spread out values in table to match shape expected by API
        income_expenditure_forecast: income_expenditure_forecast
          .reduce((arr, row, i) => {
            for (let [k, v] of Object.entries(row)) {
              arr.push({
                [k]: String(v),
                time_period_year: String(i + 1),
              })
            }
    
            return arr
          }, []).map(r => JSON.stringify(r)),
      },
      moea_product_approval: {
        ...shared,
        acceptance,
        product_name,
        production_method,
        capital_labour_ratio,
        raw_materials: stringifyValues(raw_materials),
        equipment_exemption_list: stringifyValues(equipment_exemption_list),
        product_disposition: stringifyValues(product_disposition),
        product_distribution: stringifyValues(product_distribution),
        feasibility,
        declare,
      },
      moea_product_assembly: {
        ...shared,
        project_consultant_f_name,
        project_consultant_l_name,
        project_consultant_address,
        project_name,
        location_planned,
        facility_site,
        facilities_needed: [...facilities_needed],
        est_project_period,
        products_planned: stringifyValues(products_planned),
        product_brand_names: product_brand_names.replaceAll('\n', ', '),
        production_sequence_desc,
        planned_capital_structure: stringifyValues(planned_capital_structure),
        planned_investment_analysis: stringifyValues(planned_investment_analysis),
        operational_expenditure: stringifyValues(operational_expenditure),
        raw_materials: stringifyValues(assembly_raw_materials),
        packing_materials: stringifyValues(packing_materials),
        components: stringifyValues(components),
        immigration_facilities: stringifyValues(immigration_facilities),
        employment_local_wages: stringifyValues(employment_local_wages),
        anti_pollution_measures: stringifyValues(anti_pollution_measures),
        electric_installed,
        electric_max_demand,
        electric_annual_kvp,
        main_water_supply_net_consumption,
        consumption_indices: stringifyValues(consumption_indices),
        concession_incentives: stringifyValues(concession_incentives),
        main_production_machinery,
        joint_venture: stringifyValues(joint_venture),
        erection_commissioning_cost,
        turnkey_contract_cost,
        management_contract: stringifyValues(management_contract),
        bahamians_in_training,
        technical_servicing: stringifyValues(technical_servicing),
        product_number_specification,
        additional_comments: assembly_additional_comments,
      }
    }
  }

  fields = (form, viewing) => {
    const list = [
      {
        name: 'renewal',
        save: true,
        hide: true,
      },
      {
        name: 'client_type',
        save: true,
        hide: true,
      },

      {
        heading: (
          <h4 className='form-group text-center'>
            Form 1: Product Approval Form
          </h4>
        ),
        view: false,
      },

      // {
      //   name: 'approved_products',
      //   label: 'Products',
      //   columns: ['product_name', 'production_method'],
      // },
      {
        name: 'product_name',
        view: false,
      },
      {
        name: 'production_method',
        view: false,
      },
      {
        name: 'raw_materials',
        label: 'List Raw Materials',
        columns: ['name', 'source'],
        view: false,
      },
      {
        name: 'capital_labour_ratio',
        label: 'Ratio of Capital To Labour',
        is: 'float',
        hint: 'Please approximate',
        view: false,
      },
      {
        name: 'feasibility',
        hint: 'Please approximate',
        view: false,
      },
      {
        name: 'product_disposition',
        view: false,
        columns: [
          {
            name: 'product_name',
            options: this.approvedProducts.map(p => p.product_name),
          },
          { name: 'percentage', is: 'float' },
          { name: 'market_type', options: ['local', 'foreign'] },
        ],
      },
      {
        name: 'product_distribution',
        view: false,
        columns: [
          {
            name: 'product_name',
            options: this.approvedProducts.map(p => p.product_name),
          },
          { name: 'percentage', is: 'float' },
          {
            name: 'distributor',
            options: ['factory agent', 'wholesaler', 'retailer'],
          },
        ],
      },

      '::SPACER',
      {
        heading: (
          <h4 className='form-group text-center'>
            Form 2: Manufacturer Approval Form
          </h4>
        ),
        view: false,
      },

      '::Applicant Details',
      {
        name: 'occupation',
        hide: form.client_type == 'business',
      },
      {
        name: 'street_address',
        hide: form.client_type == 'business',
      },

      ...BUSINESS_FIELDS(form),

      '::Address Details',
      {
        name: 'place_of_business',
        // hide: form.client_type == 'individual',
        callback: value => {
          console.log(value)
          if (form.factory_location_match) {
            return {
              factory_location: value,
              location_planned: value,
            }
          }
        },
      },
      {
        name: 'factory_location_match',
        type: 'checkbox',
        label: 'The factory and business location are the same',
        required: false,
        callback: bool => ({
          factory_location: bool ? form.place_of_business : '',
          location_planned: bool ? form.place_of_business : '',
        }),
      },
      {
        name: 'factory_location',
        // hide: form.client_type == 'individual',
        disabled: form.factory_location_match,
        callback: value => {
          if (!form.factory_location_match) return

          return { location_planned: value }
        },
      },
      {
        name: 'po_box',
        label: 'P. O. Box',
      },
      {
        name: 'phone_number',
        type: 'phone',
      },

      '::Management & Stakeholders',
      {
        name: 'directors',
        columns: [
          'first_name',
          'last_name',
          {
            name: 'nationality',
            options: nationalities,
          },
        ],
      },
      {
        name: 'officers',
        columns: [
          {
            name: 'first_name',
            width: 208,
          },
          {
            name: 'last_name',
            width: 208,
          },
          {
            name: 'job_title',
            width: 208,
          },
          {
            name: 'nationality',
            options: nationalities,
            width: 'auto',
          },
        ],
      },
      {
        name: 'shareholders',
        columns: [
          'first_name',
          'last_name',
          {
            name: 'shares_held_percentage',
            label: '% of Shares Held',
            // prettier-ignore
            callback: () => setTimeout(() => {
              const total = form.shareholders.reduce((sum, holder) =>
                sum + Number(holder.shares_held_percentage)
              , 0)
  
              if (total == 100) {
                this.setState({ shareholders: true })
                return
              } else if (!document.activeElement?.closest('[data-field="shareholders"]')) {
                toastr.warning(
                  'Share Percentages Incomplete',
                  'Share portions currently entered not amounting to 100%'
                )
              }
              
              this.setState({ shareholders: false })
            }, 1000),
          },
          {
            name: 'nib_upload',
            label: 'NIB Upload',
            type: 'file',
            view: false,
          },
          {
            name: 'passport_upload',
            label: 'Passport Upload',
            type: 'file',
            view: false,
          },
        ],
        view: {
          label: 'Shareholders (Documents in Uploads section below)',
        },
      },
      {
        name: 'bank_references',
        label: 'Commercial / Bank References',
        view: false,
        columns: [
          'description',
          {
            name: 'file_upload',
            type: 'file',
          },
        ],
      },

      '::Personnel Details',
      {
        name: 'number_of_production_workers',
        is: 'integer',
      },
      {
        name: 'number_of_administrators',
        is: 'integer',
      },
      {
        name: 'skills_required',
        ...textlist,
      },
      {
        name: 'training_programmes',
        ...textlist,
      },
      {
        name: 'management_tech_qualifications',
        label: 'Technical Qualifications Of Management',
        format: value => value.replaceAll('\n', ', '),
        view: {
          value: v => v.replaceAll('\n', ', '),
        },
        ...textlist,
      },

      {
        name: 'local_market_price_policy',
        columns: [
          { name: 'percentage', is: 'float' },
          { name: 'market_type', options: ['Local', 'Foreign'] },
        ],
      },

      '::Financing Details',
      {
        name: 'capital_structure',
        columns: [
          {
            name: 'capital_type',
            options: [
              { label: 'Equity', value: 'equity' },
              { label: 'Debenture', value: 'debenture' },
              { label: 'Other Loans', value: 'other loans' },
            ],
          },
          { name: 'percentage', is: 'float' },
          { name: 'market_type', options: ['local', 'foreign'] },
        ],
      },
      {
        name: 'cost_of_production',
        label: 'Cost Of Production',
        firstColumnLabel: 'Category',
        labelRowWidth: 384,
        rows: [
          'Land',
          'Building',
          'Machinery & Equipment',
          'Working Capital',
          'Total Investment',
        ],
        columns: [
          {
            name: 'amount',
            label: 'Amount ($)',
            type: 'currency',
            // prettier-ignore
            callback: () => new Promise((resolve, reject) => {
              setTimeout(() => {
                const total = form.cost_of_production
                  .slice(0, 4)
                  .reduce((sum, { amount }) => sum + Number(amount), 0)
  
                const cost_of_production = [
                  ...form.cost_of_production.slice(0, 4),
                  { amount: total },
                ]
  
                resolve({ cost_of_production })
              }, 100)
            }),
          },
        ],
        view: {
          element: value => (
            <Fragment>
              <h5 className='sub'>Costs Of Production</h5>
              <div className='form-wizard-form-table table-responsive'>
                <table className='table table-striped table-striped-bg-black'>
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Land</td>
                      <td>$ {Number(form.land_cost).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Building</td>
                      <td>$ {Number(form.building_cost).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Machinery & Equipment</td>
                      <td>$ {Number(form.machinery_cost).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Working Capital</td>
                      <td>$ {Number(form.working_capital).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total Investment</strong>
                      </td>
                      <td>
                        <strong>
                          ${' '}
                          {[
                            'land_cost',
                            'building_cost',
                            'machinery_cost',
                            'working_capital',
                          ]
                            .reduce((sum, prop) => sum + Number(form[prop]), 0)
                            .toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>
          ),
        },
      },
      {
        name: 'utilities',
        label: 'Estimated Utility Usage (Per Annum.)',
        firstColumnLabel: 'Utility',
        labelRowWidth: 384,
        rows: [
          'Electricity (kWh)',
          'Other Fuel (kWh)',
          'Water (Gal.)',
          'Sewerage (Gal.)',
        ],
        columns: [
          {
            name: 'amount',
            label: 'Amount Per Annum.',
            is: 'float',
          },
        ],
        view: {
          element: () => (
            <Fragment>
              <h5 className='sub'>Utility Usage Per Annum.</h5>
              <div className='form-wizard-form-table table-responsive'>
                <table className='table table-striped table-striped-bg-black'>
                  <thead>
                    <tr>
                      <th>Utility</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Electricity</td>
                      <td>{form.electricity_cost}</td>
                    </tr>
                    <tr>
                      <td>Other Fuel</td>
                      <td>{form.other_fuel_cost}</td>
                    </tr>
                    <tr>
                      <td>Water</td>
                      <td>{form.water_cost}</td>
                    </tr>
                    <tr>
                      <td>Sewerage</td>
                      <td>{form.sewage_cost}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>
          ),
        },
      },
      {
        name: 'income_expenditure_forecast',
        labelRowWidth: 72,
        rows: ['year_1', 'year_2', 'year_3', 'year_4', 'year_5'],
        columns: [
          {
            name: 'sales',
            is: 'currency',
            callback: calculateExpenditureForecast(form),
          },
          {
            name: 'production',
            is: 'currency',
            callback: calculateExpenditureForecast(form),
          },
          {
            name: 'gross_profit',
            is: 'currency',
            callback: calculateExpenditureForecast(form),
          },
          {
            name: 'other_expenses',
            is: 'currency',
            callback: calculateExpenditureForecast(form),
          },
          {
            name: 'net_profit',
            is: 'currency',
            callback: calculateExpenditureForecast(form),
          },
        ],
        view: {
          element: value => (
            <Fragment>
              <h5 className='sub'>Income Expenditure Forecast</h5>
              <div className='form-wizard-form-table table-responsive'>
                <table className='table table-striped table-striped-bg-black'>
                  <thead>
                    <tr>
                      <th>Year</th>
                      <th>Sales</th>
                      <th>Production</th>
                      <th>Gross Profit</th>
                      <th>Other Expenses</th>
                      <th>Net Profit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {splitEvery(value, 5).map((items, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>${Number(items[0].sales).toFixed(2)}</td>
                        <td>${Number(items[1].production).toFixed(2)}</td>
                        <td>${Number(items[2].gross_profit).toFixed(2)}</td>
                        <td>${Number(items[3].other_expenses).toFixed(2)}</td>
                        <td>${Number(items[4].net_profit).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Fragment>
          ),
        },
      },

      '::Factory & Physical Considerations',
      {
        name: 'factory_size',
        hint: '(Square ft.)',
        view: {
          value: v => v + ' sq. ft.',
        },
      },
      {
        name: 'factory_building_type',
        multi: true,
        options: [
          'Concrete',
          'Heavy Timber',
          'Wood Frame',
          'Prefabricated',
          'Other',
        ],
      },
      {
        name: 'special_location_reqs',
        label: 'Special Requirements',
        hint: 'Please let any facilities or conditions that will be required for this project',
        ...textlist,
      },
      {
        name: 'obnoxious_conditions',
        type: 'checklist',
        options: ['none', 'dust', 'noise', 'smoke', 'other'],
        view: {
          value: v => v.map(str => str.initialCaps()).join(', '),
        },
      },
      {
        name: 'obnoxious_conditions_other',
        label: 'Other Conditions',
        hide: !form.obnoxious_conditions?.has?.('other'),
      },
      {
        name: 'environment_measures',
        type: 'textarea',
        hint: 'Please describe any measures that will be taken to mitigate the obnoxious conditions',
        hide: form.obnoxious_conditions?.has?.('none'),
      },

      {
        heading: 'Uploads',
        view: false,
      },
      {
        name: 'business_license_upload',
        label: 'Business Licence',
        type: 'file:pdf',
        desc: 'Business Licence for verification',
        view: false,
      },
      {
        name: 'business_nib_upload',
        label: 'Business NIB Certificate',
        type: 'file:pdf',
        view: false,
      },
      {
        name: 'bia_approval_upload',
        label: 'Bahamas Investment Authority Approval',
        required: false,
        type: 'file:pdf',
        hint: 'Required for non Bahamian principals/shareholders',
        view: false,
      },
      {
        name: 'tax_comp_cert_upload',
        label: 'Tax Compliance Certificate',
        type: 'file:pdf',
        desc: 'Tax compliance certificate for verification',
        view: false,
      },
      {
        name: 'incorporation_cert_upload',
        label: 'Incorporation Certificate',
        type: 'file:pdf',
        desc: 'Certificate of Incorporation for verification',
        view: false,
      },
      {
        name: 'business_plan_upload',
        label: 'Business Plan',
        type: 'file:pdf',
        desc: 'Copy of Business Plan',
        view: false,
      },
      {
        heading: '<span>&nbsp;</span>',
        view: false,
      },
      {
        name: 'additional_comments',
        type: 'textarea',
        required: false,
      },
      {
        name: 'linked_products',
        hide: true,
        view: {
          element: () => {
            const viewProduct = product => () => {
              this.props.history.push('/moea/product-approval', {
                view: {
                  ...product,
                  ...extractKeys(
                    form,
                    'client_type',
                    'occupation',
                    'street_address',
                    'company_name',
                    'company_type',
                    'trading_name',
                    'bl_number',
                    'tin_number',
                    'business_nib'
                  ),
                },
              })
            }

            return (
              <Fragment>
                <h5 className='sub'>Linked Products (Form 2)</h5>
                <div className='form-wizard-form-table table-responsive'>
                  <table className='table table-striped table-striped-bg-black'>
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {form.moea_product_approvals_list.map(f => (
                        <tr>
                          <td>
                            <span
                              className='text-blue-600 cursor-pointer'
                              onClick={viewProduct(f)}
                            >
                              {f.product_name}
                            </span>
                          </td>
                          <td>
                            {new Date(f.created_at).toISOString().slice(0, 10)}
                          </td>
                          <td>{f.application_decision.initialCaps()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <span className='block -mt-6 mb-4 text-muted'>
                  (Click name to view details)
                </span>
              </Fragment>
            )
          },
        },
      },
      {
        name: 'linked_assemblies',
        label: 'Linked Product Assemblies (Form 3)',
        hide: true,
        view: {
          element: () => {
            const viewAssembly = assembly => () => {
              this.props.history.push('/moea/product-assembly', {
                view: {
                  ...assembly,
                  ...extractKeys(
                    form,
                    'client_type',
                    'occupation',
                    'street_address',
                    'company_name',
                    'company_type',
                    'trading_name',
                    'bl_number',
                    'tin_number',
                    'business_nib'
                  ),
                },
              })
            }

            return (
              <Fragment>
                <h5 className='sub'>Linked Assemblies (Form 3)</h5>
                <div className='form-wizard-form-table table-responsive'>
                  <table className='table table-striped table-striped-bg-black'>
                    <thead>
                      <tr>
                        <th>Created At</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {form.moea_product_assemblies_list.map(f => (
                        <tr
                          onClick={viewAssembly(f)}
                          className='cursor-pointer'
                        >
                          <td>
                            {new Date(f.created_at).toISOString().slice(0, 10)}
                          </td>
                          <td>{f.application_decision.initialCaps()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <span className='block -mt-6 mb-4 text-muted'>
                  (Click row to view details)
                </span>
              </Fragment>
            )
          },
        },
      },
    ]

    if (!viewing) {
      list.push(
        '::SPACER',
        {
          heading: (
            <h4 className='form-group text-center'>
              Form 3: Product Assembly Form
            </h4>
          ),
          view: {
            heading: 'Form 3: Product Assembly Form',
          },
        },

        '::Principal Executive Sponsor',
        {
          name: 'principal_exec_f_name',
          label: 'First Name',
        },
        {
          name: 'principal_exec_l_name',
          label: 'Last Name',
        },
        {
          name: 'principal_exec_title',
          label: 'Job Title',
        },

        '::Project Consultant',
        {
          name: 'project_consultant_f_name',
          label: 'First Name',
        },
        {
          name: 'project_consultant_l_name',
          label: 'Last Name',
        },
        {
          name: 'project_consultant_address',
          label: 'Address',
        },

        '::Project Details',
        {
          name: 'project_name',
          label: 'Proposed Project Name',
        },
        'location_planned',
        {
          name: 'facility_site',
          label: 'Site Area Needed',
        },
        {
          name: 'facilities_needed',
          type: 'checklist',
          options: ['Electricity', 'Water', 'Other'],
        },
        {
          name: 'facilities_needed_other',
          label: 'Other Facilities Needed',
          type: 'textarea',
          hide: !form.facilities_needed?.has?.('Other'),
          save: false,
          view: false,
        },
        {
          name: 'est_project_period',
          label: 'Estimated Period For Project Establishment',
        },
        {
          name: 'products_planned',
          label: 'Range of Products Planned (Per Year)',
          columns: [
            {
              name: 'product_name',
              label: 'Product',
            },
            {
              name: 'unit',
            },
            {
              name: 'unit_value',
              is: 'currency',
            },
            {
              name: 'local_quantity',
              label: 'Local Quantity',
              is: 'integer',
            },
            {
              name: 'local_value',
              is: 'currency',
            },
            {
              name: 'foreign_quantity',
              label: 'Foreign Quantity',
              is: 'integer',
            },
            {
              name: 'foreign_value',
              is: 'currency',
            },
          ],
        },
        {
          name: 'product_brand_names',
          label: 'Proposed Local Products Brand Names',
          ...textlist,
        },
        {
          name: 'production_sequence_desc',
          label: 'Production Sequence Description',
          type: 'textarea',
        },

        '::Financing Details ',
        {
          name: 'planned_capital_structure',
          columns: [
            {
              name: 'organization',
              options: ['Share Company', 'Partnership', 'Individual'],
            },
            { name: 'market_type', options: ['foreign', 'local'] },
            { name: 'capital_type', options: ['loan', 'project'] },
            { name: 'amount', is: 'currency' },
          ],
        },
        {
          name: 'planned_investment_analysis',
          label: 'Analysis of Planned Investment',
          fixed: true,
          columns: [
            {
              name: 'item',
              disabled: true,
            },
            // {
            //   name: 'market_type',
            //   options: ['foreign', 'local'],
            // },
            {
              name: 'local',
              is: 'currency',
              width: 180,
            },
            {
              name: 'foreign',
              is: 'currency',
              width: 180,
            },
          ],
        },
        {
          name: 'equipment_exemption_list',
          label: 'Equipment Exemptions',
          view: false,
          columns: [
            {
              name: 'desc',
              label: 'Description',
            },
            {
              name: 'qty',
              label: 'QTY',
              is: 'integer',
            },
            {
              name: 'cost',
              label: 'Cost Per Unit',
              is: 'currency',
            },
          ],
        },

        '::SPACER',
        {
          name: 'operational_expenditure',
          columns: [
            'item',
            'unit',
            { name: 'quantity', is: 'integer' },
            { name: 'foreign_cost', is: 'currency' },
            { name: 'local_cost', is: 'currency' },
          ],
        },
        {
          name: 'assembly_raw_materials',
          label: 'List Raw Materials',
          columns: [
            'item',
            'unit',
            { name: 'quantity', is: 'integer' },
            { name: 'foreign_cost', is: 'currency' },
            { name: 'local_cost', is: 'currency' },
          ],
        },
        {
          name: 'packing_materials',
          columns: [
            'item',
            'unit',
            { name: 'quantity', is: 'integer' },
            { name: 'foreign_cost', is: 'currency' },
            { name: 'local_cost', is: 'currency' },
          ],
        },
        {
          name: 'components',
          columns: [
            'item',
            'unit',
            { name: 'quantity', label: 'Qty.', is: 'integer' },
            { name: 'foreign_cost', is: 'currency' },
            { name: 'local_cost', is: 'currency' },
          ],
        },

        '::SPACER',
        {
          name: 'immigration_facilities',
          label: 'Immigration Facilities Needed',
          columns: [
            'staff_grade',
            {
              name: 'residence_period_months',
              label: 'Residence Period (Months)',
              is: 'integer',
            },
            'reason',
          ],
        },
        {
          name: 'employment_local_wages',
          label:
            'Employment Per Shift & Estimated Local Emplyee Wages (Per Year)',
          fixed: true,
          columns: [
            {
              name: 'grade',
              disabled: true,
            },
            {
              name: 'shift_1_no',
              label: 'Shift #1 Hours',
              is: 'integer',
            },
            {
              name: 'shift_1_wage',
              label: 'Wage',
              is: 'currency',
            },
            {
              name: 'shift_2_no',
              label: 'Shift #2 Hours',
              is: 'integer',
            },
            {
              name: 'shift_2_wage',
              label: 'Wage',
              is: 'currency',
            },
          ],
        },
        {
          name: 'anti_pollution_measures',
          hint: `
            * The composition of all wastes and effluents should be disclosed,
            e.g. solids in waste water; dust; gases
          `,
          rowLabel: 'Pollutant',
          addLabel: 'Add Measure',
          fields: [
            {
              name: 'effluent',
              label: 'Nature of Waste or Effluent',
            },
            {
              name: 'annual_unit',
              label: 'Annual Unit',
              is: 'integer',
            },
            {
              name: 'quantity',
              label: 'Quantity Amount',
              is: 'integer',
            },
            {
              name: 'desc',
              label: 'Description of Anti-Pollution Measures',
            },
          ],
        },

        '::Utilities Details',
        {
          name: 'electric_installed',
          type: 'select:bool',
          label: 'Electricity Installed?',
        },
        {
          name: 'electric_max_demand',
          label: 'Max Electrical Demand',
        },
        {
          name: 'electric_annual_kvp',
          label: 'Electrical Annual KVP',
        },
        {
          name: 'main_water_supply_net_consumption',
          label: 'Net Consumption of Main Water Supply',
        },
        {
          name: 'consumption_indices',
          fixed: true,
          rowLabel: 'Index',
          fields: [
            {
              name: 'item',
            },
            {
              name: 'annual_amount',
              is: 'currency',
            },
            {
              name: 'liability_period',
              label: 'Liability Period if Recurrent',
              required: false,
            },
            {
              name: 'remarks',
              required: false,
            },
          ],
        },

        '::SPACER',
        {
          name: 'concession_incentives',
          fields: [
            {
              name: 'short_desc',
              label: 'Short Title of Concesion/Incentive',
            },
            {
              name: 'reason',
              label: 'Reasons',
            },
          ],
        },

        '::Additional Details',
        {
          name: 'main_production_machinery',
          label: 'Main Productive Machinery',
          options: ['New', '2nd Hand', 'Re-conditioned'],
        },
        {
          name: 'main_production_machinery_percentage',
          label: 'Percentage Re-Conditioned',
          hide: form.main_production_machinery != 'Re-conditioned',
          save: false,
          type: 'float',
        },
        '::Foreign Collaboration',
        {
          name: 'joint_venture',
          hint: 'Percentages refer to Invested Capital',
          columns: [
            {
              name: 'equity_capital',
              label: 'Equity Capital %',
              is: 'float',
            },
            {
              name: 'loan_capital',
              label: 'Loan Capital %',
              is: 'float',
            },
            {
              name: 'machinery_contribution',
              label: 'Machinery Contribution %',
              is: 'float',
            },
          ],
        },
        {
          name: 'erection_commissioning_cost',
          is: 'currency',
        },
        {
          name: 'turnkey_contract_cost',
          is: 'currency',
        },
        {
          name: 'management_contract',
          columns: [
            {
              name: 'period',
              label: 'Period',
            },
            {
              name: 'months_years',
              label: 'Months/Years',
              options: ['Month(s)', 'Year(s)'],
            },
          ],
        },
        {
          name: 'bahamians_in_training',
          label: 'Training of Bahamians',
          is: 'integer',
        },
        {
          name: 'technical_servicing',
          columns: [
            { name: 'period', label: '#' },
            {
              name: 'months_years',
              label: 'Months/Years',
              options: ['Month(s)', 'Year(s)'],
            },
          ],
        },
        {
          name: 'product_number_specification',
          type: 'textarea',
        },
        {
          name: 'assembly_additional_comments',
          label: 'Additional Comments?',
          type: 'textarea',
          required: false,
        },
        {
          name: 'declare',
          type: 'checkbox',
          label: `
            I declare that the information entered is true and correct
            to best of my knowledge
          `,
        }
      )
    }

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
