import React, { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal'

import DatePicker from 'react-datepicker'
import Select from 'react-select'
import moment from 'moment'

export default function AdminActions(Component) {
  if (!$app.roles?.includes('mota')) {
    return props => <Component {...props} />
  }

  return function AdminFormsProvider(props) {
    const [sessions, setSessions] = useState([])
    const [modal, setModal] = useState('')
    const [records, setRecords] = useState([])
    const [sessionNums, setSessionNums] = useState([])
    const [sessionNum, setSessionNum] = useState('')
    const [date, setDate] = useState('')
    const [filteredRecords, setfilteredRecords] = useState([])

    useEffect(() => {
      async function getSessions() {
        const { data } = await $app.axios.get(
          `/services/metadata?service[name]=${props.service.name}`
        )

        const bahamahost_data = data.metadata?.find(
          m => m.desc == 'bahamahost session desc'
        )

        const nums = bahamahost_data?.data
          ?.filter(d => d.active)
          .map(d => {
            return { label: d.session_numb, value: d.session_numb }
          })

        setSessions(bahamahost_data?.data?.filter(d => d.active))
        setSessionNums(nums)
        // setSessionNum(nums[0]?.value)
      }

      async function getStudents() {
        const { data } = await $app.axios.get('/bahama_host_csps')

        const recs = [...data?.records, ...data?.records_by_proxy]

        setRecords(recs)
        setfilteredRecords(recs)
      }

      getSessions()
      getStudents()
    }, [props.service.name])

    const actions = [
      {
        text: 'View List of Available Sessions',
        icon: 'school',
        test: !$app.roles?.includes('mota_trainer'),
        fn: () => setModal('sessions'),
      },
      {
        text: 'View Class Register',
        icon: 'chalkboard-teacher',
        test: $app.roles?.includes('mota_trainer'),
        fn: () => setModal('records'),
      },
    ]

    const close = () => setModal('')

    const on = {
      date_change: value => {
        setDate(value)
        setfilteredRecords(
          records.filter(rec =>
            sessionNum
              ? rec.session_numb == sessionNum &&
                moment(rec.session_date).format('MMMM Do YYYY') ==
                  moment(value).format('MMMM Do YYYY')
              : moment(rec.session_date).format('MMMM Do YYYY') ==
                moment(value).format('MMMM Do YYYY')
          )
        )
      },

      num_change: value => {
        setSessionNum(value?.value)
        setfilteredRecords(
          records.filter(rec =>
            date
              ? rec.session_numb == value?.value &&
                moment(rec.session_date).format('MMMM Do YYYY') ==
                  moment(date).format('MMMM Do YYYY')
              : rec.session_numb == value?.value
          )
        )
      },
    }

    const modalContent = heading => (
      <div style={{ width: 800, maxWidth: '100%', maxHeight: '100%' }}>
        <div className='modal-header'>
          <h4>{heading}</h4>
          {modal === 'records' && <Filters />}
        </div>
        <div
          className='modal-body overflow-y-scroll divide-y divide-gray-400'
          style={{ maxHeight: '50vh' }}
        >
          {modal === 'sessions' ? <SessionTable /> : Records}
        </div>
      </div>
    )

    const SessionTable = () => {
      const rows = sessions.map((session, index) => (
        <tr key={index}>
          <td>{session?.session_type}</td>
          <td>{session?.session_numb}</td>
          <td>{`${session?.month_start} ${session?.date_start} - ${session?.month_end} ${session?.date_end}`}</td>
          <td>{`${session?.time_start} - ${session?.time_end}`}</td>
          <td>{session?.days.join()}</td>
        </tr>
      ))

      return (
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Session Type</th>
              <th scope='col'>Session Number</th>
              <th scope='col'>Date</th>
              <th scope='col'>Time</th>
              <th scope='col'>Days Available</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      )
    }

    const Filters = () => {
      return (
        <div className='form-group row'>
          <label
            htmlFor='session_numb'
            className='col-md-2 col-sm-4 mt-sm-2 text-right'
          >
            Session #
          </label>
          <div className='col-md-4'>
            {' '}
            <Select
              name='session_numb'
              key={sessionNum}
              options={sessionNums}
              // values={[sessionNums.find(s => s.value == sessionNum) || {}]}
              defaultValue={sessionNums.find(s => s.value == sessionNum) || {}}
              onChange={on.num_change}
              className='form-control'
            />
          </div>
          <label
            htmlFor='session_numb'
            className='col-md-3 col-sm-4 mt-sm-2 text-right'
          >
            Session Date
          </label>
          <div className='col-md-3'>
            {' '}
            <DatePicker
              type='date'
              className='form-control'
              name={'date'}
              onChange={on.date_change}
              selected={date}
              value={date}
              dateFormat='MMMM d, yyyy'
            />
          </div>
        </div>
      )
    }

    const Records = filteredRecords.map((r, i) => (
      <div
        key={i}
        className='flex flex-row justify-between items-center rounded-md my-2 p-2 hover:bg-gray-200 cursor-pointer'
      >
        <div>
          {r?.user.first_name.capitalize()} {r?.user.last_name.capitalize()}
          <br />
          <em>
            {r?.industry_type}
            {/* <span className='text-gray-600'>({p.port_type})</span> */}
          </em>
        </div>
      </div>
    ))

    return (
      <React.Fragment>
        <Component {...props} admin_actions={actions} />
        <Modal open={modal == 'sessions'} onClose={close} center>
          {modalContent('List of Available Sessions')}
        </Modal>
        <Modal
          open={modal == 'records'}
          onClose={close}
          center
          styles={{ modal: { width: '1500px' } }}
        >
          {modalContent('Class Register')}
        </Modal>
      </React.Fragment>
    )
  }
}
