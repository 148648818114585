import React, { useState, useMemo, useEffect, Fragment, isValidElement } from 'react'
import { Menu, MenuItem } from '@szhsin/react-menu'
import DataTable from 'react-data-table-component'
import moment from 'moment-business-days'

import { Spinner } from './utils'
import { listKeys } from '@helpers/objects'

const RecordsTable = props => {
  const { overrides } = props
  const [query, setQuery] = useState('')
  const [clear, setClear] = useState(false)
  /* eslint-disable-next-line */
  const [selected, setSelected] = useState([])
  const [batchable, setBatchable] = useState(false)

  useEffect(() => {
    if (props.usePagination) setQuery('')
  }, [props.usePagination])

  const on = {
    select: state => setSelected(state.selectedRows),
    clear: () => setClear(!clear),
  }

  const generateActions = record => {
    const actions = props.determineActions(record)
    if (!actions?.length) return null

    return (
      <Menu
        menuButton={<span data-action>Actions</span>}
        menuStyle={{ maxWidth: '280px' }}
        transition
        arrow
        portal
      >
        {actions.map((action, i) => (
          <MenuItem
            key={i}
            onClick={action.fn}
            className='flex items-center space-x-4'
          >
            {
              isValidElement(action.icon)
                ? action.icon
                : <i className={'fas fa-' + (action.icon || 'circle')} />
            }
            <span className='text-gray-700'>{action.text}</span>
          </MenuItem>
        ))}
      </Menu>
    )
  }

  const getStatus = ({ delivery_status, application_decision }) => {
    if (/collected/.test(delivery_status)) return 'COLLECTED'
    if (/ready for pickup/.test(delivery_status)) return 'READY FOR PICKUP'

    return application_decision?.toUpperCase() || 'PENDING'
  }

  const locations = useMemo(() => {
    if (!props.locations?.length) return []
    const agency = props.service?.agency.__id
    const arr = []
    let found = false

    // Always include Collections centre
    // Use for...of loop to avoid iterating through locations twice
    for (let { id, locations } of props.locations) {
      if (agency == id) {
        arr.push(...locations)
        if (agency == 2) break
        found = true
      }

      if (id == 2) {
        const ccc = locations.find(l => /collections/i.test(l.street_address))
        arr.push(ccc)
        if (found) break
      }
    }

    return arr
  }, [props.locations?.length])

  const columns = useMemo(() => {
    const defaults = [
      {
        name: 'View',
        omit: !props.citizen || props.hide_columns?.includes('View'),
        width: '80px',
        cell: r => (
          <span className='truncate' onClick={props.view(r)} data-action>
            View
          </span>
        ),
      },
      {
        name: 'Name',
        omit: props.citizen || props.hide_columns?.includes('Name'),
        selector: 'user.name',
        sortable: true,
        cell: r => (
          <span onClick={props.view(r)} data-action>
            {`${r.user?.last_name}, ${r.user?.first_name}`.capitalize()}
          </span>
        ),
      },
      {
        name: 'Proxy',
        selector: 'proxy',
        omit: props.skip_location,
        format: r =>
          r.proxy
            ? listKeys(r.proxy, 'first_name', 'last_name')
                .join(' ')
                .capitalize()
            : 'N/A',
      },
      {
        name: 'Date',
        omit: props.hide_columns?.includes('Date'),
        selector: 'created_at',
        sortable: true,
        width: '128px',
        format: ({ created_at }) => moment(created_at).format('YYYY-MM-DD'),
        sortFunction: (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      },
      {
        name: 'Appt. Time',
        omit:
          !props.has_appointment || props.hide_columns?.includes('Appointment'),
        selector: '__appointment.slot',
        sortable: true,
        width: '192px',
        format: r =>
          r.__appointment
            ? moment(r.__appointment.slot).format('Do MMM @ h:mm A')
            : 'N/A',
        sortFunction: (_a, _b) => {
          const [a, b] = [_a, _b].map(r => r.__appointment?.slot)
          if (!b) return 1
          if (!a) return -1

          return new Date(a).getTime() - new Date(b).getTime()
        },
      },
      {
        name: 'Appt. Location',
        omit:
          !props.has_appointment || props.hide_columns?.includes('Appointment'),
        selector: '__appointment.location',
        sortable: true,
        format: r => r.__appointment?.location.capitalize() || 'N/A',
      },
      {
        name: 'Pickup Location',
        omit: props.citizen || props.skip_location,
        selector: 'pickup_location',
        sortable: true,
        format: r =>
          r.pickup_location
            ? `${r.pickup_sub_location}, ${r.pickup_location}`.capitalize()
            : 'N/A',
      },
      {
        name: 'Status',
        omit: props.dispatcher,
        maxWidth: '150px',
        selector: 'application_decision',
        format: getStatus,
        sortable: true,
      },
      {
        name: 'Delivery Status',
        omit: props.skip_location || !props.citizen,
        maxWidth: '150px',
        selector: row => row.delivery_status ?? 'N/A',
        sortable: true,
      },
      {
        name: 'Payment',
        omit:
          props.dispatcher || props.free || (props.admin && !props.postpaid),
        maxWidth: '150px',
        selector: 'payment_status',
        format: r => (r.payment_status ? 'Paid' : 'Unpaid'),
        sortable: true,
      },
      {
        name: 'Status',
        omit: !props.dispatcher,
        selector: 'delivery_id',
        format: props.delivery?.getStatus,
        sortable: true,
      },
      {
        name: 'Action',
        maxWidth: '96px',
        cell: generateActions,
        omit: props.support || props.dispatcher,
      },
    ]

    if (!props.columns) return defaults
    if (overrides?.columns && props.columns) return props.columns(props)

    return [
      defaults[0],
      defaults[1],
      ...props.columns(props, defaults),
      ...defaults.slice(2),
    ]
  }, [])

  const colorCodes = useMemo(() => {
    const bg = bg => ({ backgroundColor: bg })

    if (props.citizen) return []
    if (props.color_codes) return props.color_codes(props, bg)

    const arr = []

    if (props.postpaid) {
      arr.push({
        when: r => !!r.payment_status,
        style: bg('#0C15'),
      })
    }

    arr.push(
      {
        style: bg('#FFBF0044'),
        when: r => {
          if (!r.payment_status) return false
          const { fulfillment_period: f } =
            locations.find(l => l.street_address == r.pickup_sub_location) ?? {}

          return moment(r.updated_at).businessDiff(moment()) == (f ?? 2)
        },
      },
      {
        style: bg('#C004'),
        when: r => {
          if (!r.payment_status) return false
          const { fulfillment_period: f } =
            locations.find(l => l.street_address == r.pickup_sub_location) ?? {}

          return moment(r.updated_at).businessDiff(moment()) > (f ?? 2)
        },
      }
    )

    return arr
  }, [locations])

  const records = useMemo(() => {
    const { search, searchable } = props

    if (
      props.usePagination ||
      !query ||
      (!searchable?.length && !search?.call)
    ) {
      return props.records
    }

    const q = new RegExp(query, 'i')
    if (typeof search == 'function') return props.records.filter(search(q))

    return props.records.filter(r => searchable.some(p => q.test(r[p])))
  }, [props.records, props.usePagination, query])

  if (!props.loading && !records?.length) {
    return <h5 className='text-center mb-0'>No Records To Display</h5>
  }

  const searchbar =
    !props.searchable?.length || props.usePagination ? null : (
      <div className='form-records-table-search searchbar'>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <i className='fas fa-search'></i>
            </span>
          </div>
          <input
            type='search'
            placeholder='Search...'
            value={query}
            onChange={ev => setQuery(ev.target.value)}
            className='form-control'
          />
        </div>
      </div>
    )

  const datatable = {
    keyField: props.table_row_id || 'id',
    columns,

    noHeader: true,
    data: records,
    defaultSortField: 'created_at',
    defaultSortAsc: !props.admin,
    progressPending: !!props.loading,
    progressComponent: <Spinner />,
    conditionalRowStyles: colorCodes,
  }

  // prettier-ignore
  props.usePagination && Object.assign(datatable, {
    paginationServer: true,
    paginationTotalRows: props.metadata.no_of_records,
    onChangeRowsPerPage: props.setTableParam('per_page'),
    onChangePage: props.setTableParam('page'),
  })

  if (props.enable_batch_updates) {
    const b = props.enable_batch_updates
    if (b === true
      || (Array.isArray(b) && $app.hasAnyRole(...b))
      || (typeof b == 'string' && $app.hasRole(b))
    ) {
      !batchable && setBatchable(true)

      Object.assign(datatable, {
        selectableRows: true,
        onSelectedRowsChange: on.select,
        clearSelectedRows: clear,
        selectableRowsHighlight: true,
      })
    }
  }

  const batchActions = batchable ? (
    <div className='flex space-x-4 pb-2 border-b border-b-solid'>
      <span data-action>Approve All</span>
      <span data-action>Deny All</span>
      <span data-action>Approve Selected</span>
      <span data-action>Deny Selected</span>
    </div>
  ) : null

  return (
    <Fragment>
      {searchbar}
      {batchActions}
      <DataTable
        key={props.usePagination}
        {...datatable}
        pagination
        striped
      />
    </Fragment>
  )
}

export default RecordsTable
