import React from 'react'

import AgriImport from './_agri-import'
import orchids from '@constants/orchids'

export default class OrchidImport extends React.Component {
  // TODO: Import items needs to have max of 4 items

  render = () => {
    const { props } = this

    const config = {
      ...props,
      skip_location: true,

      fee: record => Math.ceil(record.item_imports.length / 4) * props.service.base_fee,

      service: props.service,
      form_name: 'Orchid',
      resource: '/orchid_import_licenses',
      form_key: 'orchid_import_license',
      _sid: 'orchid import license',

      importProps: {
        metric_list: [
          'Each Per Item',
          'Bunches per item',
          'Stems per item',
          'Pot gallon size per item',
          'Square foot per item',
          'Plants per item',
        ],
        // hide_scientific: true,
        datalist: orchids,
      },
    }

    return <AgriImport {...config} />
  }
}
