import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ChassisCheck extends React.Component {
  state = {
    year: false,
    make: false,
    model: false,
    colour: false,
    status: false,
    vinCheck: false,
  }

  fee = 50
  resource = '/chassis_checks'
  form_key = 'chassis_check'
  skip_location = true
  has_appointment = true

  fields = form => [
    {
      name: 'chassis_check_purpose',
      label: 'Purpose for Chassis Check',
      options: ['Insurance Company', '3rd Party Sale', 'Shipping'],
    },
    {
      name: 'buyer_seller',
      label: 'Are you the buyer or the seller?',
      options: ['Buyer', 'Seller'],
      hide: form.chassis_check_purpose !== '3rd Party Sale',
    },
    '::Vehicle Details',
    {
      name: 'VIN',
      label: 'VIN Number',
      hint: form.new_vehicle
        ? 'VIN number will be verified by RTD to return other car info'
        : '',
      callback: async val => {
        try {
          const url = '/chassis_checks/fetch_rtd/' + val
          const { data } = await $app.axios.get(url)

          this.setState(state => {
            for (let [k, v] of Object.entries(data)) {
              state[k] = !!v
            }

            state.vinCheck = true
            return state
          })

          return {
            active: data.status == 'Active',
            vehicle_year: data.year ?? '',
            vehicle_make: data.make ?? '',
            vehicle_model: data.model ?? '',
            vehicle_color: data.colour ?? '',
          }
        } catch (error) {
          console.error(error)

          this.setState(state => {
            for (let k of Object.keys(state)) {
              state[k] = false
            }

            return state
          })

          return {
            active: false,
            vehicle_year: '',
            vehicle_make: '',
            vehicle_model: '',
            vehicle_color: '',
          }
        }
      },
    },
    {
      name: 'vehicle_make',
      disabled: this.state.make,
    },
    {
      name: 'vehicle_model',
      disabled: this.state.model,
    },
    {
      name: 'vehicle_year',
      disabled: this.state.year,
    },
    {
      name: 'vehicle_color',
      disabled: this.state.colour,
    },
    // {
    //   name: 'active',
    //   type: 'select:bool',
    //   disabled: this.state.status,
    // },
    {
      name: 'steering_wheeel_position',
      options: ['Right', 'Left'],
    },
    {
      name: 'purchased_from',
      label: 'Where was this vehicle purchased from?',
      options: ['Locally', 'Abroad'],
    },
    {
      name: 'seller_first_name',
      hide: form.buyer_seller !== 'Seller',
    },
    {
      name: 'seller_last_name',
      hide: form.buyer_seller !== 'Seller',
    },
    {
      name: 'seller_dob',
      label: 'Seller Date of Birth',
      type: 'date',
      hide: form.buyer_seller !== 'Seller',
    },
    {
      name: 'seller_drivers_license',
      label: "Seller Driver's License",
      hide: form.buyer_seller !== 'Seller',
      is: 'integer',
    },
    {
      name: 'seller_address',
      label: 'Seller Address',
      hide: form.buyer_seller !== 'Seller',
    },
    {
      name: 'seller_phone_number',
      label: 'Seller Phone Number',
      hide: form.buyer_seller !== 'Seller',
      type: 'phone',
    },
    {
      name: 'seller_cell_number',
      label: 'Seller Cell Number',
      hide: form.buyer_seller !== 'Seller',
      type: 'phone',
    },
    {
      name: 'buyer_first_name',
      hide: form.buyer_seller !== 'Buyer',
    },
    {
      name: 'buyer_last_name',
      hide: form.buyer_seller !== 'Buyer',
    },
    {
      name: 'buyer_dob',
      label: 'Buyer Date of Birth',
      type: 'date',
      hide: form.buyer_seller !== 'Buyer',
    },
    {
      name: 'buyer_drivers_license',
      label: "Buyer Driver's License",
      hide: form.buyer_seller !== 'Buyer',
      is: 'integer',
    },
    {
      name: 'buyer_address',
      label: 'Buyer Address',
      hide: form.buyer_seller !== 'Buyer',
    },
    {
      name: 'buyer_phone_number',
      label: 'Buyer Phone Number',
      hide: form.buyer_seller !== 'Buyer',
      type: 'phone',
    },
    {
      name: 'buyer_cell_number',
      label: 'Buyer Cell Number',
      hide: form.buyer_seller !== 'Buyer',
      type: 'phone',
    },
    {
      name: 'windshield_license_decal_upload',
      label: 'Windshield License Decal',
      type: 'file:image',
    },
    {
      name: 'motor_vehicle_cert_upload',
      label: 'Motor Vehicle Title Certificate',
      type: 'file:pdf',
    },
    {
      name: 'proof_sale_upload',
      label: 'Bill of Sale',
      type: 'file:pdf',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
