import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class HallOfFameNomination extends React.Component {
  resource = '/sports_hall_of_fames'
  form_key = 'sports_hall_of_fame'
  defaults = {}

  // Delete lines as needed
  fee = 99
  free = true
  postpaid = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => {
    const fields = [
      {
        name: 'federation',
        label: 'Which Sporting Federation are you associated with?',
        options:[
        'Bahamas Karate Association',
        'Bahamas Martial Arts Federation',
        'Bahamas Taekwondo Federation',
        'Bahamas Amateur Wrestling Federation',
        'Special Olympic Bahamas',
        'Bahamas Associaton for the Physically Disabled',
        'Bahamas National Council rof Disability',
        'Esports Federation',
        'Bahamas Judo Federation',
        'Bahamas Softball Federation',
        'Abaco Softball Association',
        'Andros Softball Association',
        'Bimini Softball Association',
        'Central Andros Softball Association',
        'Eleuthera Softball Association',
        'Exuma Softball Association',
        'Grand Bahama Softball Association',
        'Long Island Softball Association',
        'New Providence Softball Association',
        'Bahamas Volleyball Federation',
        'Abaco Volleyball Association',
        'Grand Bahama Volleyball Association',
        'Bahamas Bodybuilding & Fitness Federation',
        'Bahamas Cricket Federation',
        'Bahamas Cycling Federation',
        'Bahamas Darts Federation',
        'Bahamas Golf Federation',
        'Bahamas Triathalon Association',
        'Equestrian Bahamas Association',
        'Gymnastics Federation of The Bahamas',
        'Bahamas Boxing Federation',
        'Bahamas Boxing Commission',
        'Inagua Baseball Association',
        'Grand Bahama Baseball Association',
        'New Providence Amateur Baseball League',
        'Bahamas Basketball Federation',
        'Abaco Amateur Basketball Association',
        'Bimini Basketball Association',
        'Eleuthera Basketball Association',
        'Grand Bahama Basketball Association',
        'New Providence Basketball Association',
        "New Providence Women's Basketball Association",
        'San Salvador Basketball Association',
        'Bahamas Lawn Tennis Association',
        'Abaco Tennis Association',
        'Bahamas Football Association',
        'Grand Bahama Football Association',
        'Bahamas Coaches Association',
        'Bahamas Baseball Association',
        'Bimini Baseball Association',
        'Bahamas Association of Certified Officials',
        'Bahamas Association of Athletic Associations',
        'Freeport Aquatics Club',
        'Bahamas Aquatics Federation',

      
      ],
        
      },
      {
        name: 'position',
        label: 'What is your position within this federation?',
        options: [
          'President',
          'Vice President',
          'Chairman',
          'Director',
          'Secretary',
          'Treasurer',
          'Other',
        ]
        },
        {
          name: 'other',
          label: ' ',
          hint: 'Please specify',
          hide: form.position !='Other',
        },
    ]

   
      fields.push(


        '::Nominee Details',
        {
          name: 'candidate_first_name',
          label: 'First Name',
        },
        {
          name: 'candidate_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'candidate_last_name',
          label: 'Last Name',
        },
        {
          name: 'candidate_phone_numb',
          type: 'phone',
          label: 'Contact Phone #',
        },
        {
          name: 'candidate_email',
          type: 'email',
          label: 'Email Address',
        },
        {
          name: 'candidate_date_of_birth',
          type: 'date',
          label: 'Date of Birth',
          showYearDropdown: true,
        },
        {
          name: 'candidate_place_of_birth',
          label: 'Place of Birth',
          // options: countries,
        },


      )

    fields.push(
      {
        name: 'place_of_worship',
        label: '',
      },
      {
        name: 'school_info_list',
        label: 'Schools Attended',
        columns: [
          {
            name: 'school_name',
            label: 'School',
          },
          {
            name: 'date_of_attendance',
            label: 'Date Started',
            type: 'date',
            showYearDropdown: true,
          },
          {
            name: 'date_of_completion',
            label: 'Date Completed',
            type: 'date',
            showYearDropdown: true,
          },
        ]
      },
      {
        name: 'sport_practiced',
        label: 'What sport did the candidate play?',
        options: [
          'Basketball',
          'Soccer',
          'American Football',
          'Baseball',
          'Softball',
          'Golf',
          'Cricket',
          'Swimming',
          'Track & Field',
        ]
      },
      {
        name: 'local_clubs_list',
        label: 'Local Club(s) Participated In',
        columns: ['club'],
        format: v => v.club
      },
      {
        name: 'international_clubs_list',
        label: 'Local Club(s) Participated In',
        columns: ['club'],
        format: v => v.club
      },
      {
        name: 'local_achievements_list',
        label: 'Local Achievements',
        columns: [
          'achievement',
          {
            name: 'date_achieved',
            type: 'date',
            showYearDropdown: true,
          }
        ]
      },
      {
        name: 'international_achievements_list',
        label: 'International Achievements',
        columns: [
          'achievement',
          {
            name: 'date_achieved',
            type: 'date',
            showYearDropdown: true,
          }
        ]
      },
      {
        name: 'international_bahamas_representations',
        label: 'Examples of Representing The Bahamas Globally',
        columns: ['example'],
        format: v => v.example,
      },
      {
        name: 'local_moments_list',
        label: 'Most Memorable Moments Locally',
        type: 'textarea',
      },
      {
        name: 'international_moments_list',
        label: 'Most Memorable Moments Internationally',
        type: 'textarea',
      },
      {
        name: 'retirement_date',
        type: 'date',
        label: 'Date of Retirement From Active Competition',
        showYearDropdown: true,
      },
      {
        name: 'family_contacts_list',
        label: 'Contacts List',
        fields: [
          'first_name',
          'last_name',
          {
            name: 'relationship',
          },
          {
            name: 'phone_numb',
            type: 'phone',
            label: 'Phone'
          }
        ]
      },
      {
        name: 'additional_comments',
        type: 'textarea',
        required: false,
      },
      {
        name: 'photo_upload',
        key: 'image.0',
        type: 'file:image',
        label: 'Passport Photo'
      },
    )

return fields
  }

render = () => <Wizard {...SERVICE_PROPS(this)} />
}
