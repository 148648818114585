import React, { useState, useEffect, useMemo, Fragment } from 'react'
import Modal from 'react-responsive-modal'
import { ValidatorForm } from 'react-form-validator-core'
import Swal from 'sweetalert2'

import Loader from '@shared/loader'
import SelectField from '@shared/form-fields/select'
import InputField from '@shared/form-fields/input'
import findSelected from '@helpers/find-selected'
import { extractKeys } from '@helpers/objects'
import { fullName } from '@modules/form-wizard/utils'

const SearchByNIB = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [nib, setNIB] = useState('')
  const [service, setService] = useState('')

  useEffect(() => {
    if (!error) return
    console.error(error)

    Swal.fire('Oops!', `We've encountered an error!`, 'error').then(() =>
      history.goBack()
    )
  }, [error])

  const options = useMemo(() => {
    return $app.getServices().map(service => ({
      key: service.name,
      label: service.title,
      value: service.type,
      path: service.path,
    }))
  }, [])

  const submit = async ev => {
    ev.preventDefault()
    setLoading(true)

    try {
      const { path } = findSelected(options, service).pop()
      const { data } = await $app.axios.get('/services/search/' + nib, {
        params: { service },
      })

      if (!data.forms?.length) {
        return Swal.fire(
          'No Applications Found',
          `
            There were no applications for the selected service forund for
            <strong>${fullName(data.user)}</strong>
          `,
          'error'
        )
      }

      if ($app.hasRole('collections_centre')) {
        history.push({
          pathname: '/search/results',
          state: { service, ...extractKeys(data, 'forms', 'user') },
        })

        return
      }

      history.push({
        pathname: path + '/single-citizen',
        state: { service, ...extractKeys(data, 'forms', 'user') },
      })
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Loader loading={loading} />
      <Modal
        classNames={{ modal: 'w-128' }}
        showCloseIcon={false}
        onClose={() => history.goBack()}
        open
        center
      >
        <div className='modal-header'>
          <h5 className='modal-title'>Search User By NIB</h5>
        </div>
        <ValidatorForm id='nib-search' onSubmit={submit}>
          <div className='modal-body'>
            <Field name='NIB' label='NIB Number'>
              <InputField
                name='NIB'
                icon={null}
                className='form-control w-100'
                value={nib}
                onChange={ev => setNIB(ev.target.value.replace(/\D/, ''))}
                validators={['isNumber', 'maxStringLength:8']}
                errorMessages={[
                  'Field can only contain numbers',
                  'Number entered is too long',
                ]}
                required
              />
            </Field>
            <Field name='service' label='Service'>
              <SelectField
                name='service'
                icon='hand-holding'
                options={options}
                onChange={v => v?.length && setService(v[0].value)}
                values={findSelected(options, service)}
                value={service}
                validators={['required']}
                errorMessages={['Service must be selected']}
                className='form-control w-100'
              />
            </Field>
          </div>
          <div className='modal-footer'>
            <input
              className='btn custom-btn btn-round'
              type='submit'
              value='Confirm'
            />
          </div>
        </ValidatorForm>
      </Modal>
    </Fragment>
  )
}

function Field({ name, label, children }) {
  return (
    <div className='form-group form-show-validation flex flex-col'>
      <label htmlFor={name}>
        {label}
        <span className='required-label'>*</span>
      </label>
      {children}
    </div>
  )
}

export default SearchByNIB
