import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import passportPhotoExample from '@helpers/passport-photo-example'
import islands from '@constants/islands'
import generateRange from '@helpers/range'
import { BGCSES, BJCS } from '@constants/national-exams'

const currentYear = new Date().getFullYear()

export default class JobReadyForm extends React.Component {
  resource = '/job_ready_forms'
  form_key = 'job_ready_form'
  defaults = {}

  // Delete lines as needed
  free = true
  skip_location = true
  hide_buttons = false
  custom_actions = []
  custom_acceptance = true

  validate = () => {
    // TODO
    // Use Multiple Photo Attachments Route for certificate uploads

    return true
  }

  foremost = null

  fields = form => [
    {
      name: 'made_aware_by',
      label: 'How did you hear about the Program?',
      datalist: [
        'WhatsApp',
        'Social Media',
        'Newspaper',
        'Radio',
        'Word of Mouth',
      ],
    },

    '::Address',
    {
      name: 'street_name',
      label: 'Street Address',
    },
    {
      name: 'subdivision',
      label: 'Subdivision',
    },
    {
      name: 'house_numb',
      label: 'House #',
    },
    {
      name: 'island',
      options: islands
    },

    '::Bank Details',
    {
      name: 'bank_branch',
      label: 'Branch',
    },
    {
      name: 'bank_acct_numb',
      label: 'Account#',
    },

    '::Household',
    {
      name: 'numb_of_children',
      label: 'How Many Children Do You Have?',
      is: 'integer',
    },
    {
      name: 'numb_of_workers',
      label: 'How Many People Work In Your Household?',
      is: 'integer',
    },
    {
      name: 'reliable_childcare',
      type: 'select:bool',
      label: 'Do you have reliable childcare, given you are accepted?'
    },

    '::SPACER',
    {
      name: 'other_youth_programs',
      type: 'select:bool',
      label: `
        Have you participated in any other Ministry of
        Youth Sports & Culture Programs?
      `
    },
    {
      name: 'youth_programs_list',
      hide: !form.other_youth_programs,
      multi: true,
      options: [
        'Summer Program',
        'Fresh Start',
        'Job Ready',
        'Other'
      ]
    },
    {
      name: 'criminal_charge',
      type: 'select:bool',
      label: 'Have you ever been charged with a crime?',
    },
    {
      name: 'criminal_details',
      type: 'textarea',
      hide: !form.criminal_charge,
      label: 'Please Explain',
    },
    {
      name: 'convicted',
      type: 'select:bool',
      label: 'Have you ever been convicted of a crime?',
    },
    {
      name: 'convicted_details',
      type: 'textarea',
      hide: !form.convicted,
      label: 'Please Explain',
    },
    {
      name: 'other_job_programs',
      type: 'select:bool',
      label: 'Have you particiated in any other job training programs?',
    },
    {
      name: 'job_programs_list',
      hide: !form.other_job_programs,
      label: 'Other Job Programs',
      columns: ['program'],
      format: v => v.program,
    },
    {
      name: 'medical_issues',
      type: 'select:bool',
      label: `
        Do you have any medical conditions (inluding pregnancy) that
        may impact your ability to complete this Program?
      `,
    },
    {
      name: 'medical_details_list',
      hide: !form.medical_issues,
      label: 'Medical Conditions',
      columns: ['condition'],
      format: v => v.condition
    },
    {
      name: 'other_conditions',
      type: 'textarea',
      required: false,
      label: `
        If you have any other conditions/situations that may affect your
        ability to complete the Program, explain
      `,
    },
    {
      name: 'reliable_transportation',
      type: 'select:bool',
      label: 'Do you have reliable transportation?',
    },

    '::Education & Training',
    {
      name: 'academic_enrollment',
      type: 'select:bool',
      label: '',
    },
    {
      name: 'highschool_diploma',
      type: 'select:bool',
      label: 'Do you have a high school diploma?',
    },
    {
      name: 'last_school_attended',
      label: 'Last school you attended'
    },
    {
      name: 'highest_grade_completed',
      label: 'Highest grade completed',
      options: generateRange(1, 12).map(n => String(n)).reverse()
    },
    {
      name: 'year_left_school',
      label: 'Year left school',
      options: generateRange(1960, currentYear).map(n => String(n)).reverse()
    },
    {
      name: 'bjcs_passed_list',
      hint: 'Select all that apply',
      label: `BJC's Passed`,
      multi: true,
      options: BJCS
    },
    {
      name: 'bgcses_passed_list',
      hint: 'Select all that apply',
      label: `BGCSE's Passed`,
      multi: true,
      options: BGCSES
    },
    {
      name: 'other_certs_list',
      label: 'Other Certifications',
      hint: 'Select all that apply',
      required: false,
      multi: true,
      options: [
        'City & Guilds',
        'Royal School of Music',
        'Bahama Host',
        'First Aid'
      ]
    },
    {
      name: 'extra_activities_list',
      hint: 'Select all that apply',
      label: 'Have You Participated In...',
      required: false,
      multi: true,
      options: [
        'Technical Cadets',
        'GGYA',
        'Volunteering'
      ]
    },
    {
      name: 'internships_list',
      label: 'Volunteering/Internship Experience',
      required: false,
      columns: ['item'],
      format: v => v.item,
    },

    '::Employment History',
    {
      name: 'currently_working',
      type: 'select:bool',
      label: 'Are you currently working?'
    },
    {
      name: 'place_of_employment',
      hide: !form.currently_working,
      label: 'Place of Employment',
    },
    {
      name: 'previously_employed',
      type: 'select:bool',
      label: 'Have you been employed before?',
    },
    {
      name: 'previous_employment_length',
      hide: !form.previously_employed,
      label: `Longest Time Employed`,
      is: 'integer',
      hint: 'In years',
    },
    {
      name: 'previous_job',
      hide: !form.previously_employed,
      label: 'Most Recent Job',
    },
    {
      name: 'previous_job_duties_list',
      hide: !form.previously_employed,
      label: 'Duties At Previous Job',
      columns: ['duty'],
      format: v => v.duty
    },
    {
      name: 'previous_job_end_date',
      type: 'date',
      hide: !form.previously_employed,
      label: 'End Date of Last Job',
      showYearDropdown: true
    },
    {
      name: 'reason_for_leaving',
      type: 'textarea',
      hide: !form.previously_employed,
      label: 'Reason For Leaving Employment',
    },

    '::Vocational Interest',
    {
      name: 'career_interests_list',
      label: 'Top 3 Career Interests (From Greatest to Least)',
      columns: ['interest'],
      format: v => v.interest
    },

    '::Emergency Contact Info',
    {
      name: 'emergency_contacts',
      label: 'Emergency Contact(s)',
      columns: [
        'first_name',
        'last_name',
        {
          name: 'relationship',
          datalist: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
        },
        {
          name: 'phone_numb',
          label: 'Phone #',
          type: 'phone',
        }
      ]
    },
    {
      name: 'doctor_first_name',
      label: `Doctor's First Name`,
    },
    {
      name: 'doctor_last_name',
      label: `Doctor's Last Name`,
    },
    {
      name: 'doctor_phone_numb',
      type: 'phone',
      label: `Doctor's Phone#`,
    },

    {
      name: 't_shirt_size',
      label: 'T-Shirt Size',
      options: [
        'Small',
        'Medium',
        'Large',
        'X-Large',
        '2X-Large',
        '3X-Large',
        '4X-Large',
      ],
    },

    {
      view: 'false',
      heading: `
        <span>
          The Job Ready Program requires commitment, hard work, and dedication.
          What experiences that you have had that would help you succeed in
          this Program and why is it important for you to do this at this time
          in your life?
        </span>
      `
    },
    {
      name: 'personal_statement',
      type: 'textarea',
    },

    '::Uploads',
    {
      name: 'photo_upload',
      key: 'image.4',
      type: 'file:image',
      label: 'Self Portrait',
    },
    {
      name: 'passport_upload',
      key: 'image.3',
      type: 'file:image',
      label: 'Passport',
      hint: passportPhotoExample,
    },
    {
      name: 'nib_upload',
      key: 'image.2',
      type: 'file:image',
      label: 'NIB Card',
    },
    {
      name: 'police_cert_upload',
      key: 'image.1',
      type: 'file:image',
      label: 'Police Record',
    },
    {
      name: 'bank_info_upload',
      key: 'image.0',
      type: 'file:pdf',
      label: 'Bank Statement',
    },
    '::SPACER',
    {
      name: 'acceptance',
      type: 'checkbox',
      use_as_acceptance: true,
      label: `
        I certify that my answers are true and complete
        to the best of my knowledge
        <br />
        If this application leads to my employment, I understand that
        false or misleading information in my application or interview
        may result in my release
      `
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
