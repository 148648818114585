import { Fragment, useEffect, useState, createElement } from 'react'
import DataTable from 'react-data-table-component'
import { Switch, Route } from 'react-router'
import { Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
import QrReader from 'react-qr-reader'
import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Loader from '@shared/loader'
import StateStore from '@helpers/state-store'
import { fullName } from '@modules/form-wizard/utils'
import ApplicationNotes from '@modules/form-wizard/notes'
// import { getUploadPath } from '@modules/form-wizard/utils'

import CulturalRegistry from './cultural-registry'
import FormView from './minister/components/formSelectedView'

const MODAL = {
  MODE: 'MODE',
  VIA_LICENCE_NUMBER: 'VLN',
  VIA_QR_CODE: 'VQR',
}

const SERVICES = {
  CULTURAL_REGISTRY: {
    path: 'cultural-registry',
    label: 'Cultural Registry',
    resource:'/cultural_registries',
    form_key: 'cultural_registry',
    form_type: 'CulturalRegistry',
    // path: record => {
    //   // const { renewal, agent_type, temporary, appeal } = record

    //   return 'cultural-registry'
    //   // return renewal ? 'Permission To Use National Symbols Renewal' : 'Permission To Use National Symbols'
    // },
    serviceType: record => {
      // const { renewal } = record
      // return renewal ? 'Permission To Use National Symbols Renewal' : 'Permission To Use National Symbols'
      return 'Bahamas National Cultural Registry'
    }
  }
}

const PendingRegistries = props => {
  const endDateEvent = new Date();
  endDateEvent.setDate(endDateEvent.getDate() + 1)

  const [formSelected, setFormSelected] = useState(null)
  const [service, setService] = useState(null)
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [clear] = useState(false)
  const [data, setData] = useState([])
  const [active, setActive] = useState({})
  const [modal, setModal] = useState([])
  // const [focusArea, setFocusArea] = useState(null)
  // const [formStatus, setFormStatus] = useState('')
  const [showDateModal, setDateModal] = useState(false);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState(endDateEvent.toISOString().split('T')[0])
  const [params, setParams] = StateStore({
    page: 1,
    per_page: 20,
  })

  const BASE_URL = props.match.url
  const { history } = props

  useEffect(() => {
    service && fetchRecords()
  }, [service, params.page])

  const changeService = ev => {
    const v = ev.target.value

    if (!v) {
      setService(null)
      return
    }

    setFormSelected(null);
    setService({ key: v, ...SERVICES[v] })
  }

  const fetchRecords = async () => {
    setLoading(true)

    try {
      const { data } = await $app.axios.get(service.resource, { params })

      setData(data.records)
      setParams(data.params)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  const filterDate = () => () => {
      const currParams = params;
      currParams['start_date'] = startDate;
      currParams['end_date'] = endDate;
      setParams(currParams);
      fetchRecords();

      return setDateModal(false);
  }

  const filterStatus = () => async () => {
    const { value: status } = await Swal.fire({
      title: 'Select status',
      input: 'select',
      inputOptions: {
        none: 'None',
        denied: 'Deactivated',
        pending: 'Pending',
        approved: 'Emailed',
      },
      inputPlaceholder: 'Select a filter',
      showCancelButton: true,
    })
    
    if (status) {
      const currParams = params;
      currParams['status'] = status;
      if (status === 'none') delete currParams.status
      setParams(currParams);
      fetchRecords();
    }

      return
  }

  const filterFocusArea = () => async () => {
    const { value: focus } = await Swal.fire({
      title: 'Select focus area',
      input: 'select',
      inputOptions: {
        none: 'None',
        art: 'Art',
        craft: 'Craft',
        jewelry: 'Jewelry',
        music: 'Music',
        drama: 'Drama',
        film: 'Film',
        dance: 'Dance',
        culinary: 'Culinary',
        fashion: 'Fashion',
        literature: 'Literature',
        junkanoo: 'Junkanoo',
        festival: 'Festival',
        museum: 'Museum'

      },
      inputPlaceholder: 'Select a filter',
      showCancelButton: true,
    })
    
    if (focus) {
      const currParams = params;
      currParams['focus_areas'] = focus;
      if (focus === 'none') delete currParams.focus_areas
      setParams(currParams);
      fetchRecords();
    }

      return
  }

  const message = (selector) => async () => {
    const steps = ['1', '2']
    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText: 'Next >',
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: 'swal2-noanimation' },
      hideClass: { backdrop: 'swal2-noanimation' }
    })
    
    const { value: subject } = await Queue.fire({
      currentProgressStep: 0,
      // optional class to show fade-in backdrop animation which was disabled in Queue mixin
      showClass: { backdrop: 'swal2-noanimation' },
      title: `Message Applicant \n(${fullName(selector.user, 'initial')})`,
      input: 'text',
      inputLabel: 'Email subject',
      // inputValue: inputValue,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Subject cannot be empty!'
        }
      }
    })
    const { value: message } = await Queue.fire({
      currentProgressStep: 1,
      showClass: { backdrop: 'swal2-noanimation' },
      input: 'textarea',
      inputLabel: 'Message',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      confirmButtonText: 'OK',
      showCancelButton: true,
    })

    if (!subject || !message) return

    // Single Record Update
    if (selector?.user) {
      setLoading(true)

      try {
        await $app.axios.post(service.resource + '/email_application_batch', {
          [service.form_key]: {
            form_ids: [selector.id],
            subject,
            message,
          },
        })

        toastr.success('Success', 'Applicants successfully messaged')
        setActive({})
        setLoading(false)
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }

      return
    }

    // Batch Updates
    const affectingAll = selector === data
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: `Message ${affectingAll ? 'All' : 'Selected'} Applications`,
      html: affectingAll
        ? `
          Are you sure that you want to message
          all of the applications on the current page?
        `
        : `
          Are you sure you want to message
          the ${selected.length} selected applicant(s)?
        `,
      showCancelButton: true,
      confirmButtonText: 'Yes, Message All',
    })

    if (isDismissed) return
    setLoading(true)

    try {
      const form_ids = selector.map(f => f.id)

      await $app.axios.post(service.resource + '/email_application_batch', {
        [service.form_key]: {
          form_ids,
          subject,
          message,
        }
      })

      toastr.success('Success', 'Applicants successfully messaged')
      fetchRecords()
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing the affected applications',
        'error'
      )
    }
  }

  const viewRecord = record => {
    // console.log(record);
    // console.log(service);
    // service?.key == 'SECURITY_LICENCES' && console.log(service.path(record));
    setFormSelected(record);

    // const to = typeof service.path == 'function'
    //   ? service.path(record)
    //   : service.path

    // history.push(sublink(to), { view: record })
  }

  const writeNote = async record => {
    if (!record.notes?.length) record.notes = []

    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Add Note',
      inputAttributes: {
        autocorrect: 'on',
      },
      showCancelButton: true,
    })

    if (!text) return

    try {
      const { data } = await $app.axios.post('/services/notes/add_note', {
        note: {
          note_type: 'note',
          internal_view_only: true,
          notable_type: service.form_type,
          notable_id: record.id,
          text,
        },
      })

      record.notes.push(data.note)

      Swal.fire({
        icon: 'success',
        toast: 'true',
        text: 'Note successfully created',
        timer: 5000,
        position: 'top-end',
        showConfirmButton: false,
      })
    } catch (err) {
      console.error(err)
      
      Swal.fire({
        icon: 'error',
        toast: 'true',
        text: 'Failed to create note.',
        timer: 5000,
        position: 'top-end',
        showConfirmButton: false,
      })
    }
  }

  const showScanErrorMessage = async err => {
    console.error(err)
    setModal([])

    await Swal.fire(
      'Error',
      'There was a problem while scanning the QR code',
      'error'
    )
  }

  const getLicenceID = async action => {
    setModal([null, action])

    const { value, isDismissed } = await Swal.fire({
      input: 'text',
      text: 'Please enter the licence number you wish to ' + action,
      showCancelButton: true,
      cancelButtonText: 'Nevermind',
      confirmButtonText: action.capitalize()
    })

    if (isDismissed) return

    // TODO: Find licence by ID
    return value
  }

  const getLicenceViaQRCode = async code => {
    if (!code) return

  }

  // const confirmLicenceRevocation = async f => {
  //   const [method, action] = modal

  //   const { isConfirmed, isDismissed } = await Swal.fire({
  //     title: `${action} licence`.capitalize(),
  //     html: `
  //       Are you sure that you want to ${action} this licence
  //       belonging to ${f.company_name || fullName(f.user, 'initial')}?
  //     `,
  //     showCancelButton: true,
  //     cancelButtonText: 'Nevermind',
  //     confirmButtonText: action.capitalize()
  //   })

  //   if (isDismissed) return

  //   try {
  //     const { data } = await $app.axios
  //       .put('/inquiry_and_security_licenses/update_application', {
  //         inquiry_and_security_license: {
  //           form_num: f.form_num,
  //           application_decision: action == 'revoke' ? 'cancelled' : 'revoked'
  //         }
  //       })

  //   } catch (err) {
  //     console.error(err)

  //     Swal.fire(
  //       'Error',
  //       `An error occurred while trying to ${action} the application`,
  //       'error'
  //     )
  //   }
  // }

  const sublink = path => (BASE_URL + '/' + path).replace('//', '/')

  const Service = ({ Component: element, path = '', service = '' }) => (
    <Route
      path={sublink(path)}
      render={r => createElement(element, {
        ...r,
        service: $app.getService(service)
      })}
    />
  )

  const columns = [
    {
      name: 'Applicant',
      selector: row => `${row.user.last_name}, ${row.user.first_name}`.capitalize(),
      sortable: true,
    },
    {
      name: 'Artist Name',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.artist_name,
      sortable: true,
    },
    {
      name: 'No. of Members',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.number_of_members,
      sortable: true,
    },
    {
      name: 'Registered Date',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.created_at.slice(0, row.created_at.indexOf("T")),
      sortable: true,
    },
    {
      name: 'Areas of Focus',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => (<span style={{textTransform: 'capitalize'}}>{row.areas_of_focus.join(', ')}</span>),
      sortable: true,
    },
    {
      name: 'Status',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => {
        const status = row.application_decision == 'pending' ? 'pending' : 'emailed'
        return (<span style={{textTransform: 'capitalize'}}>{status}</span>)
      },
      sortable: true,
    },
    // {
    //   name: 'Illustration Upload',
    //   omit: service?.key != 'SYMBOL_USE_PERMISSION',
    //   selector: row => {
    //     const data = row.image[1];
    //     const dataSplit = data.split('/');
    //     const isMissing = dataSplit[1] != 'system';
    //     let aRefLink;
    //     isMissing ? aRefLink = <span>N/A</span> : aRefLink = <a href={getUploadPath(data, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>;
        
    //     return aRefLink
    //   },
    //   sortable: true,
    // },
    {
      name: 'Actions',
      right: true,
      cell: row => (
        <Menu
          menuButton={<span data-action>Actions</span>}
          transition
          portal
          arrow
        >
          <MenuItem onClick={() => viewRecord(row)}>
            View
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={message(row)}>
            Message
          </MenuItem>
          {/* <MenuItem onClick={approve(row)}>
            Approve
          </MenuItem>
          <MenuItem onClick={deny(row)}>
            Deny
          </MenuItem>
          <MenuDivider /> */}
          <MenuItem onClick={() => setActive(row)}>
            View Notes
          </MenuItem>
          <MenuItem onClick={() => writeNote(row)}>
            Create Note
          </MenuItem>
        </Menu>
      ),
    }
  ]

  const colors = [
    {
      style: { backgroundColor: '#0E33' },
      when: row => row.application_decision == 'approved'
    },
    {
      style: { backgroundColor: '#FFBF0044' },
      when: row => row.application_decision == 'pending'
    },
  ]

  return (
    <Fragment>
      <Switch>
        <Service
          path='cultural-registry'
          service='cultural registry'
          element={CulturalRegistry}
        />

      </Switch>
      <section className='content minister-applications-table'>
        <Loader loading={loading} />
        <article className='page-inner p-4'>
          <header className='flex justify-between items-center mb-6'>
            <h1 className='text-4xl text-gray-800'>
              Ministry of Culture
            </h1>
            <nav className='flex justify-end space-x-4'>
              <span
                onClick={() => setModal([MODAL.MODE])}
                className='flex space-x-1 items-center text-blue-700 cursor-pointer'
              >
                <i className='fas fa-ban'></i>
                <span>Cancel/Revoke</span>
              </span>
              <Link
                to='/logout'
                className='flex space-x-1 items-center text-blue-700'
              >
                <i className='fas fa-sign-out-alt'></i>
                <span>Sign Out</span>
              </Link>
            </nav>
          </header>
          <div className='card'>
            <div className='card-header flex flex-col xl:flex-row justify-between items-center'>
              <label className='flex items-center space-x-2'>
                <span className='font-semibold'>Service</span>
                <select
                  name='service'
                  value={service?.key}
                  onChange={changeService}
                  className='form-control'
                >
                  <option value=''>Select A Service</option>
                  {Object.entries(SERVICES).map(([key, { label }]) => (
                    <option key={key} value={key} children={label} />
                  ))}
                </select>
              </label>
              {service?.key == 'CULTURAL_REGISTRY' && (
                <aside className='flex space-x-4 actions'>
                  <span onClick={() => setDateModal(!showDateModal)}>
                    Filter by Date Range
                  </span>
                </aside>
              )}
              {service?.key == 'CULTURAL_REGISTRY' && (
                <aside className='flex space-x-4 actions'>
                  <span onClick={filterFocusArea()}>
                    Filter by Focus Area
                  </span>
                </aside>
              )}
              {service?.key == 'CULTURAL_REGISTRY' && (
                <aside className='flex space-x-4 actions'>
                  <span onClick={filterStatus()}>
                    Filter by Status
                  </span>
                </aside>
              )}
              {service?.key == 'CULTURAL_REGISTRY' && (
                <aside className='flex space-x-4 actions'>
                  <span onClick={message(selected?.length ? selected : data)}>
                    Mass Message {selected.length ? 'Selected' : 'All'}
                  </span>
                  {/* <span onClick={deny(selected)}>
                    Deny {selected.length ? 'Selected' : 'All'}
                  </span> */}
                </aside>
              )}
            </div>
            <div className='card-body'>
              {(!formSelected && service) && (
                <div className='table-responsive form-records-table'>
                  <DataTable
                    data={data}
                    columns={columns}
                    onSelectedRowsChange={s => setSelected(s.selectedRows)}
                    clearSelectedRows={clear}
                    conditionalRowStyles={colors}
                    noHeader
                    selectableRows
                    pagination
                    paginationServer
                    paginationPerPage={params.per_page}
                    paginationTotalRows={params.no_of_records}
                    onChangeRowsPerPage={per_page => setParams({ per_page })}
                    onChangePage={page => setParams({ page })}
                  />
                </div>
              )}
              {(!formSelected && !service) && (
                <div className='py-10 text-center font-semibold'>
                  Please select a service
                </div>
              )}
              {formSelected && (
                <FormView
                  passedProps={{service, formSelected}}
                  // methods={{setFormSelected, approve, deny, setActive, writeNote}}
                  methods={{setFormSelected, message, setActive, writeNote}}
                />
              )}
            </div>
          </div>
        </article>
        <ApplicationNotes
          data={active?.notes}
          onClose={() => setActive(null)}
        />
      </section>
      <Modal
        open={modal[0] === MODAL.MODE}
        onClose={() => setModal(false)}
        center
      >
        <header className='modal-header'>
          <h4>Cancel/Suspend A Licence</h4>
        </header>
        <article className='modal-body flex flex-col space-y-6'>
          <button
            className='btn btn-primary'
            onClick={() => getLicenceID('revoke')}
          >Cancel Via Licence Number</button>
          <button
            className='btn btn-primary'
            onClick={() => getLicenceID('suspend')}
          >Suspend Via Licence Number</button>
          <button
            className='btn btn-primary'
            onClick={() => setModal([MODAL.VIA_QR_CODE, 'revoke'])}
          >Cancel Via QR Code</button>
          <button
            className='btn btn-primary'
            onClick={() => setModal([MODAL.VIA_QR_CODE, 'suspend'])}
          >Suspend Via QR Code</button>
        </article>
        <footer className='modal-footer flex justify-end'>
          <button className='btn btn-muted' onClick={() => setModal([])}>
            Close
          </button>
        </footer>
      </Modal>
      <Modal
        open={modal[0] === MODAL.VIA_QR_CODE}
        onClose={() => setModal(false)}
        center
      >
        <p className='text-center my-8'>
          Please scan the QR Code of the licence you wish to {modal[1]}
        </p>
        <div className='relative rounded-xl bg-black overflow-hidden w-full sm:w-96 mx-auto'>
          <QrReader
            delay={1000}
            onScan={getLicenceViaQRCode}
            onError={showScanErrorMessage}
            style={{ width: '100%', height: '100%' }}
          />
          <FaChevronLeft
            size={28}
            color='#FFF'
            className='absolute bottom-0 left-0 mb-2 ml-2 cursor-pointer z-10'
            onClick={() => history.goBack()}
          />
        </div>
      </Modal>
      <Modal
        open={showDateModal}
        onClose={() => setDateModal(false)}
        center
      >
        <header className='modal-header'>
          <h4>Date Filter</h4>
        </header>
        <article className='modal-body flex flex-col space-y-6'>
          <div className='flex'>
            <label htmlFor="startDate">Start Date:</label>
            <input type="date" id="startDate" name="startDate" className='ml-2 rounded-sm' onChange={e => setStartDate(e.target.value)}/>
          </div>

          <div className='flex'>
            <label htmlFor="endDate">End Date:</label>
            <input type="date" id="endDate" name="endDate" className='ml-2 rounded-sm' onChange={e => setEndDate(e.target.value)}/>
          </div>
        </article>
        <footer className='modal-footer flex justify-end'>
          <button className='btn btn-muted' onClick={() => setDateModal(false)}>
            Close
          </button>
          <button className='btn btn-primary' onClick={filterDate()}>
            Filter
          </button>
        </footer>
      </Modal>
    </Fragment>
  )
}

export default PendingRegistries
