import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'

const AREAS_OF_PREFERENCES = [
  'Medical & Anti-Doping Committee',
  'Accreditations Committee',
  'Facilities Committee',
  'Records, Statistics & Videography',
  'Executive Technical Committee',
  'Security Committee',
  'Finance & Fund Raising',
  'Marketing & Public Relations',
  'Accommodation & Subsistence',
  'Air & Ground Transportation',
  'Cultural Committee  Opening & Closing  Ceremony',
  'Attaches Committee'
]

export default class JubileeGamesVolunteer extends React.Component {
  resource = '/jubilee_game_volunteers'
  form_key = 'jubilee_game_volunteer'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'resident_island',
      label: 'Island Currently Residing On',
      options: islands,
    },
    {
      name: 'previous_experience_list',
      label: 'Previous Volunteer Experience',
      columns: ['experience'],
      format: v => v.experience,
    },
    {
      name: 'occupation',
      hint: 'Past occupation if retired'
    },
    {
      name: 'company_name',
      label: 'Name of Company',
    },
    {
      name: 'company_address',
      label: 'Address of Company',
    },
    {
      name: 'company_island',
      label: 'Island of Company',
      options: islands,
    },
    {
      name: 'additional_info',
    },
    
    '::Availability',
    {
      heading: <span>I am available:</span>,
      view: false,
    },
    {
      name: 'mornings',
      label: 'Mornings ( 7th July - 15th July)',
      type: 'checkbox',
    },
    {
      name: 'afternoons',
      label: 'Afternoons ( 7th July - 15th July)',
      type: 'checkbox',
    },
    {
      name: 'evenings',
      type: 'checkbox',
    },
    {
      name: 'weekends',
      type: 'checkbox',
    },
    {
      name: 'once_a_week',
      type: 'checkbox',
    },
    {
      name: 'few_times_a_week',
      type: 'checkbox',
    },
    {
      name: 'one_time',
      type: 'checkbox',
    },
    {
      name: 'as_needed',
      type: 'checkbox',
    },
    {
      name: 'availability_other',
      required: false,
      label: 'Additional/Other Availabilities'
    },
    
    {
      name: 'valid_drivers_license',
      type: 'select:bool',
      label: "Do you have a valid Driver's Licence?",
    },
    {
      name: 'traffic_offender',
      type: 'select:bool',
      label: `
        Have you been charged with any traffic offences
        in the last three (3) years?
      `,
    },

    {
      name: 'area_of_preference_list',
      multi: true,
      options: AREAS_OF_PREFERENCES
    },

    // {
    //   name: 'declare',
    //   type: 'checkbox',
    //   label: `

    //   `
    // }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
