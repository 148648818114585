/* This is a React component that renders a form wizard for an annual grant application with various
fields and validations. */
import React from 'react'

/* These are import statements that bring in various modules and constants needed for the
implementation of the React component. */
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import daysOfWeek from '@constants/weekdys'

const today = new Date().toISOString().slice(0, 10);

/* This is a React component that exports a default class named `__SERVICE_NAME__`. The component
contains various properties and methods that define the behavior and appearance of a form wizard for
an annual grant application. */
export default class __SERVICE_NAME__ extends React.Component {
  resource = '/annual_grants'
  form_key = 'annual_grant'
  defaults = {
    shareholders: [
      {
        position: 'President',
        first_name: '',
        last_name: '',
        po_box: '',
        email: '',
        phone_numb: '',
        place_of_employment: '',
      },
      {
        position: 'Vice President',
        first_name: '',
        last_name: '',
        po_box: '',
        email: '',
        phone_numb: '',
        place_of_employment: '',
      },
      {
        position: 'Secretary',
        first_name: '',
        last_name: '',
        po_box: '',
        email: '',
        phone_numb: '',
        place_of_employment: '',
      },
      {
        position: 'Treasurer',
        first_name: '',
        last_name: '',
        po_box: '',
        email: '',
        phone_numb: '',
        place_of_employment: '',
      },
    ]
  }
/* These are properties and methods defined within the `__SERVICE_NAME__` React component. */

  free           = true
  skip_location  = true
  hide_buttons   = false
  custom_actions = []

  validate = null
  foremost = null

  fields = form => [
    {
      name: 'project_name',
      label: 'Name of Project',
    },
    {
      name: 'position_in_organization',
      label: 'Position in Org.',
    },
    {
      name: 'website',
    },
    {
      name: 'vision_statement',
      type: 'textarea',
    },
    {
      name: 'mission_statement',
      type: 'textarea',
    },
    {
      name: 'project_outline_list',
      label: 'Project Outline',
      columns: ['item'],
      format: v => v.item
    },
    {
      name: 'steps_to_success',
      type: 'textarea',
      label: 'Steps To Meet Objectives',
    },
    {
      name: 'target_audience',
      type: 'textarea',
      label: 'Specific Target Population',
    },
    {
      name: 'meeting_info_list',
      label: 'Meeting Dates & Times',
      columns: [
        {
          name: 'day',
          options: daysOfWeek,
        },
        {
          name: 'time',
          is: 'time',
        },
        'location'
      ]
    },
    {
      name: 'youth_issued_addressed_list',
      label: 'Youth Issues Addressed',
      fields: [
        {
          name: 'issue',
          options: [
            'Literacy',
            'Crime & Violence Reduction',
            'Career Development, Employment, & Entrepeneurship Skill Development',
            'Responsible Sexual Behaviour',
            'Youth Advocacy & Empowerment',
            'Environmental Education',
            'Substance Abuse Reduction',
            'Promotion of Volunteerism'
          ]
        },
        {
          label: 'How Was It Addressed?',
          name: 'how_was_addressed',
          type: 'textarea',
        }
      ]
    },
    {
      name: 'shareholders',
      label: 'Organization Officers',
      min: 4,
      fields: [
        {
          name: 'position',
          disabled: true,
        },
        'first_name',
        'last_name',
        {
          name: 'email',
          is: 'email'
        },
        {
          name: 'phone_numb',
          type: 'phone',
          label: 'Phone',
        },
        'place_of_employment',
        {
          name: 'po_box',
          label: 'P. O. Box',
          required: false,
        },
      ]
    },
    {
      name: 'expected_outcomes_list',
      label: 'Expected Outcomes',
      columns: ['outcome'],
      format: v => v.outcome
    },
    {
      name: 'typical_session',
      label: '',
    },
    {
      name: 'successful_events_list',
      label: 'Successful Events',
      columns: ['event'],
      format: v => v.event
    },
    {
      name: 'grant_amount_requested',
      is: 'currency',
    },
    {
      name: 'funds_needed_by',
      type: 'date',
      minDate: today
    },
    {
      name: 'fund_usage_details',
      type: 'textarea',
      label: '',
    },
    {
      name: 'fund_sources',
      label: 'Source(s) of Funds',
      columns: [
        'source',
        {
          name: 'amount_received',
          is: 'currency',
        }
      ]
    },
    {
      name: 'funds_granted_previous_year',
      type: 'select:bool',
      label: 'Did you receive a grant last year?',
    },
    {
      name: 'funds_granted_amount',
      hide: !form.funds_granted_previous_year,
      is: 'currency',
      label: 'Grant Amount Rewarded',
    },

    '::Banking Info',
    {
      name: 'bank_name',
      label: 'Name of Bank',
    },
    {
      name: 'branch_numb',
      label: 'Branch #',
    },
    {
      name: 'branch_address',
      label: 'Branch Address',
    },
    {
      name: 'name_on_bank_account',
      label: 'Name on Bank Acct.',
    },
    {
      name: 'bank_account_numb',
      label: 'Acct. #',
    },
    {
      name: 'bank_account_type',
      label: 'Type of Account',
      options: [
        'Savings',
        'Checquings',
        'Business Chequings',
        'Charge Account'
      ]
    },

    '::Uploads',
    {
      key: 'image.0',
      name: 'vendor_form_upload',
      type: 'file:pdf',
      label: 'Vendor Form',
    },
    {
      key: 'image.1',
      name: 'bank_info_upload',
      type: 'file:pdf',
      label: 'Bank Info',
    },
    {
      key: 'image.2',
      name: 'business_nib_upload',
      type: 'file:pdf',
      label: 'Business NIB',
    },
    {
      key: 'image.3',
      name: 'cash_flow_report_upload',
      type: 'file:pdf',
      label: 'Cash Flow Report',
    },
    {
      key: 'image.4',
      name: 'tax_comp_cert_upload',
      type: 'file:pdf',
      label: 'Tax Compliance Cert',
    },
    {
      key: 'image.5',
      name: 'business_license_upload',
      type: 'file:pdf',
      label: 'Business Licence',
    },

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
