import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class PermitRenewal extends React.Component {
  resource = '/drivers_learners_permit_renewals'
  form_key = 'drivers_learners_permit_renewal'

  licence = null
  recentlyPaid = false

  hooks = {
    'post:read': data => {
      if (!data?.length) return

      const last = data.findLast(record => record.payment_status)?.updated_at
      if (!last) return

      this.recentlyPaid = moment().diff(last, 'months') < 3
    },
  }

  before_apply = async () => {
    try {
      // const { Type } = await this.getLicenceDetails(true)
      const { Type } = {
        NIB: '13938967',
        Name: 'Ms Crystal Alexis Bostwick',
        Gender: 'Female',
        Office: 'Head Office',
        TMN: '421757426',
        Status: 'Expired',
        Issue: '2021-09-14 00:00:00',
        Expiry: '2022-03-14 00:00:00',
        Type: "Learner's Permit",
      }

      if (Type == "General Driver's Licence") {
        return `
          Your records show that you possess a Driver's Licence;
          please use our Licence Renewal service instead.
        `
      } else if (Type != "Learner's Permit") {
        return `
          The TMN number found in the Road Traffic Department's is
          not a Learner's Permit.
        `
      }
    } catch (err) {
      return `
        We were unable to fetch your driver's licence's details from
        the Road Traffic Department. Please try again later.
      `
    }

    if (this.recentlyPaid) {
      const { isConfirmed } = await Swal.fire({
        icon: 'warning',
        title: 'Confirm Resubmission',
        html: `
          We see that you've recently paid for a permit renewal;
          are you certain that you want to create a new application?
        `,
        showCancelButton: true,
        confirmButtonText: 'Resubmit',
      })

      return isConfirmed || null
    }

    return true
  }

  getLicenceDetails = async (refetch = false) => {
    try {
      if (refetch || !this.licence) {
        const url = '/drivers_learners_permit_renewals/fetch_rtd/' + $app.applicant.NIB
        const { data } = await $app.axios.get(url)
        this.licence = data
      }

      return this.licence
    } catch (err) {
      console.error(err)
      return {
        error: 'Unable to retrieve licence details from the RTD database',
      }
    }
  }

  validate = async form => {
    const { TMN } = await this.getLicenceDetails()

    return (
      TMN == form.license ||
      "Driver's Permit # not assigned to the user's NIB #"
    )
  }

  fields = () => [
    {
      name: 'license',
      label: 'Permit #',
      is: 'integer',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
