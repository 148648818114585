import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import islands from '@constants/islands'
export default class TimberLicense extends React.Component {
  resource = '/timber_licenses'
  form_key = 'timber_license'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  /*
  t.string :form_num
t.string :payment_amount
t.string :payment_invoice
t.string :payment_status
t.string :application_status
t.string :application_decision, default: 'pending'
t.boolean :acceptance, default: false
t.boolean :declare, default: false
t.string :pickup_location
t.string :pickup_sub_location
t.belongs_to :user, foreign_key: true
t.belongs_to :delivery, foreign_key: true, null: true
t.belongs_to :officer, foreign_key: { to_table: :users }, null: true
t.belongs_to :proxy, foreign_key: { to_table: :users }, null: true
t.string :delivery_status
t.date :app_status_change_date
t.string :ip_address
t.boolean :requested_proxy, default: false

# DIR Verification Info
t.string :client_type
t.string :company_name
t.string :company_email
t.string :bl_number
t.string :tin_number
t.string :business_nib
t.string :street_address
t.string :po_box
t.string :phone_number
t.string :trading_name
t.string :company_type

t.string :license_numb
t.date :license_expiry_date
t.date :license_approved_date
t.date :license_approval_date
t.attachment :pdf_upload

t.string :forest_name
t.string :forest_acreage
t.string :forest_island
t.string :forest_city
t.string :forest_street_address
t.decimal :longitude, precision: 10, scale: 6
t.decimal :latitude, precision: 10, scale: 6
t.string :forest_settlement
t.string :timber_specifics_list, array: true, default: []
t.integer :removal_quantity
t.string :timber_use
t.string :equipment_used_list, array: true, default: []
t.boolean :power_saw_permit, default: false
t.string :saw_permit_numb
t.date :saw_permit_date
t.date :commencement_date
t.date :completion_date

t.attachment :impact_assessment_upload
t.attachment :management_plan_upload
t.attachment :business_plan_upload
  */

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
        name: 'license_numb',
        label: 'license number',
    },
    {
        name: 'license_expiry_date',
        type: 'date',
    },
    {
      name: 'license_approval_date',
      type: 'date',
    },
    {
      name:'pdf_upload',
    },
    {
      name:'forest_name',
      label:'',
    },
    {
      name:'forest_acreage',
      label:'',
    },
    {
      name:'forest_island',
      label:'',
      options: islands,
    },
    {
      name:'forest_city',
      label:'',
    },
    {
      name:'forest_street_address',
      label:'',
    },
    {
      name:'longitude',
      label:'Longitude',
    },
    {
      name:'latitude',
      label:'Latitude',
    },
    {
      name:'forest_settlement',
      label:'',
    },
    {
      name:'timber_specifics_list',
      label:'',
      columns:[' '],
    },
    {
      name:'removal_quantity',
      label:'',
    },
    {
      name:'timber_use',
      label:'',
    },
    {
      name:'equipment_used_list',
      label:'',
      columns: [''],
    },
    {
      name:'power_saw_permit',
      label:'',
    },
    {
      name:'saw_permit_numb',
      label:'',
      type: 'integer',
    },
    {
      name:'saw_permit_date',
      label:'',
      type:'date',
    },
    {
      name:'commencement_date',
      label:'When do you plan to begin operations?',
      type:'date',
    },
    {
      name:'completion_date',
      label:'When do you plan to complete operations?',
      type:'date',
    },
    {
      name:'impact_assessment_upload',
      label:'',
      type: 'file:pdf',
    },
    {
      name:'management_plan_upload',
      label:'',
      type: 'file:pdf',
    },
    {
      name:'business_plan_upload',
      label:'',
      type: 'file:pdf',
    },
    {
      name:'',
      label:'',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}


